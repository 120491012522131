import React, { useState } from 'react'
import { FaWpforms } from 'react-icons/fa'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap'

export const AdminPageUpload = () => {
    const [number, setNumber] = useState("");
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    const handleInputChange = (e) => {
        setNumber(e.target.value);
    };

    return(
        <div>
            <Container className="d-flex justify-content-center signupContainer">
                <IoArrowBackCircleOutline
                    size={25}
                    style={{ marginTop: "10px", cursor: "pointer" }}
                    onClick={goBack}
                />
                <Form style={{ width: "100%", marginTop: "50px" }}>
                    <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                    >
                    <Label
                        for=""
                        style={{
                        marginRight: "0px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        width: "50%",
                        }}
                    >
                        Application Number :
                    </Label>
                        <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Enter application number"
                        style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            fontSize: "0.875rem",
                            marginBottom: "5px",
                            width: "70%",
                        }}
                        value={number}
                        onChange={handleInputChange}
                        required
                        />
                        <Button
                        className="signupDYR uploadButton"
                        style={{
                            marginRight: "0px",
                        }}
                        >
                        <FaWpforms />
                        &nbsp;&nbsp;Upload Application
                        </Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    )
}