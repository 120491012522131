import React, { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export const Tracking = (props) => {
    const [title, setTitle] = useState((typeof props.title === "undefined") ? "Incubig AI's research analytics dashboard" : props.title);
    const [description, setDescription] = useState((typeof props.description === "undefined") ? "Incubig AI is a patent and research analytics site that utilizes AI technology to offer solutions like Patent Analytics, Patent Filing, Prior Art Search, Freedom to Operate, Novelty Analysis, and Patent Valuation. Find comprehensive insights into your intellectual property with Incubig AI." : props.description);
    const [keywords, setKeywords] = useState((typeof props.keywords === "undefined") ? "Patent Analytics, Patent Filing, Prior Art Search, Freedom to operate, Novelty Analysis, Patent Valuation" : props.keywords+"Patent Analytics, Patent Filing, Prior Art Search, Freedom to operate, Novelty Analysis, Patent Valuation");


    useEffect(()=>{
        if (typeof props.title === "undefined"){
            document.title = getTitle(window.location.pathname)
            
            setTitle(getTitle(window.location.pathname))
        }
        window.gtag('event', 'page_view')
        // console.log(title)
    },[])

    const getTitle = (path) => {
        let fullPath = path.split("/")
        let basePath = path.split("/")[1]
        let readablePath = null

        if (basePath === "" || basePath === "/") {
            readablePath = "DYR by Incubig"
        }
        else {
            readablePath = basePath[0].toUpperCase() + basePath.substring(1,basePath.length).toLowerCase() + "|" + fullPath.at(-1).toLowerCase()
            readablePath = "DYR: " + readablePath
        }
        setTitle(readablePath)
        return readablePath
    }

    const getCanonicalUrl = () => {
        let pathname = window.location.pathname;
        return "https://www.incubig.in" + pathname
    };

    const getMetaTags = () => {
        let pathArray = window.location.pathname.split("/")
        if (pathArray.length>1){
            let page = pathArray[1]
            if (page === "results") {
                return {
                    title :"Incubig: Do Your Research - ",
                    description: "",
                    keywords: ""
                }
            }
            else if (page === "technology-page") {
                return {
                    title :"",
                    description: "",
                    keywords: ""
                }
            }
            else if (page === "company-page") {
                return {
                    title :"",
                    description: "",
                    keywords: ""
                }
            }
        }
        else {
            return {
                title :"",
                description: "",
                keywords: ""
            }
        }
    }

    return(
        <div>
            <Helmet>
            <title>{title}</title>
            <meta
                name="title"
                content={title}
            />
            <meta
                name="og:title"
                content={title}
            />
            <meta
                name="description"
                content={description}
            />
            <meta
                name="og:description"
                content={description}
            />
            <meta
                name="og:url"
                content={window.location.href}
            />
            <meta
                name="keywords"
                content={keywords}
            />
            <link rel="canonical" href={getCanonicalUrl()} />
            </Helmet>
        </div>
    )
}