import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const WorldMap = require('react-svg-worldmap').WorldMap;

const SimpleMap = (props) => {
  const [data, setData] = useState([
    { "country": "US", "value": 0 },
    { "country": "JP", "value": 0 },
    { "country": "CN", "value": 0 },
    { "country": "KR", "value": 0 },
  ]);

  useEffect(() => {
    if (typeof props.data !== "undefined") {
      let tempData = [];
      props.data.map((item) => {
        if (item[0] != null){
          tempData.push({ "country": item[0], "value": item[1] });
        }
      });
      setData(tempData);
    }
  }, [props.data]);

  const navigate = useNavigate();

  const onClick = (e) => {
    if (props.clickHandler) {
      props.clickHandler(e.countryCode, e.countryValue);
    }
  };

  return (
    <div>
      <div
        id="root"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "fit-content",
          height: "auto",
          color: props.color || "grey",
          backgroundColor: props.backgroundColor || "transparent",
          borderColor: props.borderColor || "grey"
        }}
      >
        <WorldMap
          color={props.color || "grey"}
          valueSuffix={(props.suffix) ? props.suffix : "players"}
          size="responsive"
          data={data}
          style={{ width: "100%" }}
          backgroundColor={props.backgroundColor || '#020b26'}
          borderColor={props.borderColor || 'grey'}
          onClickFunction={(e) => {onClick(e)}}
        />
      </div>
      <h2 style={{ textAlign: "center", marginTop: "auto", fontSize: "0.8rem"}}>
        {props.label}
      </h2>
    </div>
  );
};

export default SimpleMap;
