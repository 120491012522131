import React from "react";
import { Row, Col } from "reactstrap";
import { Tracking } from "../../components/tracking/tracking";
import { PatentTableFilters } from "../../components/patentTableFilters/patentTableFilters";
import PatentList from "../../components/patentsList/patentList";
import LineChart from "../../components/Charts/lineChart";
import PieChart from "../../components/Charts/pieChart";
import Skeleton from "react-loading-skeleton";
import { removeDuplicatePatents, toTitleCase } from "../../actions/helper";

const PatentRecommendation = (props) => {
  const similarApplications = props.similarApplications

  return (
    <div>
      <Tracking  title={props.publicationNumber+" : "+props.title} description={props.patentFields} keywords={props.patentFields}/>

      <Row className="g-0">
        <Col
          xs="12"
          md="12"
        >
          <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px"}}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              View similar patents
            </span>
          </div>
            <p style={{marginLeft: "25px", marginTop: "20px", marginBottom: "20px"}}>You might be interested in following patents.</p>  
            <PatentList keyApplications={(similarApplications)?removeDuplicatePatents(similarApplications):undefined}/>
        </Col>
        <Col className="xs=12" md="6">
        <div className="researchAreaChart">
          
        </div>
        <div className="trendGraph" style={{justifyContent: "center"}}>
          
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PatentRecommendation;
