import React, {useState} from 'react';
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";
import { Tracking } from '../../components/tracking/tracking';
import PatentList from "../../components/patentsList/patentList";
import { removeDuplicatePatents, sanitizeText, toTitleCase } from "../../actions/helper";
import Grid from '../../components/Grid/grid';
import { InventorFilter } from '../../components/inventorFilter/inventorFilter';
import ISOCode  from "../../actions/countryISOcodes.json";

const CompanyPageData  = (props) => {
    const selectedCompany = props.selectedCompany
    const assigneeOverview = props.assigneeOverview
    const topYear = props.topYear

    const [selectedCountry, setSelectedCountry] = useState(
        props.keyGeographies && props.keyGeographies.length === 1
          ? props.keyGeographies[0][0]
          : "All countries"
    );


    return (
      <div>
        <Tracking  title={(props.selectedTechnology)?props.selectedTechnology.definition:"Technology analysis by Incubig"} description={"Patents in "+((props.selectedTechnology)?props.selectedTechnology.definition:"Technology analysis by Incubig")} keywords={((props.selectedTechnology)?props.selectedTechnology.definition.split(" ").join(","):"Technology analysis by Incubig")}/>
        <Row className="g-0">
          <Col xs="12" md="7"
            className="borderRight"
          >
            <div
              className="summaryTag"
              style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px"}}
            >
              <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
                Patent data
              </span>
            </div>
            {(assigneeOverview&&assigneeOverview.applications.length >0)?
            <p className="para" style={{ marginTop: "10px", marginBottom: "10px" }}>
              In {assigneeOverview.applications[0].application_date.slice(0,4)}, {toTitleCase(selectedCompany)} filed a patent on {toTitleCase(assigneeOverview.applications[0].title)}.
            </p>
            :
            <p><Skeleton/></p>
            }
            {assigneeOverview?
            <InventorFilter
              inventors={assigneeOverview.inventor_analysis.top_inventors}
              filterFunction={props.filterFunction}
            />
            :
            <p className="para" style={{ marginTop: "10px" }}><Skeleton/></p>
            }
            <PatentList keyApplications={(assigneeOverview)?removeDuplicatePatents(assigneeOverview.applications):undefined} downloadOption={{show:true,params:{'assignee':selectedCompany}}} />
          </Col>
          <Col className="xs=12" md="5">
          <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginTop: "0px",
              }}
            >
              <span
                className="summaryTagText"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Assignee Summary{" "}
                {selectedCountry != "All countries"
                  ? " in " + ISOCode[selectedCountry] + " "
                  : ""}{" "}
              </span>
            </div>
            <div className="forStats">
              
              <Grid
                gridHeading="Overview"
                upperGrid={[
                  {
                    value: assigneeOverview
                      ? assigneeOverview.total_applications
                      : undefined,
                    caption: "Patents",
                  },
                  {
                    value: assigneeOverview
                      ? assigneeOverview.inventor_analysis.total_inventors
                      : undefined,
                    caption: "Inventors",
                  },
                  {
                    value: assigneeOverview
                      ? assigneeOverview.summary.industries
                      : undefined,
                    caption: "Industries",
                  },
                ]}
                lowerGrid={[
                  {
                    value: assigneeOverview
                      ? assigneeOverview.summary.technologies
                      : undefined,
                    caption: "Technologies",
                  },
                  { value: topYear, caption: "Active Year" },
                  {
                    value:
                      assigneeOverview &&
                      assigneeOverview.inventor_analysis.top_inventors.length > 0
                        ? toTitleCase(
                            assigneeOverview.inventor_analysis.top_inventors[0]
                              .inventor
                          )
                        : undefined,
                    caption: "Top inventor",
                  },
                ]}
              />
                </div>
          </Col>
          </Row>
      </div>
    )
}

export default CompanyPageData ;
