import React from 'react';
import { Row, Col } from 'reactstrap';
import { Tracking } from '../../components/tracking/tracking';
import Grid from '../../components/Grid/grid';
import "../../pages/inventorPage/inventorPage.css"
import { Timeline } from '../../components/Timeline/timeline';

const InventorPageNetwork = (props) => {
  const sampleData = {
    gridHeading: "Co-inventors Overview",
    upperGrid: [
      { value: 120, caption: "Total Patents" },
      { value: 80, caption: "Patents Published" },
      { value: 50, caption: "Patents Granted" }
    ],
    lowerGrid: [
      { value: 75, caption: "Patents Pending" },
      { value: 30, caption: "New Applications" },
      { value: 20, caption: "Applications Rejected" }
    ],
    topPlayer: ""
  };

  const data = [
    { inventorName: 'Alice Johnson', technologyName: 'Artificial Intelligence in Healthcare', country: 'USA' },
    { inventorName: 'Bob Smith', technologyName: 'Renewable Energy Systems', country: 'Germany' },
    { inventorName: 'Charlie Brown', technologyName: 'Blockchain Security', country: 'UK' },
    { inventorName: 'Diana Prince', technologyName: 'Advanced Robotics', country: 'Japan' },
    { inventorName: 'Eve Adams', technologyName: 'Quantum Computing', country: 'Canada' },
  ];

  const samplePointers = {
    maxima: [
      { year: 2010, technology: 'Wireless communication networks', sharedPatents: 2 },
      { year: 2012, technology: 'Blockchain', sharedPatents: 3 },
      { year: 2015, technology: 'Cybersecurity', sharedPatents: 1 },
      { year: 2018, technology: 'Quantum Computing', sharedPatents: 4 },
      { year: 2020, technology: 'IoT', sharedPatents: 5 }
    ],
    minima: [
      { year: 2011, technology: 'Multiplex communication', sharedPatents: 1 },
      { year: 2013, technology: 'Control or regulating systems in general', sharedPatents: 2 },
      { year: 2016, technology: 'Transmission of digital information', sharedPatents: 3 },
      { year: 2019, technology: 'Wireless communication networks', sharedPatents: 2 },
      { year: 2021, technology: 'Electric digital data processing', sharedPatents: 4 }
    ]
  };
  

  return (
    <div>
      <Tracking title={props.selectedInventor + " 's patent network and co-inventors"}
        description={
          "Analyze the co=inventors of " +
          props.selectedInventor +
          " 's patent portfolio"
        }
        keywords={props.selectedInventor} />

        <Row className="g=0">
          <Col xs="12" md="6" className="borderRight">
          <div className="companyTag">Co-Inventors
          </div>

          <h2
              className="globalText"
              style={{ fontSize: "0.8rem", marginTop: "20px" }}
            >A total of 72 co-inventors are working with Mitsuru Mochizuki.</h2>
          
         

          </Col>

<Col xs="12" md="6">
<div style={{marginLeft: "25px"}}>
          <Grid gridHeading={sampleData.gridHeading}
      upperGrid={sampleData.upperGrid}
      lowerGrid={sampleData.lowerGrid}
      topPlayer={sampleData.topPlayer}/>
      </div>
</Col>
        </Row>

        <hr style={{width: "97%", marginLeft: "25px", background: "linear-gradient(to right, rgb(12 177 232), rgb(13 39 238))", height: "10px", border: "white", marginTop: "10px"}}/>

        <Row className='g-0'>
          <Col xs="12" md="12">
          <h1 style={{ fontSize: "1.3rem", marginLeft: "25px", marginTop: "0px" }}>
          Co-inventors acitivity over time
              </h1>
              <h1 style={{ fontSize: "1rem", marginLeft: "50px", marginTop: "30px", fontWeight: "bold" }}>
          Top 3 Co-inventors, hover on the circles to see various technologies over the years.
              </h1>
<div style={{ marginTop: "10px", marginBottom: "80px", padding: "20px", border: "1px solid #ddd", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginLeft: "50px", marginRight: "50px", height: "90%" }}>
      <h2 style={{ textAlign: "left", marginTop: "0px", fontWeight: "bold" }}>Miho Maeda</h2>
      <p style={{marginBottom: "50px", color: "#a9a5a5"}}>95 Shared Patents</p>
     
      {/* <Timeline pointers={samplePointers} query="patent" /> */}
      <br/>
      <hr
          className="thin-horizontal-line"
          style={{
            backgroundColor: "rgb(199, 198, 198)",
            color: "rgb(199, 198, 198)",
            marginTop: "80px",
            marginBottom: "0px",
          }}
        />
      <h2 style={{ textAlign: "left", marginTop: "10px",fontWeight: "bold"  }}>Yasushi Iwane</h2>
      <p  style={{marginBottom: "50px", color: "#a9a5a5"}}>52 Shared Patents</p>
    
      {/* <Timeline pointers={samplePointers} query="patent" /> */}
      <br/>
      <hr
          className="thin-horizontal-line"
          style={{
            backgroundColor: "rgb(199, 198, 198)",
            color: "rgb(199, 198, 198)",
            marginTop: "80px",
            marginBottom: "0px",
          }}
        />
      <h2 style={{ textAlign: "left", marginTop: "10px" ,fontWeight: "bold" }}>Noriyuki Fukui</h2>
      <p style={{marginBottom: "50px", color: "#a9a5a5"}}>30 Shared Patents</p>
      
      {/* <Timeline pointers={samplePointers} query="patent" /> */}
    </div>
          </Col>
        
        </Row>

        <hr style={{width: "97%", marginLeft: "25px", background: "linear-gradient(to right, rgb(12 177 232), rgb(13 39 238))", height: "10px", border: "white", marginTop: "10px"}}/>

        <Row className='g=0'>
          <Col xs="12" md="12">

          <h1 style={{ fontSize: "1.3rem", marginLeft: "25px", marginTop: "0px" }}>
          Click on co-inventors to see their profile.
              </h1>

<div style={{marginLeft: "25px", marginBottom: "50px"}}>
              <table className="striped-table" style={{ marginLeft: "0px", width: "97%" }}>
      <thead>
        <tr>
          <th>Inventor Name</th>
          <th style={{ width: "50%" }}>Technology Name</th>
          <th>Country</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.inventorName}</td>
            <td>{row.technologyName}</td>
            <td>{row.country}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
          </Col>
        </Row>

    </div>
  )
}

export default InventorPageNetwork;