// import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';

// const LineChart = (props) => {
//   const [labels, setLabels] = useState(props.labels);
//   const [series, setSeries] = useState(props.series);

//   useEffect(() => {
//     if (typeof props.series != 'undefined') {
//       setLabels(props.labels)
//       setSeries(props.series);
//     } else {
//       setSeries([]);
//     }
//   }, [props.series]);

//   const chartData = {
//     options: {
//       chart: {
//         type: 'line',
//         zoom: {
//           enabled: false,
//         },
//         toolbar: {
//           show: false,
//         },
//       },
//       colors: ['#F4F2EC'],
//       dataLabels: {
//         enabled: false,
//       },
//       stroke: {
//         show:true,
//         width: [0.5, 0.5],
//         curve: 'smooth',
//         colors: ['#000000']
        
//       },
//       grid: {
//         show: false,
//       },
//       xaxis: {
//         title: {
//           text: props.title,
//         },
//         categories: labels,
//         axisBorder: {
//           show: false,
//         },
//         axisTicks: {
//           show: false,
//         },
//       },
//       yaxis: {
//         min: 0,
//         forceNiceScale: true,
//         labels: {
//           style: {
//             colors: '#8e8da4',
//           },
//         },
//       },
//       tooltip: {
//         x: {
//           show: false,
//         },
//       },
//     },
//   };

//   return (
//     <Chart 
//       options={chartData.options} 
//       series={[
//         {
//           name: props.name ? props.name : 'Patent Trends',
//           data: series,
//         },
//       ]} 
//       type="area" 
//       height={300} 
//     />
//   );
// };

// export default LineChart;

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const LineChart = (props) => {
  const [labels, setLabels] = useState(props.labels);
  const [series, setSeries] = useState(props.series);

  useEffect(() => {
    if (typeof props.series !== 'undefined') {
      setLabels(props.labels);
      setSeries(props.series);
    } else {
      setSeries([]);
    }
  }, [props.series]);

  const chartData = {
    options: {
      chart: {
        type: 'area',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#FAF1EC'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: [0.5, 0.5],
        curve: 'smooth',
        colors: ['#000000'],
      },
      grid: {
        show: false,
      },
      xaxis: {
        title: {
          text: 'Year', 
          style: {
            fontWeight: 'light',
          },
        },
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            return Math.floor(value); 
          },
          style: {
            colors: '#8e8da4',
          },
        },
        title: {
          text: props.axisLabel? props.axisLabel : "Patents Filed", 
          style: {
            fontWeight: 'light',
          },
        },
      },
      title: {
        text: props.title,
        align: 'center',
        style: {
          fontSize: '12px',
          fontWeight: 'light',
        },
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    },
  };

  return (
    <Chart
      options={chartData.options}
      series={[
        {
          name: props.name ? props.name : 'Patents Filed',
          data: series,
        }
      ]}
      type="area"
      height={300}
    />
  );
};

export default LineChart;

