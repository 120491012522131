import React from 'react'
import "./loader.scss"

const Loader = () => {
  return (
    <div className="bookBody">
      <div className='bookText'>Please wait we are working on your report</div>
      <div className="book">
      <div className="book__page"></div>
      <div className="book__page"></div>
      <div className="book__page"></div>
    </div>
    </div>
  )
}

export default Loader