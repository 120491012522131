import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import LineChart from "../../components/Charts/lineChart";
import PieChart from "../../components/Charts/pieChart";
import { Tracking } from "../../components/tracking/tracking";
import Grid from "../../components/Grid/grid";
import { CompanyCarousel } from "../../components/carousel/companyCarousel";
import { CpcTable } from "../../components/Tables/cpcTable";
import SimpleMap from "../../components/WorldMap/WorldMap";
import { moveToTechnologyPage, toTitleCase } from "../../actions/helper";
import FullCompanyTable from "../../components/Tables/fullCompanyTable";
import PatentList from "../../components/patentsList/patentList";

const Trends = (props) => {
  const [keyPlayers, setKeyPlayers] = useState(
    props.keyPlayers ? props.keyPlayers : []
  );
  const [grantPercent, setGrantPercent] = useState(
    props.grantPercent ? props.grantPercent : undefined
  );
  const [researchYear, setResearchYear] = useState(
    props.topYear ? props.topYear : undefined
  );
  const [researchYearContribution, setResearchYearContribution] = useState(
    props.topYearContribution ? props.topYearContribution : undefined
  );
  const [trends, setTrends] = useState(props.trends ? props.trends : undefined);
  const [isMoving, setIsMoving] = useState(true);
  // const technologies = props.technologies;
  const [industries, setIndustries] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isWidth, setIsWidth] = useState(window.innerWidth <= 768);

  const companies = [
    ["Apple", "Apple Inc."],
    ["Google", "Google LLC"],
    ["Microsoft", "Microsoft Corporation"],
    ["Amazon", "Amazon.com, Inc."],
    ["Tesla", "Tesla, Inc."],
  ];

  const technologies = [
    ["AI", "Artificial Intelligence"],
    ["ML", "Machine Learning"],
    ["Blockchain", "Blockchain Technology"],
    ["IoT", "Internet of Things"],
    ["Cloud", "Cloud Computing"],
  ];

  const [selectedCompany, setSelectedCompany] = useState("Apple");
  const [selectedTechnology, setSelectedTechnology] = useState("AI");

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleTechnologyChange = (event) => {
    setSelectedTechnology(event.target.value);
  };

  const handleButtonClick = () => {
    console.log(
      `Filtering data for ${selectedCompany} in ${selectedTechnology}`
    );
  };

  const gridData = {
    gridHeading: "Overview",
    upperGrid: [
      { caption: "Number of Technologies", value: "150" },
      { caption: "Number of Countries", value: "45" },
      { caption: "Top Country", value: "Germany" },
    ],
    lowerGrid: [
      { caption: "Top Inventor", value: "Jane Doe" },
      { caption: "Time Period", value: "2010-2023" },
      { caption: "Top Technology", value: "Instruction prefetching" },
    ],
    topPlayer: "GlobalTech Inc.",
  };

  const sampleData = [
    {
      companyName: "InnovateTech Inc.",
      country: "USA",
      industry1: "Artificial Intelligence",
      industry2: "Cloud Computing",
      technologiesCount: 25,
      topInventor: "Dr. Alice Johnson",
    },
    {
      companyName: "EcoSolutions Ltd.",
      country: "UK",
      industry1: "Renewable Energy",
      industry2: "Sustainable Materials",
      technologiesCount: 18,
      topInventor: "Mr. Liam Brown",
    },
    {
      companyName: "BioHealth Corp.",
      country: "Germany",
      industry1: "Biotechnology",
      industry2: "Pharmaceuticals",
      technologiesCount: 32,
      topInventor: "Dr. Emma Müller",
    },
    {
      companyName: "FinTech Dynamics",
      country: "Canada",
      industry1: "Financial Technology",
      industry2: "Blockchain",
      technologiesCount: 15,
      topInventor: "Mr. Noah Williams",
    },
    {
      companyName: "AutoDrive Solutions",
      country: "South Korea",
      industry1: "Automotive",
      industry2: "Autonomous Vehicles",
      technologiesCount: 22,
      topInventor: "Ms. Yuna Kim",
    },
    {
      companyName: "GreenEarth Innovations",
      country: "Australia",
      industry1: "Environmental Technology",
      industry2: "Waste Management",
      technologiesCount: 20,
      topInventor: "Dr. Olivia Smith",
    },
    {
      companyName: "Quantum Leap Labs",
      country: "Japan",
      industry1: "Quantum Computing",
      industry2: "Advanced Materials",
      technologiesCount: 28,
      topInventor: "Mr. Hiroshi Tanaka",
    },
  ];

  const sampleDataTable = [
    {
      name: "Tech Innovators Inc.",
      country: "USA",
      industryFocus: ["Technology", "Artificial Intelligence"],
      technologiesDeveloped: 120,
      topInventor: "Jane Doe",
    },
    {
      name: "Global Green Energy",
      country: "Germany",
      industryFocus: ["Energy", "Sustainability"],
      technologiesDeveloped: 85,
      topInventor: "Hans Schmidt",
    },
    {
      name: "FutureTech Solutions",
      country: "Japan",
      industryFocus: ["Robotics", "Automation"],
      technologiesDeveloped: 95,
      topInventor: "Akira Yamamoto",
    },
    {
      name: "BioHealth Innovations",
      country: "UK",
      industryFocus: ["Healthcare", "Biotechnology"],
      technologiesDeveloped: 110,
      topInventor: "Emma Wilson",
    },
    {
      name: "SpaceTech Ventures",
      country: "USA",
      industryFocus: ["Aerospace", "Satellite Technology"],
      technologiesDeveloped: 75,
      topInventor: "John Smith",
    },
  ];

  const sampleIndustries = [
    ["Technology", 40],
    ["Healthcare", 25],
    ["Finance", 15],
    ["Education", 10],
    ["Retail", 10],
  ];

  const sampleData2 = [
    { definition: "Machine Learning", count: 120 },
    { definition: "Natural Language Processing", count: 85 },
    { definition: "Computer Vision", count: 95 },
    { definition: "Data Mining", count: 60 },
    { definition: "Robotics", count: 150 },
  ];

  const sampleData3 = [
    ["US", 10],
    ["JP", 20],
    ["CN", 30],
    ["KR", 40],
  ];

  const sampleTopCities = [
    { city: "New York", count: 350 },
    { city: "San Francisco", count: 270 },
    { city: "Los Angeles", count: 220 },
    { city: "Chicago", count: 190 },
    { city: "Boston", count: 160 },
  ];

  const samplePatentData = [
    {
      publication_number: "US123456A1",
      title: "Method for Producing Renewable Energy",
      assignee: ["GreenTech Innovations"],
      inventor: ["John Doe"],
      industry: "Energy",
      application_year: 2019,
      assignee_country: "USA",
      abstract:
        "A method for producing renewable energy using a new type of wind turbine designed to capture low-speed winds.",
      country: "USA",
      application_date: "2019-08-15",
    },
    {
      publication_number: "US789012B2",
      title: "Wearable Device for Health Monitoring",
      assignee: ["HealthTech Corp"],
      inventor: ["Jane Smith"],
      industry: "Healthcare",
      application_year: 2020,
      assignee_country: "UK",
      abstract:
        "A wearable device designed to monitor vital signs and detect anomalies in real-time, providing alerts to medical professionals.",
      country: "UK",
      application_date: "2020-06-10",
    },
    {
      publication_number: "US345678C3",
      title: "Artificial Intelligence System for Traffic Management",
      assignee: ["SmartCity AI"],
      inventor: ["Alan Turing"],
      industry: "Technology",
      application_year: 2021,
      assignee_country: "Germany",
      abstract:
        "An AI-based traffic management system that optimizes traffic flow in real-time to reduce congestion and improve travel times.",
      country: "Germany",
      application_date: "2021-03-12",
    },
    {
      publication_number: "US901234D4",
      title: "Advanced Materials for Battery Storage",
      assignee: ["Future Energy Materials"],
      inventor: ["Marie Curie"],
      industry: "Materials Science",
      application_year: 2022,
      assignee_country: "France",
      abstract:
        "A new class of materials for use in batteries that offer increased storage capacity and faster charging times.",
      country: "France",
      application_date: "2022-01-25",
    },
  ];

  const [selectedCountryInfo, setSelectedCountryInfo] = useState({
    city_analysis: {
      top_cities: sampleTopCities,
    },
  });

  useEffect(() => {
    // Simulating data fetching
    setIndustries(sampleIndustries);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsWidth(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setKeyPlayers(props.keyPlayers ? props.keyPlayers : []);
    setGrantPercent(props.grantPercent ? props.grantPercent : undefined);
    setResearchYear(props.topYear ? props.topYear : undefined);
    setResearchYearContribution(
      props.topYearContribution ? props.topYearContribution : undefined
    );
    setTrends(props.trends ? props.trends : undefined);
  }, [props]);

  return (
    <div>
      <Tracking
        title={"Research overview for :" + props.query}
        description={"Patents in " + props.query}
        keywords={props.query}
      />
      {/* <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <p className="keyPoints">Key Trends</p>
          <p className="para" style={{ fontWeight: "bold", marginTop: "20px" }}>
            Recently in the year {researchYear}, {researchYearContribution}{" "}
            patents were filed.
          </p>
          {keyPlayers[0] && (
            <div className="trendText" style={{ marginTop: "10px" }}>
              <ul className="keyPointsUL">
                <li style={{ marginBottom: "10px" }}>
                  {typeof grantPercent !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {grantPercent}%
                      </strong>{" "}
                      of the patent applications filed are granted.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof researchYear !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {researchYear}
                      </strong>{" "}
                      observed a high research activity.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof researchYearContribution !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {researchYearContribution}
                      </strong>{" "}
                      patents were filed in{" "}
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {researchYear}
                      </strong>{" "}
                      year.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
              </ul>
            </div>
          )}
          <div className="trendGraph">
            {grantPercent && (
              <PieChart
                labels={["Granted", "Pending"]}
                values={[grantPercent, 100 - grantPercent]}
                label="Patents status"
                width="400px"
                height="auto"
              />
            )}
          </div>
        </Col>
        <Col xs="12" md="6">
          {typeof trends != "undefined" && (
            <div className="trendsGraph1">
              <LineChart
                labels={trends.labels}
                series={trends.count.map((item) => {
                  return item[0];
                })}
                name={"Patents filed"}
                title={"YoY patent filing trends"}
              />
            </div>
          )}
          {typeof trends != "undefined" && (
            <div className="trendsGraph2">
              <LineChart
                labels={trends.labels}
                series={trends.count.map((item) => {
                  return item[1];
                })}
                name={"Patents published"}
                title={"YoY patent publication trends"}
                axisLabel={"Patents Published"}
              />
            </div>
          )}
        </Col>
      </Row> */}

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="12">
          <div className="sectionHeading2">Top Companies</div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Google is the leading company in developing geolocation data.
            </h1>
          </div>

          <h2
            className="globalText"
            style={{
              fontSize: "0.8rem",
              marginTop: "10px",
              width: "70%",
              marginBottom: "20px",
            }}
          >
            Google is leading the technology focus by developing 200+
            technologies.
          </h2>

          <FullCompanyTable
            data={sampleDataTable}
            heading=""
            showDropdown={true}
            technologies={technologies}
          />
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "#5b5b5b",
          color: "black",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6" className="borderRight">
          <div className="sectionHeading2">Company Overview</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Google is developing 200+ technologies, with recent focus on
              instruction perfetching, packet discarding & relaying, and
              artificial neural networks.
            </h1>
          </div>

          <div className="d-flex justify-content-between patentsHeader">
            <div
              className="headline-3 d-flex"
              style={{
                fontSize: "0.7rem",
                marginLeft: "25px",
                marginTop: "30px",
              }}
            >
              Click to read patents
            </div>

            <div className="patentAction">
              {/* Dropdown for selecting company */}
              <div onClick={handleButtonClick}>
                <select
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {companies.map((company) => (
                    <option
                      key={company[0]}
                      value={company[0]}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {company[1]}
                    </option>
                  ))}
                </select>
              </div>

              {/* Dropdown for selecting technology */}
              <div onClick={handleButtonClick}>
                <select
                  value={selectedTechnology}
                  onChange={handleTechnologyChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {technologies.map((technology) => (
                    <option
                      key={technology[0]}
                      value={technology[0]}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {technology[1]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <PatentList
            keyApplications={samplePatentData}
            downloadOption={{ show: true, params: { assignee: "All Patents" } }}
          />
        </Col>
        <Col xs="12" md="6">
          <div className="sectionHeading2">Statistical Overview</div>
          <div className="forStats">
            {/* <p>Summary of recent 1000 patents</p> */}
            <Grid
              gridHeading={gridData.gridHeading}
              upperGrid={gridData.upperGrid}
              lowerGrid={gridData.lowerGrid}
              topPlayer={gridData.topPlayer}
            />
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "#5b5b5b",
          color: "black",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      {/* <Row className="g-0" style={{ background: "#FAF1EC" }}>
  <Col xs="12" md="12" className="gradient-background">
  <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "20px",
                width: "55%",
                fontWeight: "bold",
              }}
            >
              What are the top companies developing technology?
            </h1>
          </div>

          <h2
            className="globalText"
            style={{
              fontSize: "0.8rem",
              marginTop: "20px",
              width: "70%",
              marginBottom: "20px",
            }}
          >
            Google is leading the technology focus by developing  200+ technologies.
          </h2>

          <div className="companyTag">Top Companies</div>
          <hr
            style={{
              width: "20%",
              marginLeft: "25px",
              background:
                "linear-gradient(to right, rgb(16 16 16), rgb(13 39 238))",
              height: "10px",
              border: "none",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />



<CompanyCarousel data={sampleData} isMoving={isMoving} heading="" />


  </Col>
</Row> */}

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "#5b5b5b",
          color: "black",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6" className="borderRight">
          <div className="sectionHeading2">Top Industries</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What are the main industries of top companies?
            </h1>
          </div>

          <h2 className="paraData">
            Google LLC's core industry is computational technology, where they
            have filed 5,645 patents.
          </h2>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontSize: "0.8rem",
                marginLeft: "25px",
                marginBottom: "20px",
              }}
            >
              click & see underlying technology
            </div>
            {industries && industries.length > 1 ? (
              <PieChart
                className="specific-chart"
                labels={industries.map((item) => item[0])}
                values={industries.map((item) => item[1])}
                hideLegend={false}
                legendPosition={isMobile ? "bottom" : "right"}
                responsiveLegendPosition="top"
                width={isWidth ? "400px" : "600px"}
                responsiveWidth="400px"
                onClick={(e) => {
                  console.log(e);
                }}
              />
            ) : (
              <Skeleton circle={true} width={"100%"} height={500} />
            )}
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="sectionHeading2">Top Technologies</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What are the main technolgies of top companies?
            </h1>
          </div>

          <h2 className="paraData">
            Instruction prefetching is Google's leading technology focus.
          </h2>

          <div className="mapDataTable" style={{ marginTop: "0" }}>
            <div className="table-container" style={{ height: "auto" }}>
              <table className="vibrant-table">
                <thead>
                  <tr>
                    <th className="text-center">Technology</th>
                    <th className="text-center">Patent Count</th>
                    {/* <th className="text-center">Inventors</th>
                    <th className="text-center">Patents</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sampleData2.map((row, index) => (
                    <tr
                      key={index}
                      onClick={() => moveToTechnologyPage(row.definition)}
                    >
                      <td>{row.definition}</td>
                      <td>{row.count}</td>
                      {/* <td>{row.inventors}</td>
                      <td>{row.patents}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="show-more-container">
                <button className="show-more-button">Show More</button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "#5b5b5b",
          color: "black",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6" className="borderRight">
          <div className="sectionHeading2">Geographical Activity</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What are the important geographies?
            </h1>
          </div>

          <h2 className="paraData">
            Mountainview is the leading location, accounting for 10% of all
            patent filings.
          </h2>

          <div className="citationMap">
            <SimpleMap
              data={sampleData3}
              color="blue"
              backgroundColor="#FAF1EC"
              borderColor="black"
              label="Click & explore"
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className="sectionHeading2">Top Locations</div>
          {selectedCountryInfo ? (
            <ol>
              {selectedCountryInfo.city_analysis.top_cities.map(
                (item, index) => (
                  <li key={index} className="country-list-item">
                    <p className="country-name">{toTitleCase(item.city)}</p>
                    <span className="patents-count">{item.count} patents</span>
                  </li>
                )
              )}
            </ol>
          ) : (
            <Skeleton count={5} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Trends;
