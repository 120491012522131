import React from 'react'
import {Row,Col} from 'reactstrap'
import { useNavigate} from "react-router-dom";
import "../technologyPageHeader/technologyPageHeader.css";

import { moveToOYIPage } from '../../actions/helper';

export default function OYIPageHeader() {
    const navigate = useNavigate()
    return (
        <div>
            <hr className="mt-0"/>
            <Row className="g-0">
                <Col xs={{ span: 12, order: 1 }} md="6">
                    <div className="companyHeader">
                    <div>
                        <div style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
                            Own Your Invention
                        </div>
                    </div>
                    <h2 className="details-title">
                    Discover | Transform | Innovate
                    </h2>
                    </div>
                </Col>
                <Col xs={{ span: 12, order: 2 }} md="6" style={{display: "flex"}}>
                <div className="sectionContainer">
                    <div
                        className={`sectionButton ${
                        window.location.pathname.split("/").at(-1) == "idf"
                            ? "bold Outline"
                            : ""
                        }`}
                        onClick={() => moveToOYIPage(navigate,"idf")}
                    >
                        IDF
                    </div>
                    {/* <div
                        className={`sectionButton ${
                        window.location.pathname.split("/").at(-1) == "reports"
                            ? "bold Outline"
                            : ""
                        }`}
                        onClick={() => moveToOYIPage(navigate,"reports")}
                    >
                        Reports
                    </div> */}
                    <div
                        className={`sectionButton ${
                        window.location.pathname.split("/").at(-1) == "drafts"
                            ? "bold Outline"
                            : ""
                        }`}
                        onClick={() => moveToOYIPage(navigate,"drafts")}
                    >
                        Drafts
                    </div>
                    <div
                        className={`sectionButton ${
                        window.location.pathname.split("/").at(-1) == "file"
                            ? "bold Outline"
                            : ""
                        }`}
                        onClick={() => moveToOYIPage(navigate,"file")}
                    >
                        File Patents
                    </div>
                    </div>
                </Col>
            </Row>
            
        </div>
    )
}