import React, { useEffect, useRef } from "react";
import "./partners.css";
import { useNavigate } from "react-router-dom";

const Partners = ({ props, isMoving }) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const startScroll = () => {
      animationRef.current = requestAnimationFrame(scroll);
    };

    const stopScroll = () => {
      cancelAnimationFrame(animationRef.current);
    };

    const scroll = () => {
      if (container.scrollLeft <= 0) {
        container.scrollTo({ left: container.scrollWidth, behavior: "auto" });
      } else {
        container.scrollLeft -= 1.5;
      }

      animationRef.current = requestAnimationFrame(scroll);
    };

    if (isMoving) {
      startScroll();
      container.addEventListener("mouseenter", stopScroll);
      container.addEventListener("mouseleave", startScroll);
    } else {
      stopScroll();
      container.removeEventListener("mouseenter", stopScroll);
      container.removeEventListener("mouseleave", startScroll);
    }

    return () => {
      cancelAnimationFrame(animationRef.current);
      container.removeEventListener("mouseenter", stopScroll);
      container.removeEventListener("mouseleave", startScroll);
    };
  }, [isMoving]);

  // Mock data for company logos
  const companyLogos = [
    {
      id: 1,
      name: "Shaping Tomorrow",
      image: "/logo/st-logo-large.png",
      width: "150px",
      height: "40px",
      marginTop: "20%",
    },
    {
      id: 2,
      name: "AIC GIM, GOA",
      image: "/logo/aic_gim_logo.jpg",
      width: "150px",
      height: "55px",
      marginTop: "20%",
    },
    {
      id: 3,
      name: "Indian Institute of Technology (ISM), Dhanbad",
      image: "/logo/ism_dhanbad_logo.png",
      width: "100px",
      height: "100px",
    },
    {
      id: 4,
      name: "TEXMIN IIT (ISM)",
      image: "/logo/Texmin-logo.png",
      width: "150px",
      height: "40px",
      marginTop: "20%",
    },
    {
      id: 5,
      name: "PreEmpt",
      image: "/logo/pre-empt-logo.png",
      width: "150px",
      height: "40px",
      marginTop: "20%",
    },
    {
      id: 6,
      name: "K-StartUp",
      image: "/logo/ksgc_logo.png",
      width: "150px",
      height: "40px",
      marginTop: "20%",
    },
    {
      id: 7,
      name: "StartUp India",
      image: "/logo/startup_india.png",
      width: "150px",
      height: "40px",
      marginTop: "20%",
    },
    {
      id: 8,
      name: "Intercepting Horizons",
      image: "/logo/intercepting.webp",
      width: "150px",
      height: "50px",
      marginTop: "20%",
    },
    {
      id: 9,
      name: "Department of Science & Technology",
      image: "/logo/dst-logo1.jpg",
      width: "170px",
      height: "50px",
      marginTop: "20%",
    },
    {
      id: 10,
      name: "Indian Institute of Technology, MANDI",
      image: "/logo/iit_mandi.png",
      width: "180px",
      height: "50px",
      marginTop: "20%",
    },
    {
      id: 11,
      name: "Indian Institute of Technology, Madras",
      image: "/logo/IITMOD-Logo.svg",
      width: "150px",
      height: "40px",
      marginTop: "20%",
    },
    {
      id: 12,
      name: "Indian Institute of Technology, Delhi",
      image: "/logo/iit_delhi.png",
      width: "100px",
      height: "100px",
      marginTop: "20%",
    },
    {
      id: 13,
      name: "Indian Institute of Information Technology, Una",
      image: "/logo/iiit_una.png",
      width: "100px",
      height: "100px",
      marginTop: "20%",
    },
  ];

  return (
    <div>
      <div className="para-button" style={{ marginTop: "0px" }}>
            <p
              className="para"
              style={{
                marginTop: "0px",
                fontSize: "1.3rem",
                fontWeight: "bolder",
                marginBottom: "0px",
                marginLeft: "10px",
                marginRight: "10px",
                textAlign: "center",
                width: "auto",
                background:
                  "linear-gradient(to right, rgb(2, 11, 38), rgb(0, 127, 255)) text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
            Driving the Future with DYR
            </p>
          </div>


      <div className="company-logos-container" ref={containerRef}>
        {companyLogos.map((company) => (
          <div className="company-logo" key={company.id}>
            <img
              src={company.image}
              alt={company.name}
              style={{
                width: company.width,
                height: company.height,
                backgroundColor: company.backgroundColor,
                marginTop: company.marginTop,
              }}
            />
            <div className="logoName">{company.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
