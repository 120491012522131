import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import GridPatent from "../../components/Grid/gridPatent";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { Popover, OverlayTrigger } from "react-bootstrap";
import "../../pages/patentView/patentView.css";
import { Tracking } from "../../components/tracking/tracking";
import { FaSpinner } from "react-icons/fa";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FcLikePlaceholder } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
import { MdShare } from "react-icons/md";
import { trackUsage } from "../../actions/helper";

const PatentAbstract = (props) => {
  const [publicationNumber, setPublicationNumber] = useState(
    window.location.pathname.split("/").at(-1)
  );
  const [downloadingFlag, setDownloadingFlag] = useState(true);
  const abstract = props.abstract;
  const flag = props.flag;

  const downloadApplicationURL = "https://api.incubig.org/download/patent-pdf";
  // const downloadApplicationURL =
  // "https://bddl8e1hrg.execute-api.ap-south-1.amazonaws.com/production/download/patent";

  const [loginPopoverShow, setLoginPopoverShow] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const isLoggedIn = localStorage.getItem("token") !== null;

  const loginPopover = (
    <Popover id="login-popover">
      <Popover className="popoverBody">Please log in to like.</Popover>
    </Popover>
  );

  const handleLike = () => {
    if (isLoggedIn) {
      trackUsage(
        "patent",
        { publication_number: publicationNumber, liked: true },
        props.cpc
      );
      setIsLiked(!isLiked);
    } else {
      setLoginPopoverShow(true);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Check out this document:",
          url: window.location.href,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  const downloadPDF = (pn) => {
    setDownloadingFlag(false);
    if (pn.startsWith("US") && pn.endsWith("A1")) {
      pn = pn.substr(2, pn.length - 4);
      window.open(
        "https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/" +
          pn,
        "_blank"
      );
      setDownloadingFlag(true);
    } else if (pn.startsWith("US") && pn.endsWith("B2")) {
      pn = pn.substr(2, pn.length - 4);
      window.open(
        "https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/" +
          pn,
        "_blank"
      );
      setDownloadingFlag(true);
    } else if (pn.startsWith("US")) {
      pn = pn.substr(2, pn.length - 2);
      window.open(
        "https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/" +
          pn,
        "_blank"
      );
      setDownloadingFlag(true);
    } else if (pn.contains("/")) {
      console.log("contains /");
    }

    const config = {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
        "x-api-key":
          "60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
      },
      params: { publication_number: pn },
    };
    // axios.get(downloadApplicationURL, config).then((response) => {
    //     if (response.status === 200) {
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", publicationNumber + ".pdf");
    //       document.body.appendChild(link);
    //       link.click();
    //       setDownloadingFlag(true);
    //     }
    //     else{
    //       console.log(response.data);
    //       setDownloadingFlag(true);
    //     }
    // })
  };

  return (
    <div>
      <Tracking
        title={
          props.publicationNumber +
          " : " +
          (props.title ? props.title : " US patent")
        }
        description={props.abstract}
        keywords={props.title}
      />
      <Row
        className="g-0"
        style={{ display: "flex", flexDirection: "row", height: "550px" }}
      >
        <Col md="6" className="borderRight">
          {flag ? (
            <div className="content-wrapper">
              <section className="raw patent-info">
                <section className="abstract">
                  <div className="abstract"></div>
                  <div style={{ overflow: "hidden" }}></div>
                  <div
                    style={{
                      lineHeight: "1.5",
                      marginTop: "0px",
                      maxHeight: "500px",
                      overflowY: "auto",
                    }}
                  >
                    <strong style={{ fontSize: "0.8rem" }}>Abstract : </strong>
                    <br />
                    {typeof abstract != "undefined" ? (
                      <div style={{ fontSize: "0.8rem", marginTop: "10px" }}>
                        {abstract}
                      </div>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </section>
              </section>
            </div>
          ) : (
            <div className="content-wrapper">
              <div>
                <section
                  className="raw patent-info"
                  style={{
                    maxHeight: "350px",
                    overflowY: "auto",
                  }}
                >
                  <section className="abstract">
                    <br />
                    <Skeleton />
                    <div className="abstract-container"></div>

                    <div style={{ lineHeight: "1.5", marginTop: "0px" }}>
                      <strong style={{ fontSize: "0.8rem" }}>
                        Abstract :{" "}
                      </strong>
                      <br />
                      <Skeleton count={5} />
                    </div>
                  </section>
                </section>
              </div>
            </div>
          )}
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="6" style={{ overflowY: "auto" }}>
          <div className="forStats">
            <GridPatent
              showPublication={true}
              gridHeading="Patent details"
              publicationNumber={props.publicationNumber}
              applicationDate={props.applicationDate}
              publicationDate={props.publicationDate}
              grantDate={props.grantDate}
              expiryDate={props.expiryDate}
              assignees={props.assignees}
              inventors={props.inventors}
              status={props.status}
              researchArea={props.researchArea}
              industry={props.industry}
            />

            <div className="actionButtons">
              <div className="downloadButton">
                {publicationNumber.length === 11 && (
                  <div
                    title="Download Patent"
                    onClick={() => {
                      downloadPDF(props.publicationNumber);
                    }}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      color: "#4007a2",
                    }}
                  >
                    {downloadingFlag ? "Download" : <FaSpinner />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PatentAbstract;
