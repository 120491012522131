import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";
import { toTitleCase } from "../../actions/helper";
import PieChart from "../../components/Charts/pieChart";
import { CpcTable } from "../../components/Tables/cpcTable";
import { Tracking } from "../../components/tracking/tracking";
import DonutChart from "../../components/Charts/pieChart";

export const SearchResultsTechnologies = (props) => {
    const technologies = props.technologies;
    const industries = props.industries;
    const isMobile = window.innerWidth <= 768;
    const isWidth = window.innerWidth <= 768;
    return (
    <div>
      <Tracking
        title={"Research overview for :" + props.query}
        description={"Patents in " + props.query}
        keywords={props.query}
      />
      <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Navigate Technologies
            </span>
          </div>
          {technologies && technologies.length > 1 ? (
            <p
              className="globalText"
              style={{ marginTop: "10px", fontSize: "1rem" }}
            >
              <span style={{ fontWeight: "bold" }}>
                {toTitleCase(technologies[0].definition)}
              </span>
              , is most active, followed by{" "}
              {toTitleCase(technologies[1].definition)}.
            </p>
          ) : (
            <Skeleton />
          )}
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ fontSize: "0.8rem", marginLeft: "25px", marginBottom: "20px" }}>
              click & see underlying technology
            </div>
              {industries && industries.length > 1 ? (
              <PieChart className="specific-chart"
                labels={industries.map((item) => item[0])}
                values={industries.map((item) => item[1])}
                hideLegend={false}
                legendPosition={isMobile ? 'bottom' : 'right'}
                responsiveLegendPosition="top"
                width={isWidth ? '400px' : '600px'}
                responsiveWidth="400px"
                onClick={(e) => {console.log(e)}}
              />
            ) : (
              <Skeleton circle={true} width={"100%"} height={500} />
            )}
          </div>
        </Col>
        <Col xs="12" md="6" style={{ paddingRight: "5%" }}>
          <CpcTable data={technologies} showHeading={true} showCount={true} heading={"Top technologies (click to view)"} />
        </Col>
      </Row>
    </div>
  );
};
