import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Row,Col, Button} from 'reactstrap'
import {jwtDecode} from 'jwt-decode'
import Skeleton from 'react-loading-skeleton'
import { moveToForYouPage, moveToReportsPage, toTitleCase, trackUsage } from '../../actions/helper';
import { useNavigate } from 'react-router-dom'
import "../oyiIDF/oyiIDF.css";

export default function OYIReports(props) {
    const navigate = useNavigate()
    const getIDFurl = "https://api.incubig.org/solutions/idf"
    const [idfs,setIDFs] = useState()
    const [noIDFflag,setNoIDFflag] = useState(false)

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            let userEmail = jwtDecode(token).user.email;
            axios.get(getIDFurl, {
                headers: {
                    "auth-token": token
                },
                params:{
                    "email":userEmail
                }
            })
            .then((response) => {
                if(response.data.data.length == 0){
                    setNoIDFflag(true)
                }
                setIDFs(response.data.data)
            })
        }
    }, []);

    return (
        <div>
            <Row className="g-0">
                <Col xs="12">
                    {(idfs)
                    ?
                    <div>
                        {idfs.map((idf,IDFindex) => 
                                <div key={IDFindex} className='idfSection' onClick={()=>{moveToReportsPage(navigate,idf.id)}}>
                                    <h3 className='idfTitle'>Title :- <span style={{fontWeight: "normal",fontSize: "1.1rem" }}>{toTitleCase(idf.title)}.</span></h3>
                                    <p className='idfNovel'>Novel Element :- <span style={{fontWeight: "normal"}}>{toTitleCase(idf.novel_element)}.</span></p>
                                    <p style={{fontWeight: "bold", marginBottom: "10px"}} className='idfFeature'>Features :- </p>
                                    {idf.features.map((feature,index) => 
                                        <li key={index} className='idfFeatureli'>{toTitleCase(feature)}</li>
                                    )}
                                    <br />
                                    <Button className='idfButton' onClick={()=>{moveToReportsPage(navigate,idf.id)}}>View Report</Button>
                                </div>
                        )
                        }
                    </div>
                    :
                    <div>
                        <div className='idfSection'>
                            <Skeleton/>
                        </div>
                    </div>
                    }
                    {
                        (noIDFflag)
                        ?
                        <div>
                            <h3>No IDFs found</h3>
                            <Button onClick={() => moveToForYouPage(navigate,"report")}>Generate Novelty</Button>
                        </div>
                        :
                        <div></div>
                    }
                </Col>
            </Row>
            
        </div>
    )
}