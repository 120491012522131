import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "./technologyPageHeader.css";
import { moveToTechnologyPage, toTitleCase } from "../../actions/helper";

const TechnologyHeader = (props) => {
  const selectedTechnology = props.selectedTechnology
  const technologyOverview = props.technologyOverview

  const [updateFilter, setUpdateFilter] = useState(false);
  const [technologyNameSuggestions, setTechnologyNameSuggestions] = useState([]);

  const technologySuggestionsAPI = 
    "https://api.incubig.org/xsearch/technology-search/";

    const handleUpdate = () => {
      setUpdateFilter(true);
    };

  const handleNameChange = (e) => {
    if (e.target.value.length > 0) {
      axios
        .get(technologySuggestionsAPI, {
          params: { query: e.target.value },
          headers: {
            "x-api-key":
              "60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
          },
        })
        .then((response) => {
          setTechnologyNameSuggestions(response.data);
        });
    }
  };

  const handleSuggestionClick = (item) => {
    if (item) {
      setUpdateFilter(false);
      setTechnologyNameSuggestions([]);
      moveToTechnologyPage(navigate, "overview",[item]);
    }
    
  };

  const navigate = useNavigate();

  return (
    <div>
      <hr className="mt-0"/>

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="6">
          <div className="technologyMonitor">
            {/* <div>
              {selectedTechnology ? (
                <h1 style={{ fontSize: "1.3rem", marginLeft: "11px"}}>
                  {selectedTechnology.definition}
                </h1>
              ) : (
                <Skeleton />
              )}
            </div> */}

              <div style={{position: "relative"}}>
              {!updateFilter ? (
                <h1 style={{ fontSize: "1.3rem", marginLeft: "12px" }}>
                  {(selectedTechnology)?toTitleCase(selectedTechnology.definition):""}&nbsp;  
                  <button className="updateButton"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </h1>
                ) 
                : 
                (
                  <h1 style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
                    <input
                      style={{
                        padding: "8px 12px",
                        fontSize: "1.3rem",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        marginRight: "8px",
                      }}
                      onChange={handleNameChange}
                    />
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        width: "50%",
                        marginTop: "5px",
                        position: "absolute",
                        zIndex: "9999",
                        maxHeight: "200px",
                        overflow: "auto",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {technologyNameSuggestions.map((item, index) => (
                      <li
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleSuggestionClick(item)}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#f9f9f9",
                          padding: "8px 12px",
                          borderRadius: "4px",
                          marginBottom: "2px",
                          transition: "background-color 0.3s ease", 
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#ebebeb";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#f9f9f9"; 
                        }}
                      >
                        {item.description} 
                      </li>
                    ))}
                    </ul>
                  </h1>
                )}
                </div> 

            {technologyOverview?
            <h2 className="details-title">
                 {technologyOverview.total_applications} patents filed | {technologyOverview.country_analysis.total_countries} countries working 
                  | {technologyOverview.assignee_analysis.total_assignees} active organizations
            </h2>
            :
            <h2>
              <Skeleton/>
            </h2>
            }
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="6" style={{display: "flex"}}>
        <div className="sectionContainer">
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "overview"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"overview",[selectedTechnology])}
            >
              Overview
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "dataset"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"dataset",[selectedTechnology])}
            >
              Dataset
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "trends"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"trends",[selectedTechnology])}
            >
              Trends
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "global"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"global",[selectedTechnology])}
            >
              Global
            </div>
            {/* <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "organization"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToTechnologyPage(navigate,"organization")}
            >
              Organization
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TechnologyHeader;
