import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useLocation } from "react-router-dom";
import { moveToCompanyPage, toTitleCase } from "../../actions/helper";
import { FaLocationDot } from "react-icons/fa6";
import "../../sections/technologyPageHeader/technologyPageHeader.css";
import axios from "axios";

const CompanyPageHeader = (props) => {
  const navigate = useNavigate();
  const selectedCompany = props.selectedCompany;
  const companyTechnologies = props.companyTechnologies;
  const companyLocation = props.companyLocation;

  const [updateFilter, setUpdateFilter] = useState(false);
  const [companyNameSuggestions, setCompanyNameSuggestions] = useState([]);

  const assigneeSuggestionsAPI =
    "https://api.incubig.org/analytics/assignee-suggestions";

  const handleUpdate = () => {
    setUpdateFilter(true);
  };

  const handleNameChange = (e) => {
    if (e.target.value.length > 0) {
      axios
        .get(assigneeSuggestionsAPI, {
          params: { assignee: e.target.value },
          headers: {
            "x-api-key":
              "60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
          },
        })
        .then((response) => {
          setCompanyNameSuggestions(response.data);
        });
    }
  };

  const handleSuggestionClick = (name) => {
    setUpdateFilter(false);
    setCompanyNameSuggestions([]);
    moveToCompanyPage(navigate, name, "overview");
  };

  return (
    <div>
      <hr className="mt-0" />

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="6">
          <div className="companyHeader">
          <div style={{ position: "relative" }}>
  {!updateFilter ? (
    <h1 style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
      {toTitleCase(selectedCompany)}{" "}
      <button className="updateButton"
        onClick={handleUpdate}
      >
        Update
      </button>
    </h1>
  ) : (
    <h1 style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
      <input
        style={{
          padding: "8px 12px",
          fontSize: "1.3rem",
          border: "1px solid #ccc",
          borderRadius: "4px",
          marginRight: "8px",
        }}
        placeholder={selectedCompany}
        onChange={handleNameChange}
      />
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          width: "50%",
          marginTop: "5px",
          position: "absolute",
          zIndex: "9999",
          maxHeight: "200px",
          overflow: "auto",
          backgroundColor: "#ffffff",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {companyNameSuggestions.map((item, index) => (
          <li
            key={index}
            className="suggestion-item"
            onClick={() => handleSuggestionClick(item)}
            style={{
              cursor: "pointer",
              backgroundColor: "#f9f9f9",
              padding: "8px 12px",
              borderRadius: "4px",
              marginBottom: "2px",
              transition: "background-color 0.3s ease", 
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#ebebeb";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#f9f9f9"; 
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </h1>
  )}
</div>


            <h2 className="details-title">
              {companyTechnologies &&
              companyTechnologies.constructor === Array ? (
                companyTechnologies.join(" | ")
              ) : (
                <Skeleton />
              )}
            </h2>
            <div
              style={{
                fontSize: "0.8rem",
                marginLeft: "25px",
                marginTop: "20px",
              }}
            >
              <FaLocationDot /> {companyLocation}
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="6" style={{ display: "flex" }}>
          <div className="sectionContainer">
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "overview"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToCompanyPage(navigate, selectedCompany, "overview")
              }
            >
              Overview
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "data"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToCompanyPage(navigate, selectedCompany, "data")
              }
            >
              Dataset
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "trends"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToCompanyPage(navigate, selectedCompany, "trends")
              }
            >
              Trends
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "explore"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToCompanyPage(navigate, selectedCompany, "explore")
              }
            >
              Technologies
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyPageHeader;
