import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import "../../pages/patentView/patentView.css";
import LineChart from "../../components/Charts/lineChart";
import { CpcTable } from "../../components/Tables/cpcTable";
import { Tracking } from "../../components/tracking/tracking";

const PatentTechnology = (props) => {
  const researchArea = props.researchArea
  const industry = props.industry 
  const technologyOverview = props.technologyOverview
  const researchAreaTrends = props.researchAreaTrends
  const patentFields = props.patentFields


  return (
    <div>
        <Tracking title={props.publicationNumber+" : "+props.title} description={props.patentFields} keywords={props.patentFields}/>
        <Row className="g-0" style={{marginBottom: "30px"}}>
            <Col xs="12" md="6" className="borderRight">
            <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px"}}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              Patent technologies
            </span>
          </div>
              <div className="researchArea">
                {(researchArea)?
                <div className="researchAreaText">
                  Patent's technology{" "}
                  <strong style={{ fontSize: "1rem" }}>
                    "{researchArea}"
                  </strong>{" "}
                  under industry{" "}
                  <strong style={{ fontSize: "1rem" }}>"{industry}"</strong>{" "}
                  was active in{" "}
                  <strong style={{ fontSize: "1rem" }}>
                    {technologyOverview.bestYear}
                  </strong>
                  , with{" "}
                  <strong style={{ fontSize: "1rem" }}>
                    {technologyOverview.bestYearCount}
                  </strong>{" "}
                  patents filed in this year.
                </div>
                :
                <div className="researchAreaText">
                  <Skeleton/>
                </div>
                }
                {(researchAreaTrends)?
                  <div className="researchAreaChart">
                    <LineChart
                      labels={researchAreaTrends.labels}
                      series={researchAreaTrends.count.map((item) => {
                        return item[0];
                      })}
                      name={"Patents filed"}
                      title={"YoY patent filing trends"}
                    />
                  </div>
                  :
                  <Skeleton/>
                }
              </div>
            </Col>
            <Col xs="12" md="6" style={{paddingRight: "5%"}}>
              {patentFields ? (
                <CpcTable data={patentFields} showHeading={true} heading={"Top technologies (click to view)"} />
              ) : (
                <Skeleton count={5} />
              )}
            </Col>
          </Row>

    </div>
  )
}

export default PatentTechnology;