import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import CompanyPageHeader from "../../sections/companyPageHeader/companyPageHeader";
import CompanyPageOverview from "../../sections/companyPageOverview/companyPageOverview";
import CompanyPageTrends from "../../sections/companyPageTrends/companyPageTrends";
import CompanyPageExplore from "../../sections/companyPageExplore/companyPageExplore";
import { Footer } from "../../components/footer/footer";
import axios from "axios";
import { toTitleCase, trackUsage } from "../../actions/helper";
import CompanyPageData from "../../sections/companyPageData/companyPageData";

const CompanyPage = () => {
  const params = useParams()
  const [selectedCompany, setSelectedCompany] = useState(decodeURIComponent(params.assignee))
  const [assigneeOverview,setAssigneeOverview] = useState()
  const [companyDescription, setCompanyDescription] = useState()
  const [topYear, setTopYear] = useState()
  const [topYearCount, setTopYearCount] = useState()
  const [grantRate,setGrantRate] = useState()
  const [technologies,setTechnologies] = useState()
  

  const assigneeAPI = 
    "https://api.incubig.org/analytics/assignee"
  const cpcDefinitionAPI = 
    "https://api.incubig.org/analytics/cpc-definition";
  

  useEffect(()=>{
    clearState()
    setSelectedCompany(decodeURIComponent(params.assignee))
    axios.get(assigneeAPI,{
      headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},
      params:{assignee:decodeURIComponent(params.assignee)}})
      .then((response)=>{
      // console.log(response.data)
      updateState(response.data)
      trackUsage("company",{name:decodeURIComponent(params.assignee)},response.data.top_industries?response.data.top_industries[0].cpc:null)
    }) 
  },[params.assignee])


  const updateState = (data) => {
    setAssigneeOverview(data);
    let tempTopYear = new Date().getFullYear();
    let tempTopYearCount = 0;
    data["application_trends"].forEach((item) => {
      if (item.count > tempTopYearCount) {
        tempTopYear = item.year;
        tempTopYearCount = item.count;
      }
    });
    setTopYear(tempTopYear);
    setTopYearCount(tempTopYearCount);
    data.status.forEach((item) => {
      if (Object.keys(item)[0] === "Active") {
        setGrantRate(
          (
            (item["Active"] * 100) /
            data["total_applications"]
          ).toFixed(1)
        );
      }
    });
    let topIndustries = data.top_industries.filter((item)=>{return item.cpc!=null})
    
    axios.post(cpcDefinitionAPI, topIndustries.map((item)=> {return item.cpc})).then((resp) => {
      let tempFields = []
      topIndustries.forEach((item)=>{
        tempFields.push({"cpc":item.cpc,"count":item.count,"definition":resp.data.filter(i => i.cpc == item.cpc)[0].definition})
      })
      setTechnologies(tempFields)
      setCompanyDescription(toTitleCase(params.assignee)+" is a company from "+data.location_info[0].country+", primarily working on '"+tempFields[0].definition+"'. It is part of "+data.top_industries[0].industry+", where company has filed "+data.top_industries[0].count+" patents. Total patents filed by company are "+data.summary.applications+".")
   
    });

  }

  const clearState = () => {
    setAssigneeOverview()
    setCompanyDescription()
    setTopYear()
    setTopYearCount()
    setTechnologies()
  }

  const filterByInventor = (inventor) => {
    clearState()
    if (inventor) {
      axios.get(assigneeAPI,{
        headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},
        params:{assignee:selectedCompany,inventor:inventor}})
        .then((response)=>{
        // console.log(response.data)
        updateState(response.data)
      })
    }
    else {
      axios.get(assigneeAPI,{
        headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},
        params:{assignee:selectedCompany}})
        .then((response)=>{
        // console.log(response.data)
        updateState(response.data)
      })
    }
  }

  return (
    <div className="companyPage">
      <Row className="g-0">
        <Col xs="12">
          <PersonalizedHeader />
        </Col>
      </Row>
      
      <CompanyPageHeader
        selectedCompany={selectedCompany}
        companyTechnologies={(assigneeOverview)?assigneeOverview.top_industries.map((item)=>{return item.industry}):undefined}
        companyLocation={assigneeOverview&&assigneeOverview.location_info.length>0?toTitleCase(assigneeOverview.location_info[0].city)+", "+assigneeOverview.location_info[0].country:undefined}
      />

      <Row className="g-0">
        <Col xs="12">
          <hr className="thin-horizontal-line" />
          <span
            className="headingSearch"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.75rem",
              background: "linear-gradient(to right, #1834dd, #d079df)",
              color: "white",
              height: "40px",
            }}
          >
            Company Page | {toTitleCase(window.location.pathname.split("/").at(-1))} 
          </span>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
      />

      {(window.location.pathname.split("/").at(-1) == "overview") |
      (window.location.pathname.split("/").at(-1) == "company-page") ? (
        <CompanyPageOverview 
          selectedCompany ={selectedCompany}
          companyDescription={companyDescription} 
          topYear={topYear} 
          assigneeOverview={assigneeOverview}
        />
      ) : (
        <div></div>
      )}

      {(window.location.pathname.split("/").at(-1) == "data") |
      (window.location.pathname.split("/").at(-1) == "company-page") ? (
        <CompanyPageData
          selectedCompany={selectedCompany} 
          grantRate={grantRate}
          assigneeOverview={assigneeOverview}
          topYear={topYear}
          filterFunction={filterByInventor}
        />
      ) : (
        <div></div>
      )}

      {(window.location.pathname.split("/").at(-1) == "trends") |
      (window.location.pathname.split("/").at(-1) == "company-page") ? (
        <CompanyPageTrends 
          selectedCompany={selectedCompany} 
          grantRate={grantRate}
          topYear={topYear}
          topYearCount={topYearCount}
          assigneeOverview={assigneeOverview}
        />
      ) : (
        <div></div>
      )}

      {(window.location.pathname.split("/").at(-1)=="explore"|window.location.pathname.split("/").at(-1)=="company-page")? (
        <CompanyPageExplore
          selectedCompany={selectedCompany}
          technologies={technologies}
          assigneeOverview={assigneeOverview}
        />
  ) : (
    <div></div>
  )}

<Row className="g-0">
        <Col xs="12">
          <div style={{height: "20px"}}>

          </div>
        </Col>
      </Row>

    <Row className="g-0">
      <Footer/>
    </Row>
      
    </div>
  );
};

export default CompanyPage;
