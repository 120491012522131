import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "../../pages/patentView/patentView.css";
import { toTitleCase } from "../../actions/helper";
import { Tracking } from "../../components/tracking/tracking";

const PatentClaim = (props) => {
  const claims = props.claims;
  const claimsText = props.claimsText
    ? props.claimsText.split("CLM-")
    : undefined;
  const description = props.description?props.description:"No Description available";

  const getClaimText = (claimText) => {
    if (claimText.constructor === Array) {
      return claimText;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <Tracking title={props.publicationNumber+" : "+props.title} description={props.description} keywords={"US patent application ,"+props.title}/>
      <Row
        className="g-0"
        style={{ display: "flex", flexDirection: "row", height: "550px" }}
      >
        <Col md="6" className="borderRight">
          <div
            style={{
              lineHeight: "1.5",
              marginTop: "10px",
              maxHeight: "520px",
              overflowY: "auto",
              marginBottom: "60px",
            }}
          >
            <div
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                marginLeft: "25px",
                position: "sticky",
                zIndex: "1",
                top: "0px",
                backgroundColor: "white",
              }}
            >
              Claims :
            </div>
            {claims && claims["@id"] != "description" 
            ? 
            (
              <div
                style={{ marginLeft: "25px", width: "90%", fontSize: "0.8rem" }}
              >
                {claims.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      color: item["claim-text"]["claim-ref"] ? "grey" : "",
                    }}
                  >
                    {/* For claim number like CLM-00001 */}
                    <span style={{ fontSize: "0.8rem" }}>
                      {item["@id"]}
                    </span>{" "}
                    <br />
                    {/* All claims will definitely have some primary text (a statement) */}
                    <p style={{ fontSize: "0.8rem" }}>
                      {item["claim-text"]["claim-ref"]
                        ? item["claim-text"]["#text"].split(",")[0] +
                          item["claim-text"]["claim-ref"]["#text"] +
                          "," +
                          item["claim-text"]["#text"].split(",").slice(1)
                        : item["claim-text"]["#text"]}
                    </p>{" "}
                    {/* Can have further sub pointers */}
                    
                    {item["claim-text"]["claim-text"] && (
                      <div>
                        {item["claim-text"]["claim-text"].constructor ===
                        Array ? (
                          <div>
                            {item["claim-text"]["claim-text"].map(
                              (text, key) => (
                                <div key={key}>
                                  {(typeof text === "object") ? (
                                    <li style={{ fontSize: "0.8rem" }}> 
                                      {(typeof text["claim-text"]==="object")?
                                      text["claim-text"]["text"]
                                      :
                                      text["#text"]
                                      }
                                    </li>
                                  ) : (
                                    <li
                                      key={key}
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {text}
                                    </li>
                                  )}
                                  <br />
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div>
                            {(typeof item["claim-text"]["claim-text"] ==="object" && item["claim-text"]["claim-text"]["claim-text"]) ? (
                              <div>
                                {item["claim-text"]["claim-text"]["claim-text"].constructor === Array 
                                ? (
                                  <div>
                                    {item["claim-text"]["claim-text"]["claim-text"].map((text, key) => (
                                      <div
                                        key={key}
                                        style={{ fontSize: "0.8rem" }}
                                      >
                                        {(typeof text === "object" && text["claim-text"])?
                                        <li style={{ fontSize: "0.8rem" }}>
                                          {(text["claim-text"].constructor === Array)
                                          ?
                                          text["claim-text"].map((pointer, pointerIndex) => (
                                            <div key={pointerIndex} style={{ fontSize: "0.8rem" }}>
                                              {pointer["#text"]}
                                            </div>
                                          ))
                                          :
                                          text["claim-text"]["#text"]
                                          }
                                          
                                        </li>
                                        :
                                        <li style={{ fontSize: "0.8rem" }}>
                                          {(typeof text === "object" && text["#text"])?text["#text"]:text}
                                        </li>
                                        }
                                      </div>
                                    ))}
                                    <br />
                                  </div>
                                ) 
                                : (
                                  <li style={{ fontSize: "0.8rem" }}>
                                    {
                                      item["claim-text"]["claim-text"]["claim-text"]
                                    }
                                  </li>
                                )}
                                <br />
                              </div>
                            ) : (
                              <li style={{ fontSize: "0.8rem" }}>
                                <div style={{ fontSize: "0.8rem" }}>
                                  {(item["claim-text"]["claim-text"]["#text"])?
                                  item["claim-text"]["claim-text"]["#text"]
                                  :
                                  item["claim-text"]["claim-text"]
                                }
                                </div>
                              </li>
                            )}
                            <br />
                          </div>
                        )}
                      </div>
                    )}
                    {/* <br /> */}
                  </div>
                ))}
              </div>
            ) 
            : 
            (
              <div
                id="claimsText"
                style={{ marginLeft: "25px", width: "90%", fontSize: "0.8rem" }}
              >
                {claimsText ? 
                (
                  <div style={{ fontSize: "0.8rem" }}>
                    {claimsText.map((item, index) => (
                      <div key={index}>
                        <p style={{ fontSize: "0.8rem" }}>
                          {item != "" && "CML-" + item}
                        </p>
                        {/* <br /> */}
                      </div>
                    ))}
                  </div>
                ) 
                :
                (
                  <Skeleton count={10} />
                )}
              </div>
            )}
          </div>
        </Col>
        <Col md="6">
          <section
            className="basic-info"
            style={{
              lineHeight: "1.5",
              marginTop: "10px",
              maxHeight: "520px",
              overflowY: "auto",
              marginBottom: "60px",
            }}
          >
            <h2
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                marginLeft: "25px",
                position: "sticky",
                zIndex: "1",
                top: "0px",
                backgroundColor: "white",
              }}
            >
              Description:
            </h2>
            {description ? (
              <div
                style={{ marginLeft: "25px", width: "90%", fontSize: "0.8rem", marginTop: "5px"}}
              >
                {toTitleCase(description)}
              </div>
            ) : (
              <div style={{ marginLeft: "25px", width: "90%" }}>
                <Skeleton count={10} />
              </div>
            )}
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default PatentClaim;
