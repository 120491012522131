import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Logo } from "../../components/logo/logo";
import { Tracking } from "../../components/tracking/tracking";
import "../signUp/signUp.css";
import { Footer } from "../../components/footer/footer";
import AcademicsTable from "../../components/Tables/academicsTable";
import BusinessTable from "../../components/Tables/businessTable";
import Customers from "../../components/ourCustomers/customer";
import axios from "axios";
import { FaUser, FaUnlock, FaLock } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { moveToResultsPage, truncateText } from "../../actions/helper";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import { jwtDecode } from "jwt-decode";
import "../../pages/signUp/signUp.css";

export const SignIn = () => {
  const [flag, setFlag] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // for tables
  const academics = ["Text", "Text"];
  const business = ["Text", "Text"];
  const location = useLocation();

  // for customers
  const customers = [
    "The process is fast, efficient, cost effective and the results have been outstanding. Strategically we can do much more at a fraction of the cost and at incredible speed.",
    "I have been working with Incubig since the last two years, and they have never disappointed me. Reports are detailed and meet all the expectations. Their report on Covid19 in early 2020 was shared with the government and was well appreciated.",
    "Incubig's solution & platform is needed in South Korea. One of the top companies in KSGC 2019 competition, signed MoUs and joint venture agreements with leading corporations.",
  ];
  const customerName = [
    "Michael Wright, Founding Partner Intercepting Horizons",
    "Satish Mehta, Director Digital India Fund",
    "Chelsea Han, Manager NIPA",
  ];

  const emailURL =
    "https://client-communication.azurewebsites.net/api/client-communication";

  const authenticationURL =
    "https://api.incubig.org/authenticate";

  const emailVerificationURL =
    "https://api.incubig.org/verify-email";

  const forgotPasswordURL =
    "https://api.incubig.org/forgot-password";

  const sendEmail = (user, orgName) => {
    const recipients = {
      to: [{ email: "ketan@incubig.org", name: "Ketan Chandra" }],
    };
    const content = {
      subject: `(System Alert) DYR new sign in`,
      body: `${user} from ${orgName} has signed in`,
      html: "",
    };
    axios.post(emailURL, { recipients: recipients, content: content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      const response = await axios.get(authenticationURL, {
        params: { user_name: email, password: password },
      });

      if (response.data.status_code === 200) {
        let decoded_token = jwtDecode(response.data.auth_token);
        setFlag(false);
        localStorage.setItem("user", decoded_token.user.name);
        localStorage.setItem("email", decoded_token.user.id);
        localStorage.setItem("token", response.data.auth_token);
        navigate("/for-you/state-of-the-art");
        sendEmail(email, decoded_token.user.employer);
      } else if (response.data.status_code === 400) {
        setErrorMessage(
          "No user account found with the provided email. Please sign up instead"
        );
      } else if (response.data.status_code === 401) {
        setErrorMessage(
          "Invalid credentials. Please check your email and password."
        );
      } else if (response.data.status_code === 403) {
        setErrorMessage(
          "Access forbidden. You do not have permission to access."
        );
      } else {
        setErrorMessage(truncateText(response.data.msg, 100));
        sendEmail(email, "");
      }
    } catch (error) {
      console.error("Login failed", error);

      if (error.response) {
        // The request was made and the server responded with a status code
        console.log(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        setErrorMessage("No response from the server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorMessage("An unexpected error occurred.");
      }

      sendEmail(email, "");
    }
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    // Implement logic to send verification code to the provided email
    // If successful, proceed to step 3: Enter verification code
    axios
      .get(emailVerificationURL, { params: { email: email } })
      .then((response) => {
        if (response.data.status_code === 200) {
          setStep(3);
        }
      });
  };

  const handleSubmitVerificationCode = (e) => {
    e.preventDefault();
    // Implement logic to verify the entered code
    // If successful, proceed to step 4: Set new password
    console.log(verificationCode);
    axios
      .get(forgotPasswordURL, {
        params: {
          email: email,
          verification_code: verificationCode,
          new_password: newPassword,
        },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          setStep(5);
        }
      });
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();
    // Implement logic to set the new password
    setStep(5);
  };

  const handleBackToLogin = () => {
    // Reset the form to step 1 (login)
    setStep(1);
  };

  const navigate = useNavigate();
  const moveToLandingPage = () => {
    navigate("/");
  };

  const moveToSignUp = () => {
    navigate("/sign-up");
  };

  const isSignupPage = location.pathname === "/sign-up";
  const isLogin = location.pathname === "/login";

  return (
    <div className="signupPage">
      <Tracking />

      <Row className="g-0">
        <PersonalizedHeader />
      </Row>
      <Row className="g-0">
        <Col
          xs="12"
          className="formLeft d-flex justify-content-center flex-column"
        >
          <p className="dontReactText">
            Discover companies, technologies
            <br />
            & innovators shaping
            <br />
            the future.
          </p>
          {/* <div
            className=" belowDontReact"
            style={{
              width: "auto",
              backgroundColor: "transparent",
              color: "white",
              marginTop: "20px",
            }}
          >
            <span className="summaryTagText belowDontReactText">
              Fuel Your R&D and Business with personalised Research
              Intelligence.
            </span>
          </div> */}
          {/* <div className="access">
            <div className="accessText">Get Started Now!</div>
          </div> */}

          <Row className="g-0">
            <div className="wrapper">
              <div className="title-text">
                <div className={`title login ${isLogin ? "active" : ""}`}>
                  Activate your DYR account. Signup now!
                </div>
              </div>
              <div className="form-container">
                <div className="form-inner">
                  {isLogin ? (
                    <form className="login" onSubmit={handleSubmit}>
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Email Address"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="field">
                        <input
                          type="password"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="pass-link">
                        <a href="#" onClick={handleForgotPasswordClick}>
                          Forgot password?
                        </a>
                      </div>
                      <div className="field btn">
                        <input type="submit" value="Login" />
                      </div>
                      <div className="signup-link">
                        Not a member?{" "}
                        <a href="#" onClick={() => navigate("/sign-up")}>
                          Signup now
                        </a>
                      </div>
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </form>
                  ) : (
                    <form className="signup" onSubmit={handleSubmitEmail}>
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Enter your academic/business email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="field">
                        <input
                          type="password"
                          placeholder="Enter password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="field">
                        <input
                          type="password"
                          placeholder="Confirm password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="field btn">
                        <input type="submit" value="Signup" />
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </Row>

          {/* <Container className="d-flex justify-content-center signupContainer">
            {flag ? (
              <div style={{ width: "95%" }}>
                {step === 1 && (
                  <div>
                    <div className="getAccessText">Login</div>
                    <Form
                      style={{ width: "100%", marginTop: "50px" }}
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                      >
                        <Label
                          for="email"
                          className="signupLabel"
                          style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "50%",
                          }}
                        >
                          <MdMail />
                          &nbsp;&nbsp;Email
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          className="signupLabel"
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                          placeholder="Enter your academic/business email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </FormGroup>
                      <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                      >
                        <Label
                          for="name"
                          className="signupLabel"
                          style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "50%",
                          }}
                        >
                          <FaUnlock />
                          &nbsp;&nbsp;Password
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          className="signupLabel"
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                          placeholder="Enter password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </FormGroup>

                      <div className="signInWrap">
                        <a
                          className="forget"
                          onClick={handleForgotPasswordClick}
                        >
                          Forgot Password?
                        </a>

                        <a className="leftSignIn" onClick={moveToSignUp}>
                          {" "}
                          Sign up instead!
                        </a>
                      </div>

                      <p className="mt-4" style={{ color: "red" }}>
                        {errorMessage}
                      </p>
                      <Button type="submit" className="signupButton">
                        Login
                      </Button>
                    </Form>
                  </div>
                )}
              </div>
            ) : (
              <div className="afterSignUp">
                <p className="mt-4">
                  You have been successfully authenticated, granting you full
                  access to the Incubig's patent analytics platform.{" "}
                </p>
                <Button
                  className="signupDYR"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    moveToResultsPage(navigate, "Cold nuclear fusion");
                  }}
                >
                  Do Your Research
                </Button>
              </div>
            )}
          </Container> */}

          <Container className="d-flex justify-content-center signupContainer">
            {flag && (
              <div style={{ width: "95%" }}>
                {step === 2 && (
                  <div>
                    <div className="getAccessText">Login</div>
                    <Form
                      style={{ width: "90%", marginTop: "50px" }}
                      onSubmit={handleSubmitEmail}
                    >
                      <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                      >
                        <Label
                          for="email"
                          className="signupLabel"
                          style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "30%",
                          }}
                        >
                          <MdMail />
                          &nbsp;&nbsp;Email
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          className="signupLabel"
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            marginBottom: "5px",
                            width: "65%",
                          }}
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </FormGroup>
                      <Button type="submit" className="signupDYR">
                        Send Verification Code
                      </Button>
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </Form>
                  </div>
                )}
              </div>
            )}
          </Container>

          <Container className="d-flex justify-content-center signupContainer">
            {flag && (
              <div style={{ width: "95%" }}>
                {step === 3 && (
                  <div>
                    <div className="getAccessText">Login</div>
                    <Form
                      style={{ width: "90%", marginTop: "50px" }}
                      onSubmit={handleSubmitVerificationCode}
                    >
                      <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                      >
                        <Label
                          for="name"
                          className="signupLabel"
                          style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                        >
                          <FaUnlock />
                          &nbsp;&nbsp;Verification Code
                        </Label>
                        <Input
                          type="code"
                          name="password"
                          id="verificationCode"
                          className="signupLabel"
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                          placeholder="0000"
                          onChange={(e) => setVerificationCode(e.target.value)}
                          required
                        />
                      </FormGroup>
                      <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                      >
                        <Label
                          for="name"
                          className="signupLabel"
                          style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                        >
                          <FaLock />
                          &nbsp;&nbsp;Enter new password
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          id="new_password"
                          className="signupLabel"
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                          placeholder="Confirm password"
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </FormGroup>
                      <Button type="submit" className="signupDYR">
                        Set New Password
                      </Button>
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </Form>
                  </div>
                )}
              </div>
            )}
          </Container>

          <Container className="d-flex justify-content-center signupContainer">
            {flag && (
              <div style={{ width: "95%" }}>
                {step === 4 && (
                  <div>
                    <div className="getAccessText">Login</div>
                    <Form
                      style={{ width: "90%", marginTop: "50px" }}
                      onSubmit={handleSubmitNewPassword}
                    >
                      <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                      >
                        <Label
                          for="name"
                          className="signupLabel"
                          style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                        >
                          <FaUnlock />
                          &nbsp;&nbsp;Set new password
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          className="signupLabel"
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                          placeholder="Set new password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </FormGroup>
                      <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                      >
                        <Label
                          for="name"
                          className="signupLabel"
                          style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                        >
                          <FaLock />
                          &nbsp;&nbsp;Confirm new password
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          className="signupLabel"
                          style={{
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            marginBottom: "5px",
                            width: "70%",
                          }}
                          placeholder="Confirm password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </FormGroup>
                      <Button type="submit" className="signupDYR">
                        Set New Password
                      </Button>
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </Form>
                  </div>
                )}
              </div>
            )}
          </Container>

          <Container className="d-flex  justify-content-center signupContainer">
            {flag && (
              <div style={{ width: "20%" }}>
                {step === 5 && (
                  <div className="afterSignUp">
                    <p className="mt-4">Password Updated. </p>
                    <Button
                      className="signinDYR"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackToLogin}
                    >
                      Log In
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Container>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0">
        <Col xs="12">
          <div
            style={{
              textAlign: "center",
              position: "relative",
              marginTop: "50px",
            }}
          >
            <div className="textAbovePortfolio">
              Powering our Global Research & Innovation community.
            </div>
            <hr className="lineBelowPortfolio" />
          </div>

          <div className="clientContainer">
            <div className="client1">
              <img
                src={process.env.PUBLIC_URL + "/incubig_michael.jfif"}
                className="rounded-circle"
                style={{ width: "50%", height: "50%" }}
                alt="My Image"
              />
              <div className="clientName">Michael Wright</div>
              <div className="clientTitle">
                Founding Partner, Intercepting Horizons
              </div>
              <div className="clientReview">
                The process is fast, efficient, cost effective and the results
                have been outstanding. Strategically we can do much more at a
                fraction of the cost and at incredible speed.
              </div>
            </div>
            <div className="client2">
              <img
                src={process.env.PUBLIC_URL + "/incubig_satish.jfif"}
                className="rounded-circle"
                style={{ width: "35%", height: "50%" }}
                alt="My Image"
              />
              <div className="clientName">Satish Mehta</div>
              <div className="clientTitle">Director, Digital India Fund</div>
              <div className="clientReview">
                RI have been working with Incubig since the last two years, and
                they have never disappointed me. Reports are detailed and meet
                all the expectations. Their report on Covid19 in early 2020 was
                shared with the government and was well appreciated.
              </div>
            </div>
            <div className="client3">
              <img
                src={process.env.PUBLIC_URL + "/incubig_chelsea.jfif"}
                className="rounded-circle"
                style={{ width: "50%", height: "50%" }}
                alt="My Image"
              />
              <div className="clientName">Chelsea Han</div>
              <div className="clientTitle">Manager, NIPA</div>
              <div className="clientReview">
                Incubig's solution & platform is needed in South Korea. One of
                the top companies in KSGC 2019 competition, signed MoUs and
                joint venture agreements with leading corporations.
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr className="lineBelowPortfolio " />

      <Row className="g-0">
        <Col xs="12">
          <div style={{ height: "20px", marginTop: "30px" }}></div>
        </Col>
      </Row>

      <Row className="g-0">
        <Col>
          <Footer />
        </Col>
      </Row>
    </div>
  );
};
