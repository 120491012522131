import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaWpforms } from 'react-icons/fa'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label } from 'reactstrap'

export const AdminPageNotifications = () => {
    const [email,setEmail] = useState()
    const [dropdownOpen,setDropdownOpen] = useState(false)
    const [dropDownValue,setDropDownValue] = useState("Select User")
    const [registeredUsers,setRegisteredUsers] = useState([])
    const navigate = useNavigate();

    const usersURL = 
        "https://api.incubig.org/authentication/users"

    useEffect(() => {
        axios.get(usersURL).then((response) => {}
        ).catch((error) => {
            console.log(error)
        })
    },[])
    const goBack = () => {
        navigate(-1);
    };

    const toggle = () => setDropdownOpen(prevState => !prevState)

    const handleChangeDropdown = (email) => {
        setDropDownValue(email)
    }

    const sendNotification = () => {

    }

    return(
        <div>
            <Container className="d-flex justify-content-center signupContainer">
                <IoArrowBackCircleOutline
                    size={25}
                    style={{ marginTop: "10px", cursor: "pointer" }}
                    onClick={goBack}
                />
                <Form style={{ width: "100%", marginTop: "50px" }} onSubmit={sendNotification}>
                    <FormGroup
                        className="mt-4 d-flex align-items-center"
                        style={{ marginBottom: "100px" }}
                    >
                        <Label
                            for=""
                            style={{
                            marginRight: "0px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                            width: "50%",
                            }}
                        >
                            Email :
                        </Label>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret>{dropDownValue}</DropdownToggle>
                            <DropdownMenu>
                            {/* {registeredUsers.map((email) => {
                                <DropdownItem
                                    onClick={() => handleChangeDropdown(email)}
                                    dropDownValue={email}
                                >
                                    {email}
                                </DropdownItem>
                            })} */}
                            </DropdownMenu>
                        </Dropdown>
                        <Button
                            className="signupDYR uploadButton"
                            style={{
                                marginRight: "0px",
                            }}
                        >
                        <FaWpforms />
                        &nbsp;&nbsp;Notify
                        </Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    )
}