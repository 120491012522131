import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Carousel } from "../../components/carousel/carousel";
import axios from "axios";

const ForYouOrganizations = (props) => {
  const [orgs,setOrgs] = useState({"umn.edu":[
    ["Regents of The University of Minnesota",1088],
    ["University of Minnesota",4],
  ],
  "iitism.ac.in":[
    ['INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES)',67],
    ["Indian Institute of Technology",5169],
    ['Chairman, defence research & development organisation (DRDO)', 101],
    ['CIIE IIT ISM',1]
  ]}
  )

  const userURL = "https://api.incubig.org/user"
  const assigneeAPI = "https://api.incubig.org/analytics/assignee"

  useEffect(() => {
    let tempOrgs = orgs
    let userID = localStorage.getItem('email')
    // axios.get(userURL).then((response)=>{
    //   let employers = response.data.data.employers
    //   console.log(response.data.data)
    //   if (response.data.data&&response.data.data.employers){
    //     if (employers.length>0){
    //       // employers = employers.map((item)=>{
    //       //   return [item.employer_name,item.count]
    //       // })
    //     }
    //     tempOrgs[props.domain] = [...tempOrgs[props.domain],...employers]
    //     console.log(tempOrgs)
    //   }
    //   else{
    //     console.log("employers not found")
    //   }
    //   setOrgs(tempOrgs)
    // })

    // const options = {
    //   method: 'GET',
    //   url: 'https://website-intelligence.p.rapidapi.com/lookup',
    //   params: {
    //     domain: props.domain
    //   },
    //   headers: {
    //     // 'X-RapidAPI-Key': '66afea8d5fmshcf1bea0514e9f7fp196028jsn6974d5ec3ed1',
    //     'X-RapidAPI-Host': 'website-intelligence.p.rapidapi.com'
    //   }
    // };

    // axios.request(options).then((response) => {
    //   console.log("got response");
    //   console.log(response.data);
    // }).catch((error) => {
    //   console.error("error");
    //   console.error(error);
    // });
  }, []);
  
  return (
    <div>
      <div
        className="summaryTag"
        style={{
          width: "auto",
          backgroundColor: "white",
          color: "black",
          marginTop: "20px",
        }}
      >
        <span
          className="summaryTagText"
          style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
        >
          Click to view patents from your organizations
        </span>
      </div>
      <div className="reportPara">
        <Carousel data={orgs[props.domain]} isMoving={true} />
      </div>

      <div style={{ textAlign: "center", position: "relative" }}>
          <hr className="belowForYou"
          />
          <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "0.75rem",
                height: "20px",
                fontWeight: "bold"
              }}
            >
          Click & view
          </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "0.75rem",
                height: "40px",
              }}
            >
              System-generated, personalized technology lists.
            </div>
          </div>
    </div>
  );
};

export default ForYouOrganizations;
