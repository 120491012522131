import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Carousel } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Tracking } from "../../components/tracking/tracking";
import "../../pages/companyPage/companyPage.css";
import Grid from "../../components/Grid/grid";
import { toTitleCase } from "../../actions/helper";

const CompanyPageOverview = (props) => {
  const companyText = props.companyDescription;
  const topYear = props.topYear;
  const assigneeOverview = props.assigneeOverview;
  const [searchStatus, setSearchStatus] = useState(false);
  const [keyPlayers, setKeyPlayers] = useState([]);

  useEffect(()=>{
    setSearchStatus(props.searchStatus);
  })

  return (
    <div>
      <Tracking
        title={props.selectedCompany + " 's patent and research portfolio"}
        description={
          "Analyze the key strength and weakness of " +
          props.selectedCompany +
          " 's patent portfolio"
        }
        keywords={props.selectedCompany}
      />

      <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <div className="companyTag">About Company</div>
          {companyText ? (
            <h2
              className="globalText"
              style={{ fontSize: "0.8rem", marginTop: "20px" }}
            >
              {companyText}
              <hr
        className="belowCompany"
      />
            </h2>
            
            ) : (
              <div
              className="globalText"
              style={{ fontSize: "0.8rem", marginTop: "20px" }}
              >
              <Skeleton count={2} />
            </div>
          )}
          <p className="keyPoints">Key Points</p>
          <ul className="keyPointsUL">
            <li style={{ marginBottom: "10px" }}>
              {(typeof assigneeOverview !== "undefined" && assigneeOverview.top_industries.length>0) ? (
                <div className="keyPointsLI">
                  <strong className="keyPointsLI" style={{ color: "black" }}>
                    {toTitleCase(props.selectedCompany)}
                  </strong>{" "}
                  {assigneeOverview.top_industries[0].count /
                    assigneeOverview.total_applications <
                  0.4
                    ? "is a very well diversified conglomerate"
                    : " is a pure play company (niche focussed)"}
                  .
                </div>
              ) : (
                <Skeleton />
              )}
            </li>
            <li style={{ marginBottom: "10px" }}>
              {typeof topYear !== "undefined" ? (
                <div className="keyPointsLI">
                  <strong className="keyPointsLI" style={{ color: "black" }}>
                    {topYear}
                  </strong>{" "}
                  year observed a high research activity.
                </div>
              ) : (
                <Skeleton />
              )}
            </li>
          </ul>
        </Col>
        <Col xs="12" md="6">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Company Overview
            </span>
          </div>
          <div className="forStats" style={{marginBottom: "30px"}}>
            <Grid
              gridHeading="Overview"
              upperGrid={[
                {
                  value: assigneeOverview
                    ? assigneeOverview.total_applications
                    : undefined,
                  caption: "Patents",
                },
                {
                  value: assigneeOverview
                    ? assigneeOverview.inventor_analysis.total_inventors
                    : undefined,
                  caption: "Inventors",
                },
                {
                  value: assigneeOverview
                    ? assigneeOverview.summary.industries
                    : undefined,
                  caption: "Industries",
                },
              ]}
              lowerGrid={[
                {
                  value: assigneeOverview
                    ? assigneeOverview.summary.technologies
                    : undefined,
                  caption: "Technologies",
                },
                { value: topYear, caption: "Active Year" },
                {
                  value:
                    assigneeOverview &&
                    assigneeOverview.inventor_analysis.top_inventors.length > 0
                      ? toTitleCase(
                          assigneeOverview.inventor_analysis.top_inventors[0]
                            .inventor
                        )
                      : undefined,
                  caption: "Top inventor",
                },
              ]}
            />
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />
      {keyPlayers.length > 0 &&
      <Row className="g-0" style={{marginBottom: "30px"}}>
        <Col md="12">
          <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "10px" }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              See other active organizations
            </span>
          </div>
          <div className="cpcTable" style={{marginLeft: "0px", marginTop: "0px"}}>
            <Carousel data={keyPlayers.slice(0, 10)} isMoving={true} />
          </div>
        </Col>
      </Row>
      }
    </div>
  );
};

export default CompanyPageOverview;
