// import React, { useState } from "react";
// import { Table } from "reactstrap";
// import Skeleton from "react-loading-skeleton";
// import "./fullCpcTable.css";

// const FullCpcTable = ({ data, showHeading = true, heading = "Patent Technologies", showCount = true, count = "Patent Count" }) => {
//   return (
//     <div className="customTableContainerFull">
//     {showHeading && (
//       <div className="tableHeadingFull">
//         {heading}
//       </div>
//     )}
//     <Table className="mainTable">
//         <thead>
//           <tr>
//             <th className="text-center">Name</th>
//             <th className="text-center">Industry Impacting</th>
//             <th className="text-center">Highest Activity</th>
//             <th className="text-center">Top Inventor</th>
//             <th className="text-center">Patents Published</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item, index) => (
//             <tr key={index} className="rowTable" onClick={() => {/* Handle row click if needed */}}>
//               <td className="tableFirstColumnFull">{item.name}</td>
//               <td className="tableSecondColumnFull">{item.industryImpacting}</td>
//               <td className="tableThirdColumnFull">{item.highestActivity}</td>
//               <td className="tableFourthColumnFull">{item.topInventor}</td>
//               <td className="tableFifthColumnFull">{item.patentsPublished}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//   </div>
// );
// };

// export default FullCpcTable;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./fullCpcTable.css";
import { moveToTechnologyPage } from '../../actions/helper';

const VibrantTable = ({ data, showMoreButton = true }) => {

  const navigate = useNavigate(); 

  const handleRowClick = (item) => {
    const section = "overview"; 
    const cpc = item.cpc ? [item] : []; 
    
    moveToTechnologyPage(navigate, section, cpc);
  };

  return (
    <div className="table-container" style={{ height: "auto" }}>
      <table className="vibrant-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Industry</th>
            <th>Inventor</th>
            <th>Patents</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }}>
              <td>{item.name}</td>
              <td>{item.industryImpacting}</td>
              <td>{item.topInventor}</td>
              <td>{item.patentsPublished}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showMoreButton && (
        <div className="show-more-container">
          <button className="show-more-button">
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

export default VibrantTable;



