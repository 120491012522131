import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import "../../pages/forYouPage/forYou.css";

const ForYouReport = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [reportRequested, setReportRequested] = useState(false);
  const email = localStorage.getItem("email");
  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  const emailURL =
    "https://client-communication.azurewebsites.net/api/client-communication";

  const sendEmail = (report) => {
    const recipients = {
      to: [{ email: "ketan@incubig.org", name: "Ketan Chandra" }],
    };
    const content = {
      subject: "(System Alert) Report Request",
      body: `${user} with ${email} email address has requested - ${report}`,
      html: "",
    };
    axios.post(emailURL, { recipients: recipients, content: content });
  };

  const togglePopover = (reportName) => {
    // setPopoverOpen(!popoverOpen);
    setReportRequested(true);
    sendEmail(reportName);
  };

  const goBack = () => {
    navigate(-1);
  };

  const redirectToIDF = () => {
    sendEmail("own your invention");
    navigate("/for-you/request-report/own-your-invention");
  };

  return (
    <div>
      {!reportRequested ? (
        <div>
          <Row className="g-0">
            <Col xs={{ span: 12, order: 1 }} md="7">
              <div className="landingReact foryouReact">
                <span
                  className="summaryTagText"
                  style={{ fontSize: "1rem", color: "black" }}
                >
                  Expert level Patent Research & business Solutions
                </span>
              </div>
            </Col>
          </Row>
          <br />

          <Row className="g-0">
            <Col xs="12">
              <p className="paraReport foryouPara">
                Get expert level research solutions.
              </p>
              <hr className="landingLinePortfolio foryouLine" />
              <div className="list-container" style={{ marginLeft: "0%" }}>
                <ul className="list">
                  <p
                    className="para"
                    style={{
                      fontWeight: "bold",
                      marginTop: "20px",
                      fontSize: "1.3rem",
                      marginLeft: "0px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    Own your invention
                  </p>
                  <li>Patent Novelty analysis.</li>
                  <li>Claim recommendations.</li>
                  <li>Increased valuation.</li>
                  <div
                    className="getReport"
                    id="Popover1"
                    onClick={() => {
                      togglePopover("Freedom to operate");
                    }}
                  >
                    <div className="getReportText">Get Report</div>
                  </div>
                </ul>
                <span className="separator"></span>

                <ul className="list">
                  <p
                    className="para"
                    style={{
                      fontWeight: "bold",
                      marginTop: "20px",
                      fontSize: "1.3rem",
                      marginLeft: "0px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    Freedom-to-operate
                  </p>
                  <li>Product infringement analysis.</li>
                  <li>Workaround strategies.</li>
                  <li>Product geo-positioning.</li>
                  <div
                    className="getReport"
                    id="Popover1"
                    onClick={() => {
                      togglePopover("Freedom to operate");
                    }}
                  >
                    <div className="getReportText">Get Report</div>
                  </div>
                </ul>
                <span className="separator"></span>
                <ul className="list">
                  <p
                    className="para"
                    style={{
                      fontWeight: "bold",
                      marginTop: "20px",
                      fontSize: "1.3rem",
                      marginLeft: "0px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    Know Your Technology
                  </p>
                  <li>Technology gaps analysis.</li>
                  <li>Right R&D strategies.</li>
                  <li>Technology advancement & growth.</li>
                  <div
                    className="getReport"
                    id="Popover1"
                    onClick={() => {
                      togglePopover("Know yout technology");
                    }}
                  >
                    <div className="getReportText">Get Report</div>
                  </div>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
            <p className="reportCard">
            <IoArrowBackCircleOutline
              size={25}
              style={{
                marginBottom: "20px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={goBack}
            />
              We have received your request, our team will get in touch with you
              within 2 working days
            </p>
        </div>
      )}
    </div>
  );
};

export default ForYouReport;