import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Popover } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { moveToInventorPage, toTitleCase } from "../../actions/helper";
import { FaLocationDot } from "react-icons/fa6";
import { FcLikePlaceholder } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
import { MdShare } from "react-icons/md";

const InventorPageHeader = (props) => {
  const navigate = useNavigate();
  const selectedInventor = props.selectedInventor;
  const companyTechnologies = props.companyTechnologies;
  const companyLocation = props.companyLocation;

  const [loginPopoverShow, setLoginPopoverShow] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const isLoggedIn = localStorage.getItem("token") !== null;

  const loginPopover = (
    <Popover id="login-popover">
      <Popover className="popoverBody">Please log in to like.</Popover>
    </Popover>
  );

  const handleLike = () => {
    if (isLoggedIn) {
      trackUsage(
        "patent",
        { publication_number: publicationNumber, liked: true },
        props.cpc
      );
      setIsLiked(!isLiked);
    } else {
      setLoginPopoverShow(true);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Check out this document:",
          url: window.location.href,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div>
      {/* <hr className="mt-0" /> */}

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="6">
          <div className="companyHeader">
            <div style={{ position: "relative" }}>
              <h1 style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
                {toTitleCase(selectedInventor)}{" "}
              </h1>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
            <h2 className="details-title">
                Japan | Mitsubishi | Communication Systems
            </h2>
            {/* Like button */}
            <div
                className="likeButton"
                title="Like this patent"
                onClick={handleLike}
                style={{ marginLeft: "10px" }}
              >
                {isLiked ? (
                  <FcLike size={25} />
                ) : (
                  <FcLikePlaceholder size={25} />
                )}
                {loginPopoverShow && (
                  <Popover id="login-popover" className="popText">
                    <div className="popText2">Please log in to like.</div>
                  </Popover>
                )}
              </div>
              {/* Share button */}
              <div
                className="shareButton"
                title="Share"
                onClick={handleShare}
                style={{ marginLeft: "10px" }}
              >
                <MdShare size={22} />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="6" style={{ display: "flex" }}>
          <div className="sectionContainer">
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "about"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToInventorPage(navigate, selectedInventor, "about")
              }
            >
              About
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "activity"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToInventorPage(navigate, selectedInventor, "activity")
              }
            >
              Activity
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "impact"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToInventorPage(navigate, selectedInventor, "impact")
              }
            >
              Impact
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "network"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() =>
                moveToInventorPage(navigate, selectedInventor, "network")
              }
            >
              Network
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InventorPageHeader;
