import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Row, Col } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { SearchSection } from "../../components/searchSection/searchSection";
import { Logo } from "../../components/logo/logo";
import { Footer } from "../../components/footer/footer";
import "./landingPage.css";
import { Tracking } from "../../components/tracking/tracking";
import Customers from "../../components/ourCustomers/customer";
import SimpleMap from "../../components/WorldMap/WorldMap";
import { FaUsers, FaCode, FaUserCog } from "react-icons/fa";
// import ExampleTable from '../../components/Tables/exampleTable';
// import CapabilityTable from '../../components/Tables/capabilityTable';
// import LimitationTable from '../../components/Tables/limitationTable';
import SampleQueries from "./sampleQueries.json";
import cachedResults from "../searchResults/cachedResults.json";
import {
  moveToResultsPage,
  moveToTechnologyPage,
  toTitleCase,
} from "../../actions/helper";
import PatentList from "../../components/patentsList/patentList";
import LineChart from "../../components/Charts/lineChart";
import DonutChart from "../../components/Charts/pieChart";
import { FaSearch } from "react-icons/fa";
import { FaUser, FaUnlock, FaLock } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import Partners from "../../components/partners/partners";
import SignupForm from "../../components/signupform/signupForm";
import { CompanyCarousel } from "../../components/carousel/companyCarousel";
import Grid from "../../components/Grid/grid";
import VibrantTable from "../../components/Tables/fullCpcTable";
import PatentcardInsight from "../../sections/forYouStateArt/patentcardInsight";
import { FaBuilding, FaMicrochip } from "react-icons/fa6";

export const LandingPage = (props) => {
  const [query, setQuery] = useState();
  const [flag, setFlag] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orgName, setOrgName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState("");
  const [emergingIndustries, setEmergingIndustries] = useState(
    Object.keys(cachedResults)
  );
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerificationMode, setIsVerificationMode] = useState(false);
  const [recentSearches, setRecentSearches] = useState();
  const [isMoving, setIsMoving] = useState(true);
  const [isLogin, setIsLogin] = useState(true);
  const [keyPlayers, setKeyPlayers] = useState([
    { org: "SAMSUNG ELECTRONICS CO., LTD.", count: 193, previousCount: 222 },
    { org: "CANON KABUSHIKI KAISHA", count: 72, previousCount: 55 },
    {
      org: "INTERNATIONAL BUSINESS MACHINES CORPORATION",
      count: 71,
      previousCount: 64,
    },
    { org: "SAMSUNG DISPLAY CO., LTD.", count: 69, previousCount: 68 },
    { org: "QUALCOMM Incorporated", count: 67, previousCount: 95 },
    { org: "Dell Products L.P.", count: 51, previousCount: 33 },
    { org: "HUAWEI TECHNOLOGIES CO., LTD.", count: 50, previousCount: 67 },
    { org: "EMC IP Holding Company LLC", count: 46, previousCount: 25 },
    { org: "Monsanto Technology LLC", count: 41, previousCount: 24 },
    { org: "TOYOTA JIDOSHA KABUSHIKI KAISHA", count: 36, previousCount: 42 },
  ]);
  const [researchTitle, setResearchTitle] = useState("Neuroscience");

  // Table variables
  const samplePatent = [
    {
      title: "Innovative Device for Efficient Energy Use",
      assignee: ["Tech Innovations Inc."],
      inventor: ["Jane Doe", "John Smith"],
      industry: "Renewable Energy",
      publication_year: 2023,
      publication_number: "US12345678B2",
      assignee_country: "United States",
      country: "US",
    },
    {
      title: "Advanced Robotics for Manufacturing Automation",
      assignee: ["Future Tech Co."],
      inventor: ["Alice Johnson"],
      industry: "Manufacturing",
      publication_year: 2022,
      publication_number: "US87654321B1",
      assignee_country: "Germany",
      country: "DE",
    },
    {
      title: "Smart Wearable Health Monitor",
      assignee: ["HealthTech Innovations"],
      inventor: ["Bob Brown", "Charlie Green"],
      industry: "Healthcare",
      publication_year: 2024,
      publication_number: "US23456789C3",
      assignee_country: "Canada",
      country: "CA",
    },
    {
      title: "Next-Generation Battery Technology",
      assignee: ["Energy Solutions Inc."],
      inventor: ["Eve White"],
      industry: "Energy Storage",
      publication_year: 2023,
      publication_number: "US34567890D4",
      assignee_country: "Australia",
      country: "AU",
    },
    {
      title: "AI-Powered Personal Assistant Software",
      assignee: ["SmartSoft Corp."],
      inventor: ["David Black", "Fiona Blue"],
      industry: "Software",
      publication_year: 2021,
      publication_number: "US45678901E5",
      assignee_country: "United Kingdom",
      country: "GB",
    },
  ];

  const sampleData = [
    {
      companyName: "Innovative technology lab co., ltd.",
      country: "South Korea",
      industry1: "Communication Technique",
      industry2: "Cloud Computing",
      technologiesCount: 74,
      topInventor: "Dong hyun park",
    },
    {
      companyName: "EcoLab Inc",
      country: "USA",
      industry1: "Petrochemical Manufacturing",
      industry2: "Oil & Fat",
      technologiesCount: 145,
      topInventor: "Kim r. smith",
    },
    {
      companyName: "Biohealthcore inc.",
      country: "South Korea",
      industry1: "Sports",
      industry2: "Mensuration",
      technologiesCount: 1,
      topInventor: "Hyo taek lee",
    },
    {
      companyName: "Robert bosch gmbh",
      country: "Germany",
      industry1: "Vehicles",
      industry2: "Computational Technology",
      technologiesCount: 7441,
      topInventor: "Stefan nordbruch",
    },
    {
      companyName: "Apple inc.",
      country: "USA",
      industry1: "Computational Technology",
      industry2: "Communication Technique",
      technologiesCount: 6378,
      topInventor: "Dawei zhang",
    },
    {
      companyName: "Greenearth cleaning, llc",
      country: "USA",
      industry1: "Treatment of Textiles",
      industry2: "Medical Science",
      technologiesCount: 7,
      topInventor: "Douglas, james, e",
    },
    {
      companyName: "Quanta computer inc.",
      country: "Taiwan",
      industry1: "Communication Technique",
      industry2: "Electric Heating",
      technologiesCount: 714,
      topInventor: "Chao-jung chen",
    },
  ];

  const sampleData2 = {
    gridHeading: "Overview",
    upperGrid: [
      { caption: "Players", value: "11655" },
      { caption: "Technologies", value: "14936" },
      // { caption: "Industries", value: "51" },
    ],
    lowerGrid: [
      { caption: "Innovators", value: "3476" },
      // { caption: "Industries", value: "51" },
      // { caption: "Top Inventor", value: "Matthew Shariffi" },
    ],
  };

  const sampleTechnologyData = [
    {
      name: "Machine Learning",
      cpc: "G06N 20/00",
      industryImpacting: "Computing",
      topInventor: "Charles Howard Cella",
      patentsPublished: 26766,
    },
    {
      name: "Automatic pilot",
      cpc: "G05D 1/00",
      industryImpacting: "Aviation",
      topInventor: "Donald R. High",
      patentsPublished: 12889,
    },

    {
      name: "Fuel cells",
      cpc: "H01M 2250/00",
      industryImpacting: "Electric Elements",
      topInventor: "Joachim F. OPPELT",
      patentsPublished: 14,
    },

    {
      name: "Biological computer models",
      cpc: "G06N 3/00",
      industryImpacting: "Computing",
      topInventor: "Jeffrey P. McGuckin",
      patentsPublished: 959,
    },

    {
      name: "Cardiovascular drugs",
      cpc: "A61P 9/00",
      industryImpacting: "Medical Science",
      topInventor: "Dorian Bevec",
      patentsPublished: 4092,
    },
  ];

  const labels = [
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
  ];
  const seriesData = [52, 62, 55, 55, 74, 71, 72, 94, 106, 112, 87, 15];

  const donutChartValues1 = [35, 42, 20, 21, 26];
  const donutChartLabels1 = [
    "Measuring for diagnostic purposes",
    "Using electronic means",
    "Involving amino acids, proteins or peptides",
    "Characterised by a protocol",
    "Bus structure",
  ];

  const donutChartValues2 = [30, 70];
  const donutChartLabels2 = ["Granted", "Pending"];

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  const handleSignupLinkClick = (e) => {
    e.preventDefault();
    handleToggle();
  };

  const additionalText = Object.values(cachedResults).map((item) => {
    return item.facets.assignee_country.length;
  });

  const additionalInfo = Object.values(cachedResults).map((item) => {
    return item.facets.assignee.slice(0, 2);
  });

  // Research Section Variables
  const [recentQuery, setRecentQuery] = useState(
    "Unmanned Supersonic jet aircraft"
  );
  const [summary, setSummary] = useState(
    "In 2010, Hypermach aerospace industries incorporated filed a patent on Supersonic aircraft with shockwave canceling aerodynamic configuration."
  );
  const [countryCount, setCountryCount] = useState(36);
  const [topAssignee, setTopAssignee] = useState("The Boeing Company");
  const [topYear, setTopYear] = useState(2019);
  const [topCountry, setTopCountry] = useState(36);
  const [mapData, setMapData] = useState([
    ["US", 531],
    ["JP", 70],
    ["CN", 69],
    ["DE", 57],
    ["FR", 41],
    ["KR", 25],
    ["GB", 24],
    ["CA", 19],
    ["TW", 10],
    ["AU", 6],
    ["RU", 5],
    ["SG", 5],
    ["IL", 5],
    ["CH", 4],
    ["ES", 4],
    ["SE", 4],
    ["BE", 3],
    ["BR", 2],
    ["NL", 2],
    ["CT", 2],
    ["FI", 2],
    ["PK", 1],
    ["VG", 1],
    ["NZ", 1],
    ["IT", 1],
    ["DK", 1],
    ["AT", 1],
    ["RO", 1],
    ["BY", 1],
    ["SA", 1],
    ["HR", 1],
    ["GR", 1],
    ["KY", 1],
    ["IE", 1],
    ["NO", 1],
    ["GD", 1],
  ]);

  const newsItems = [
    {
      time: "32 mins ago",
      headline: "Someone, somewhere, did something stupid",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, ab? Sequi dolorem aspernatur ad earum! Eius nulla tempora pariatur temporibus.",
      link: "#none",
      active: true,
      bgColor: "", // or 'tmln__item--bg-lght' or 'tmln__item--bg-dark'
    },
    {
      time: "36 mins ago",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, ab?",
      link: "#none",
      active: false,
      bgColor: "tmln__item--bg-lght",
    },
    {
      time: "58 mins ago",
      headline: "Someone, somewhere, did something great",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, ab? Sequi dolorem aspernatur ad earum! Eius nulla tempora pariatur temporibus.",
      link: "#none",
      active: false,
      bgColor: "tmln__item--bg-dark",
    },
    {
      time: "1 day ago",
      headline: "The world needs more LEGO",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, ab? Sequi dolorem aspernatur ad earum!",
      link: "#none",
      active: false,
      bgColor: "tmln__item--bg-lght",
    },
  ];

  // for customers
  const customers = [
    "The process is fast, efficient, cost effective and the results have been outstanding. Strategically we can do much more at a fraction of the cost and at incredible speed.",
    "I have been working with Incubig since the last two years, and they have never disappointed me. Reports are detailed and meet all the expectations. Their report on Covid19 in early 2020 was shared with the government and was well appreciated.",
    "Incubig's solution & platform is needed in South Korea. One of the top companies in KSGC 2019 competition, signed MoUs and joint venture agreements with leading corporations.",
  ];
  const customerName = [
    "Michael Wright, Founding Partner Intercepting Horizons",
    "Satish Mehta, Director Digital India Fund",
    "Chelsea Han, Manager NIPA",
  ];

  const statsData = [
    { number: 100, tag: "Users" },
    { number: 25, tag: "Projects" },
    { number: 50, tag: "Teams" },
  ];

  const emergingIndustriesURL =
    "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/recent";
  const recentSearchesURL =
    "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/recent";
  const keyPlayersURL =
    "https://09rypb92vg.execute-api.ap-south-1.amazonaws.com/alpha/api/v1/top-orgs";
  const emailURL =
    "https://client-communication.azurewebsites.net/api/client-communication";

  useEffect(() => {
    axios.get(recentSearchesURL).then((response) => {
      setRecentSearches(response.data);
    });
    axios.get(keyPlayersURL).then((response) => {
      setKeyPlayers(response.data.slice(0, 5));
    });
  }, []);

  const modifiedCustomerName = customerName.map((name) => (
    <span>
      {name.split(", ").map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index < name.split(", ").length - 1 && <br />}
        </React.Fragment>
      ))}
    </span>
  ));

  const sendEmail = () => {
    const recipients = {
      to: [{ email: "ketan@incubig.org", name: "Ketan Chandra" }],
    };
    const content = {
      subject: "(System Alert) DYR Full Access Requested",
      body: `${email} from ${orgName} has requested full access to the dashboard`,
      html: "",
    };
    axios.post(emailURL, { recipients: recipients, content: content });
  };

  const scrollToSection = () => {
    const targetSection = document.querySelector(".belowDontReactText");
    const isLoggedIn = localStorage.getItem("token") !== null;

    if (isLoggedIn) {
      window.location.href = "/for-you/report";
    } else {
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop - 50,
          behavior: "smooth",
        });
      }
    }
  };

  const navigate = useNavigate();

  const containerRef = useRef(null);
  const animationRef = useRef(null);

  // useEffect(() => {
  //   const container = containerRef.current;

  //   const startScroll = () => {
  //     animationRef.current = requestAnimationFrame(scroll);
  //   };

  //   const stopScroll = () => {
  //     cancelAnimationFrame(animationRef.current);
  //   };

  //   const scroll = () => {
  //     if (container.scrollLeft <= 0) {
  //       // Reset scroll position to the end
  //       container.scrollTo({ left: container.scrollWidth, behavior: "auto" });
  //     } else {
  //       container.scrollLeft -= 1.5;
  //     }

  //     animationRef.current = requestAnimationFrame(scroll);
  //   };

  //   startScroll();

  //   container.addEventListener("mouseenter", stopScroll);
  //   container.addEventListener("mouseleave", startScroll);

  //   // Clean up the event listeners when the component unmounts
  //   return () => {
  //     cancelAnimationFrame(animationRef.current);
  //     container.removeEventListener("mouseenter", stopScroll);
  //     container.removeEventListener("mouseleave", startScroll);
  //   };
  // }, []);

  const tableContainerRef = useRef(null);
  const tableAnimationRef = useRef(null);

  return (
    <div className="landingPage">
      <Tracking />

      <Row className="g-0">
        <PersonalizedHeader />
      </Row>
      <Row className="g-0">
        <SearchSection />
      </Row>

      {/* <Row className="g-0">
        <Col>
          <div className="queryHeading">
            Get <strong className="dyrTextLanding">Intelligence</strong> on{" "}
            <strong className="dyrTextLanding">
              Patents, technology & industry
            </strong>
          </div>
        </Col>
      </Row>

      <hr className="belowQueryHeading" /> */}

      {/* <Row className="g-0" style={{ marginLeft: "30px", marginRight: "30px", marginTop: "140px" }}>
        <div className="cardContainer" ref={containerRef}>
          <div className="cardWrapper">
            {emergingIndustries.map((item, index) => (
              <div className="cardEnclosed" key={index}>
                <div className="card">
                  <div className="cardBody">
                    <p className="cardText">
                      {additionalText[index]} countries working on
                    </p>
                    <h5 className="cardTitle">{item}</h5>
                    <p className="additionalInfo">
                      {toTitleCase(additionalInfo[index][0][0])}, and{" "}
                      {toTitleCase(additionalInfo[index][1][0])} active.
                    </p>
                    <button
                      onClick={() => {
                        moveToTechnologyPage(navigate,"overview",cachedResults[item]["cpc"]);
                      }}
                      key={index}
                      style={{ cursor: "pointer" }}
                      className="viewButton"
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Row> */}

      <Row className="g-0 companyRow">
        <Col xs="12" md="12">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background:
                "linear-gradient(to right, rgb(16 16 16), rgb(13 39 238))",
                height: "35%"
            }}
          >
            <div
              className="para&country"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <p
                className="para"
                style={{
                 

  marginTop: "40px",
  textAlign: "center",
  fontSize: "1.3rem",
  textAlign: "center",
  marginBottom: "0px",
  width: "100%",
  color: "white",
  marginLeft: "0px"

                }}
              >
                Transform Your Research: Global Insights at Your Fingertips,
                <br/>
                Accelerate Innovation, Optimize Decision-Making.
              </p>
            </div>

            <div className="box-container">
              <div className="boxNew">
                <div className="widget-block">
                  <FaBuilding className="icon2" />
                  &nbsp;
                  <div className="value" style={{color :"white"}}>200000</div>
                  <p className="landingBody3 landingStats">Players</p>
                </div>
              </div>
              <div className="boxNew">
                <div className="widget-block">
                  <FaMicrochip className="icon2" />
                  &nbsp;
                  <div className="value" style={{color :"white"}}>300000</div>
                  <p className="landingBody3 landingStats">Technologies</p>
                </div>
              </div>
              <div className="boxNew">
                <div className="widget-block">
                  <FaUserCog className="icon2" />
                  &nbsp;
                  <div className="value" style={{color :"white"}}>400000</div>
                  <p className="landingBody3 landingStats">Innovators</p>
                </div>
              </div>
            </div>
          </div>
          {/* </Col>
        <Col xs="12" md="6"> */}
          <div
            className="para&country"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <p
              className="para"
              style={{
                marginTop: "40px",
                fontSize: "1.3rem",
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: "0px",
                marginLeft: "10px",
                marginRight: "10px",
                width: "auto",
                background:
                  "linear-gradient(to right, rgb(2, 11, 38), rgb(0, 127, 255)) text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Never miss to discover & monitor Your competition
            </p>
          </div>

          <h2
            className="paraData"
            style={{
              width: "auto",
              textAlign: "center",
              marginTop: "30px",
              marginLeft: "0px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            Easily monitor your competition, track their research, identify
            potential technology collaborators, and future acquisition
            prospects.
          </h2>

          <div
            className="cpcTable"
            style={{ marginLeft: "0px", marginTop: "0px" }}
          >
            <div
              className="sectionHeading2"
              style={{
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              Click & explore company profile
            </div>
            <CompanyCarousel data={sampleData} isMoving={isMoving} heading="" />
          </div>
        </Col>
      </Row>

      {/* <hr
        className="thin-horizontal-line"
        style={{
          background:
            "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
          height: "2px",
          marginTop: "0px",
          marginBottom: "10px",
        }}
      /> */}

      <Row className="g-0">
        <Col xs="12" md="12">
          <div
            className="para-button"
            style={{ marginTop: "30px", marginLeft: "0px" }}
          >
            <p
              className="para"
              style={{
                marginTop: "10px",
                fontSize: "1.3rem",
                fontWeight: "bolder",
                marginBottom: "0px",
                marginLeft: "10px",
                marginRight: "10px",
                textAlign: "center",
                width: "auto",
                background:
                  "linear-gradient(to right, rgb(2, 11, 38), rgb(0, 127, 255)) text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Get Instant Global Technology Insights
            </p>
          </div>

          <p
            className="paraData"
            style={{
              width: "auto",
              marginTop: "30px",
              textAlign: "center",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            With just a few clicks, know your technological future, stay
            informed about global research.
          </p>
          <div
            className="sectionHeading2"
            style={{
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            Checkout emerging technologies
          </div>
          <VibrantTable
            data={sampleTechnologyData}
            showHeading={false}
            showDefaultHeading={false}
            heading=""
            showCount={true}
            count="Patent Count"
            showMoreButton={false}
          />
        </Col>
      </Row>

      {/* <Row className="g-0 companyRow">
        <Col xs="12" md="12">
          
        </Col>
      </Row> */}

      {/* <hr
        className="thin-horizontal-line"
        style={{
          background:
            "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
          height: "2px",
          marginTop: "0px",
          marginBottom: "10px",
        }}
      /> */}

      <hr
        className="thin-horizontal-line"
        style={{
          background:
            "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
          height: "2px",
          marginTop: "0px",
          marginBottom: "10px",
        }}
      />

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="12" style={{ color: "black" }}>
          <div className="para-button" style={{ marginTop: "30px" }}>
            <p
              className="para"
              style={{
                marginTop: "10px",
                fontSize: "1.3rem",
                fontWeight: "bolder",
                marginBottom: "0px",
                marginLeft: "10px",
                marginRight: "10px",
                textAlign: "center",
                width: "auto",
                background:
                  "linear-gradient(to right, rgb(2, 11, 38), rgb(0, 127, 255)) text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Navigate and utilize research data with ease
            </p>
          </div>
          {/* </Col>
        <Col xs={{ span: 12, order: 2 }} md="7"> */}
          <div
            className="landingRightSections"
            style={{ width: "auto", marginLeft: "10px", marginRight: "10px" }}
          >
            <p
              className="paraData"
              style={{
                width: "auto",
                textAlign: "center",
                marginTop: "30px",
                marginLeft: "0px",
              }}
            >
              In 2020, Arctop ltd filed a patent on Method and system for
              providing a brain computer interface.
            </p>

            {/* <PatentList keyApplications={keyApplications} /> */}
          </div>

          <div
            className="landingForstats"
            style={{
              marginTop: "30px",
            }}
          >
            <div className="map">
              <SimpleMap
                data={mapData}
                color="blue"
                backgroundColor="white"
                borderColor="grey"
                label=""
              />
            </div>
            <div
              className="sectionHeading2"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginTop: "30px",
              }}
            >
              Recent Innovations
            </div>
            {/* <PatentList keyApplications={keyApplications} />
             */}

            <div
              onMouseEnter={() => setIsMoving(false)}
              onMouseLeave={() => setIsMoving(true)}
            >
              <PatentcardInsight
                patents={samplePatent}
                showBackgroundColor={false}
                isMoving={isMoving}
              />
            </div>

            {/* <p
              style={{
                fontSize: "0.7rem",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "30px"
              }}
            >
              Research activity stats
            </p> */}
            {/* <div className="box-container">
              <div
                className="box"
                style={{
                  borderRight: "1px solid black",
                }}
              >
                <div className="widget-block">
                  <div className="value">36</div>
                  <p className="landingBody3 landingStats">Countries</p>
                </div>
              </div>
              <div
                className="box"
                style={{
                  borderRight: "1px solid black",
                }}
              >
                <div className="widget-block">
                  <div className="value">IBM</div>
                  <p className="landingBody3 landingStats">Top organization</p>
                </div>
              </div>
              <div className="box" >
                <div className="widget-block">
                  <div className="value">2238</div>
                  <p className="landingBody3 landingStats">
                    Number of inventors
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </Col>
      </Row>

      {/* <Row className="g-0" style={{
            background:
              "linear-gradient(to right, rgb(16 16 16), rgb(13 39 238))",
          }}>
        <Col
          xs="12"
          md="12"
          
        >
          <div
            className="para&country"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p
              className="para"
              style={{
                marginTop: "15px",
                fontSize: "1rem",
                textAlign: "center",
                color: "white",
                textAlign: "center",
              }}
            >
              DYR gives you the foresight to innovate and dominate the future of
              technology.
            </p>
          </div>
         
          <Timeline newsItems={newsItems} />
        </Col>
      </Row> */}

      {/* <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

      {/* <Row className="g-0">
        <Col
          xs="12"
          md="6"
          style={{
            background:
              "linear-gradient(to right, rgb(16 16 16), rgb(13 39 238))",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="para&country"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <p
                className="para"
                style={{
                  marginTop: "40px",
                  fontSize: "1.3rem",
                  fontWeight: "bolder",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Instantly access tech insights from billions of data points,
                delivering deep, research-driven intelligence.
              </p>
            </div>

            <div className="vertical-line-container">
               <div className="circles">
                 <span className="circle" />
                 <span className="circle" />
                 <span className="circle" />
               </div>
               <div className="line" />
             </div>
          </div>
        </Col>
      </Row> */}
      {/* 
      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

      {/* <Row className="g-0">
        <Col
          xs={{ span: 12, order: 1 }}
          md="5"
        >
          <div className="landingSection2">
            <div className="searchDiv">
              <div
                className="landingBrandname sectionBrandName"
                style={{ color: "black" }}
              >
                Get Instant
                <br />
                Global
                <br />
                Technology
                <br />
                Insights
              </div>
              <div className="searchBarWrapper">
                <input
                  className="searchBar"
                  placeholder="neurological analysis using images"
                  style={{ width: "80%" }}
                />
                <FaSearch
                  className="searchIcon"
                  style={{ top: "0px", color: "black" }}
                  
                />
              </div>
              <p className="landingPara brandNamePara">
                With just a few clicks, know your technological future, stay
                informed about global research, and make right R&D and business
                decisions, at right time.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="7">
          <div className="landingRightSections">
          

            <div className="landingTechnologyText">
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                Image Analysis
              </p>
              <p
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                20487 patents filed | 70 countries working | 5031 active
                organizations
              </p>
            </div>

            <div className="map">
              <SimpleMap
                data={mapData}
                color="blue"
                backgroundColor="#FAF1EC"
                borderColor="grey"
                label="distribution of organizations in different countries"
              />
            </div>
          </div>

          <div className="landingForstats">
            <div className="box-container">
              <div
                className="box"
                style={{
                  borderRight: "1px solid black",
                  background: "#FAF1EC",
                }}
              >
                <div className="widget-block">
                  <div className="value">Japan</div>
                  <p className="landingBody3 landingStats">Leading country</p>
                </div>
              </div>
              <div
                className="box"
                style={{
                  borderRight: "1px solid black",
                  background: "#FAF1EC",
                }}
              >
                <div className="widget-block">
                  <div className="value">Canon</div>
                  <p className="landingBody3 landingStats">Top organization</p>
                </div>
              </div>
              <div className="box" style={{ background: "#FAF1EC" }}>
                <div className="widget-block">
                  <div className="value">110</div>
                  <p className="landingBody3 landingStats">
                    Patents filed in 2020
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row> */}

      {/* <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

      {/* <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col
          xs={{ span: 12, order: 1 }}
          md="5"
          style={{ background: "#FAF1EC", color: "black" }}
        >
          <div className="landingSection3">
            <div className="searchDiv">
              <div
                className="landingBrandname sectionBrandName"
                style={{ color: "black" }}
              >
                Never miss to
                <br />
                discover &
                <br />
                monitor Your
                <br />
                competition
              </div>
              <div className="searchBarWrapper">
                <input
                  className="searchBar"
                  placeholder="network chip for neurological data"
                  style={{ width: "80%" }}
                />
                <FaSearch
                  className="searchIcon"
                  style={{ top: "0px", color: "black" }}
                 
                />
              </div>
              <p className="landingPara brandNamePara">
                Easily monitor your competition, track their research, identify
                potential technology collaborators, and future acquisition
                prospects.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="7">
          <div className="landingRightSections">
           

            <div className="landingTechnologyText">
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                Neuralink corp.
              </p>
              <p
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                Neuralink corp. is primarily researching on Implanted circuitry,
                followed by Information transfer, eg. neurological data.
              </p>
            </div>

            <div className="landingResearchAreaChart">
              <LineChart
                labels={labels}
                series={seriesData}
                axisLabel="Patents filed"
                title="YoY patent filing trends"
              />
            </div>

            <div className="landingDonuts">
              <DonutChart
                values={donutChartValues1}
                labels={donutChartLabels1}
                width="400px"
                height="400px"
              />

              <DonutChart
                values={donutChartValues2}
                labels={donutChartLabels2}
                width="250px"
                height="250px"
              />
            </div>
          </div>
        </Col>
      </Row> */}

      {/* <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

      {/* <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="7">
          <div className="landingReact">
            <span
              className="summaryTagText"
              style={{ fontSize: "1rem", color: "black" }}
            >
              Expert level Patent Research & business Solutions
            </span>
          </div>
          <p className="landingReactText">
            Our
            <b className="landingReactText" style={{ marginLeft: "auto" }}>
              {" "}
              global team
            </b>{" "}
            of
            <b className="landingReactText" style={{ marginLeft: "auto" }}>
              {" "}
              IP experts, Data scientists, & Strategist,
            </b>{" "}
            is delivering
            <b className="landingReactText" style={{ marginLeft: "auto" }}>
              {" "}
              precise, & high value reports
            </b>{" "}
            for our clients.
          </p>
          <div className="landingAccess" onClick={scrollToSection}>
            <div className="landingAccessText">Request Reports</div>
          </div>
        </Col>
        <Col xs={{ span: 12, order: 1 }} md="5">
          <div className="clientContainer">
            <div className="client1">
              <img
                src={process.env.PUBLIC_URL + "/incubig_michael.jfif"}
                className="rounded-circle"
                style={{ width: "25%", height: "25%" }}
                alt="My Image"
              />
              <div className="clientName">Michael Wright</div>
              <div className="clientTitle">
                Founding Partner, Intercepting Horizons
              </div>
              <div className="clientReview">
                The process is fast, efficient, cost effective and the results
                have been outstanding. Strategically we can do much more at a
                fraction of the cost and at incredible speed.
              </div>
            </div>
          </div>
        </Col>
      </Row> */}

      {/* <Row className="g-0">
        <Col xs="12">
          <p className="paraReport">Get Our Reports</p>
          <hr className="landingLinePortfolio" />
          <div className="list-container">
            <ul className="list">
              <p
                className="para"
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "1.3rem",
                  marginLeft: "0px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Own your invention
              </p>
              <li>Patent Novelty analysis.</li>
              <li>Claim recommendations.</li>
              <li>Increased valuation.</li>
              <div className="getReport" onClick={scrollToSection}>
                <div className="getReportText">Get Report</div>
              </div>
            </ul>
            <span className="separator"></span>

            <ul className="list">
              <p
                className="para"
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "1.3rem",
                  marginLeft: "0px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Freedom-to-operate
              </p>
              <li>Product infringement analysis.</li>
              <li>Workaround strategies.</li>
              <li>Product geo-positioning</li>
              <div className="getReport" onClick={scrollToSection}>
                <div className="getReportText">Get Report</div>
              </div>
            </ul>
            <span className="separator"></span>
            <ul className="list">
              <p
                className="para"
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "1.3rem",
                  marginLeft: "0px",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Know Your Technology
              </p>
              <li>Technology gaps analysis.</li>
              <li>Right R&D strategies.</li>
              <li>Technology advancement & growth.</li>
              <div className="getReport" onClick={scrollToSection}>
                <div className="getReportText">Get Report</div>
              </div>
            </ul>
          </div>
        </Col>
      </Row> */}

      {/* <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

      {/* <Row className="g-0" >
        <Col xs="12" md="12">
          <Partners isMoving={false} />
        </Col>
      </Row> */}

      {/* <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

{/* {!localStorage.getItem("token") && (
  <Row className="g-0">
    <Col
      xs="12"
      className="formLeft d-flex justify-content-center flex-column"
    >
      <div className={`landingSignupPage ${isLogin ? 'height-login' : 'height-signup'}`}>
        <div
          className="para&country"
          style={{ display: "flex", flexDirection: "row",justifyContent: "center" }}
        >
          <p
            className="para"
            style={{
              marginTop: "50px",
              fontSize: "1.3rem",
              marginBottom: "50px",
              marginLeft: "10px",
                marginRight: "10px",
                textAlign: "center",
                width: "auto",
              color: "white",
            }}
          >
            DYR gives you the foresight to innovate and dominate the future of technology.
          </p>
        </div>

         <SignupForm />
      </div>
    </Col>
  </Row>
)} */}

<hr className="hrLine" />

      {/* <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

      <Row className="g-0 partnerColumn">
        <Col xs="12" md="12">
          <Partners isMoving={true} />
        </Col>
      </Row>

      <Row className="g-0">
        <Footer />
      </Row>
    </div>
  );
};
