import React, { useState } from "react";
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import "./fullCpcTable.css";

const FullCompanyTable = ({ data, showDropdown = false, technologies = [], heading = "Company Overview" }) => {
    const [selectedTechnology, setSelectedTechnology] = useState("All");

    const handleTechnologyChange = (event) => {
      const selected = event.target.value;
      setSelectedTechnology(selected);
      // Implement filtering logic based on selectedTechnology
    };
  
    const filteredData = selectedTechnology === "All" ? data : data.filter(item => item.industryFocus.includes(selectedTechnology));

  return (
    <div className="table-container" style={{height: "auto"}}>
     {showDropdown && (
        <div className="dropdownTechnology2" style={{  }}>
          <label htmlFor="technologyDropdown" className="labelTechnology">
            Select Technology:
          </label>
          {technologies.length > 0 && (
            <select
              id="technologyDropdown"
              className="selectTechnology"
              value={selectedTechnology}
              onChange={handleTechnologyChange}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                cursor: "pointer",
                width: "100%",
                maxWidth: "300px",
                boxSizing: "border-box",
              }}
            >
              <option value="All">All</option>
              {technologies.map((tech, index) => (
                <option key={index} value={tech}>
                  {tech}
                </option>
              ))}
            </select>
          )}
          
        </div>
      )}
      <table className="vibrant-table">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Industry Impacting</th>
            <th>Highest Activity</th>
            <th>Top Inventor</th>
            <th>Patents Published</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.country}</td>
              <td>{item.industryFocus.join(", ")}</td>
              <td>{item.technologiesDeveloped}</td>
              <td>{item.topInventor}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="show-more-container">
        <button className="show-more-button">
          Show More
        </button>
      </div>
    </div>
  );
};

export default FullCompanyTable;


