import React, { useEffect, useRef } from 'react';
import './carousel.css';
import { useNavigate } from 'react-router-dom';
import { moveToCompanyPage } from '../../actions/helper';

export const CompanyCarousel = (props) => {
  const navigate = useNavigate();
  const data = props.data;
  const isMoving = props.isMoving;
  const containerRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const startScroll = () => {
      animationRef.current = requestAnimationFrame(scroll);
    };

    const stopScroll = () => {
      cancelAnimationFrame(animationRef.current);
    };

    const scroll = () => {
      if (container.scrollLeft <= 0) {
        container.scrollTo({ left: container.scrollWidth, behavior: 'auto' });
      } else {
        container.scrollLeft -= 1.5;
      }

      animationRef.current = requestAnimationFrame(scroll);
    };

    if (isMoving) {
      startScroll();
      container.addEventListener('mouseenter', stopScroll);
      container.addEventListener('mouseleave', startScroll);
    } else {
      stopScroll();
      container.removeEventListener('mouseenter', stopScroll);
      container.removeEventListener('mouseleave', startScroll);
    }

    return () => {
      cancelAnimationFrame(animationRef.current);
      container.removeEventListener('mouseenter', stopScroll);
      container.removeEventListener('mouseleave', startScroll);
    };
  }, [isMoving]);

  const clickHandler = (item) => {
    moveToCompanyPage(navigate, item.companyName, "overview");
  };

  return (
    <div className="carousel-container">
      {props.heading && (
        <div className="heading" style={{fontSize :"0.7rem"}}>
          <span>{props.heading}</span>
        </div>
      )}
      <div className="scrollable-container" ref={containerRef}>
        <div className="columns-container">
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <div
                key={index}
                className="carouselCard"
                onClick={()=>{moveToCompanyPage(navigate,item.companyName,"overview")}}
              >
                <div className="companyName" style={{
                  background:
                    "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223)) text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}>{item.companyName}</div>
                <div className="companyCountry">{item.country}</div>
                <div>
                  <hr
                    style={{
                      width: "30%",
                      background:
                        "linear-gradient(to right, rgb(12 177 232), rgb(13 39 238))",
                      height: "5px",
                      border: "none",
                    }}
                  />
                </div>
                <div className="industryFocus">
                  <h4>Industry Focus</h4>
                  <p>{item.industry1}</p>
                  <p>{item.industry2}</p>
                </div>
                <div className="technologiesDeveloped">
                  <h4>Technologies Developed</h4>
                  <p>{item.technologiesCount}</p>
                </div>
                <div className="topInventor">
                  <h4>Top Inventor</h4>
                  <p>{item.topInventor}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

