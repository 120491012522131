import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
} from "reactstrap";
import { PersonalizedHeader } from '../../components/personalizedHeader/personalizedHeader';
import AdminPageHeader from '../../sections/adminPageHeader/adminPageHeader';
import { AdminPageNotifications } from '../../sections/adminPageNotifications/adminPageNotifications';
import { AdminPageUpload } from '../../sections/adminPageUpload/adminPageUpload';
import { AdminPageTweet } from '../../sections/adminPageTweet/adminPageTweet';
import axios from 'axios';

export const AdminPage = (props) => {
  const [selectedSection,setSelectedSection] = useState(window.location.pathname.split("/").at(-1))
  const [authenticated, setAuthenticated] = useState(false);

  const authenticationAPI =
    "https://api.incubig.org/authorization/authorize";

  useEffect(()=>{
    let authToken = localStorage.getItem('token')
    if (!authToken){
      setAuthenticated(false)
    }
    else {
      // Verify the auth token
      axios.get(authenticationAPI,{headers:{"auth-token":authToken}}).then((response) => {
        if (response.status === 200) {
          // Auth token is valid, user is authenticated
          setAuthenticated(true);
        }
        else {
          // Auth token is not valid, user not authenticated
          setAuthenticated(false);
        }
      })
    }
    setSelectedSection(window.location.pathname.split("/").at(-1))
  },[window.location.pathname])

  return (
    <div>
      <Row className="g-0">
        <Col xs="12">
            <PersonalizedHeader/>
        </Col>
      </Row>

      <AdminPageHeader/>

      <Row className="g-0">
        <Col xs="12">
          <hr className="thin-horizontal-line" />
          <span
            className="headingSearch"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.75rem",
              backgroundColor: "#020b26",
              color: "white",
              height: "40px",
            }}
          >
            Admin Page
            
          </span>
        </Col>
      </Row>
      <hr
        className="thin-horizontal-line" 
      />
      {(authenticated)
      ?
      <div>
        {(selectedSection==="notifications")
          ?
          <AdminPageNotifications/>
          :
          <div></div>
        }

        {(selectedSection==="upload-indian-applications")
          ?
          <AdminPageUpload/>
          :
          <div></div>
        }

        {(selectedSection==="tweet")
          ?
          <AdminPageTweet/>
          :
          <div></div>
        }
      </div>
      :
      <div>
        <p className="noLoginText">Please log in to view this page.</p>
      </div>
      }
    </div>
  )
}