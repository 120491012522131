import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import LineChart from "../../components/Charts/lineChart";
import PieChart from "../../components/Charts/pieChart";
import { Tracking } from "../../components/tracking/tracking";

const TechnologyTrends = (props) => {
  const technologyOverview = props.technologyOverview;
  const grantRate = props.grantRate;
  const topYear = props.topYear;
  const topYearCount = props.topYearCount;


  const [keyPlayers, setKeyPlayers] = useState(
    props.keyPlayers ? props.keyPlayers : []
  );

  return (
    <div>
      <Tracking  title={(props.selectedTechnology)?props.selectedTechnology.definition:"Technology analysis by Incubig"} description={"Patents in "+((props.selectedTechnology)?props.selectedTechnology.definition:"Technology analysis by Incubig")} keywords={((props.selectedTechnology)?props.selectedTechnology.definition.split(" ").join(","):"Technology analysis by Incubig")}/>
      <Row className="g-0">
        <Col xs="12" md="6"
          className="borderRight"
        >
          <p className="keyPoints">Key Trends</p>
          {(topYear)?
          <p className="para" style={{ fontWeight: "bold", marginTop: "20px" }}>
            Recently in the year {topYear}, {topYearCount}{" "}
            patents were filed.
          </p>
          :
          <p className="para" style={{ fontWeight: "bold", marginTop: "20px" }}>
            <Skeleton/>
          </p>
          }
          {grantRate && (
            <div className="trendText" style={{ marginTop: "10px" }}>
              <ul className="keyPointsUL">
                <li style={{ marginBottom: "10px" }}>
                  {typeof grantRate !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {grantRate}%
                      </strong>{" "}
                      of the patent applications filed are granted.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof topYear !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {topYear}
                      </strong>{" "}
                      observed a high research activity.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof topYearCount !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {topYearCount}
                      </strong>{" "}
                      patents were filed in{" "}
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {topYear}
                      </strong>{" "}
                      year.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
              </ul>
            </div>
          )}
          {typeof grantRate != "undefined" ? (
            <div className="chartWithText">
              <div
                className="trendGraph"
                style={{justifyContent: "center", marginBottom: "50px", marginTop: "15%"}}
              >
                <PieChart
                  labels={["Granted", "Pending"]}
                  values={[parseInt(grantRate), 100 - parseInt(grantRate)]}
                  label="Patents status"
                  width="400px"
                  height="400px"
                />
              </div>
            </div>
          ) : (
            <p className="para" style={{ marginTop: "10px" }}>
              <Skeleton />
            </p>
          )}

        </Col>
        <Col className="xs=12" md="6">
        <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px"}}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              Trends
            </span>
          </div>
          {technologyOverview ? (
            <div className="researchAreaChart">
              <LineChart
                labels={technologyOverview.application_trends.map((item) => {
                  return item.year;
                })}
                series={technologyOverview.application_trends.map((item) => {
                  return item.count;
                })}
                name={"Patents filed"}
                title={"YoY patent filing trends"}
              />
            </div>
          ) : (
            <p className="para" style={{ marginTop: "10px" }}>
              <Skeleton count={2} />
            </p>
          )}
          {technologyOverview ? (
            <div className="researchAreaChart" style={{ marginBottom: "30px"}}>
              <LineChart
                labels={technologyOverview.publication_trends.map((item) => {
                  return item.year;
                })}
                series={technologyOverview.publication_trends.map((item) => {
                  return item.count;
                })}
                name={"Patents published"}
                title={"YoY patent publishing trends"}
                axisLabel={"Patents Published"}
              />
            </div>
          ) : (
            <p className="para" style={{ marginTop: "10px" }}>
              <Skeleton count={2} />
            </p>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TechnologyTrends;
