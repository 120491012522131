import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Row, Col, Button } from "reactstrap";
import "./reports.css";
import {
  base64Decode,
  decodePatentNumber,
  sanitizeText,
  toTitleCase,
  truncateText,
} from "../../actions/helper";
import Skeleton from "react-loading-skeleton";
import { CpcTable } from "../../components/Tables/cpcTable";
import PatentList from "../../components/patentsList/patentList";
import { jwtDecode } from "jwt-decode";
import moment from "moment/moment";
import PatentAbstract from "../../sections/patentAbstract/patentAbstract";
import { ReportReference } from "../../components/reportReferenceTable/reportReferenceTable";
import { ReportCPC } from "../../components/reportTechnologyTable/reportTechnologyTable";
import { MdShare } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";
import { FaSpinner } from "react-icons/fa";
import { set } from "react-ga";
import ForYouHeader from "../../sections/forYouHeader/forYouHeader";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import { RWebShare } from "react-web-share";
import GoToSignin from "../../components/goToSignin/goToSignin";
import { usePDF } from "react-to-pdf";

const Reports = (props) => {
  let params = useParams();
  let location = useLocation();

  // const { title, features, email } = props.location.state;

  const id = params.id;

  // IDF values
  const [title, setTitle] = useState(
    JSON.parse(localStorage.getItem(id))
      ? toTitleCase(JSON.parse(localStorage.getItem(id)).title)
      : undefined
  );
  const [novelElement, setNovelElement] = useState(
    JSON.parse(localStorage.getItem(id))
      ? JSON.parse(localStorage.getItem(id)).novel_element
      : "Sample report novel element"
  );
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem(id))
      ? JSON.parse(localStorage.getItem(id)).filter_value
      : ["Communication Technique", "Computational Technology"]
  );
  const [features, setFeatures] = useState(
    JSON.parse(localStorage.getItem(id))
      ? JSON.parse(localStorage.getItem(id)).features
      : [
          "Sample report feature 1",
          "Sample report feature 2",
          "Sample report feature 3",
        ]
  );
  const [description, setDescription] = useState(
    JSON.parse(localStorage.getItem(id))
      ? JSON.parse(localStorage.getItem(id)).description
      : "Sample report description"
  );

  const userName = localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token")).user.name
    : "Ketan Chandra";
  const userInfo =
    localStorage.getItem("token") &&
    jwtDecode(localStorage.getItem("token")).user.employer != "self"
      ? jwtDecode(localStorage.getItem("token")).user.employer
      : "Incubig, India";
  const currentDate = moment(new Date()).format("YYYY-MM-DD");

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [overview, setOverview] = useState();
  const [technologies, setTechnologies] = useState();
  const [industries, setIndustries] = useState([]);
  const [featureSummary, setFeatureSummary] = useState();
  const [oneOtwoCount, setOneOtwoCount] = useState();
  const [oneOtwoReferences, setOneOtwoReferences] = useState([]);
  const [oneOthreeCount, setOneOthreeCount] = useState();
  const [oneOthreeReferences, setOneOthreeReferences] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [claimRecommendations, setClaimRecommendations] = useState();
  const [downloadingFlag, setDownloadingFlag] = useState(false);

  const score = props.score || "4";
  const inventor = props.inventor || "Peter KITTEL";
  const industry = props.industry || "Machines & Engines";
  const applicationDate = props.applicationDate || " Feb 28th 2019";
  const abstract =
    props.abstract ||
    "A vertical axis turbine includes a rotatable hub assembly that is configured to be connected to an energy sink and rotatable about an axis of rotation. At least two blades are mounted on the hub assembly, each blade including a leading edge and a trailing edge, the blades being oriented so that the respective leading edges face in a common rotational direction. Each blade further includes a straight section that is substantially parallel to the axis of rotation and two helical sections, the straight section being interposed between the helical sections, and the helical sections extending at least partially around the axis of rotation.";

  const [sampleApplications, setSampleApplications] = useState([]);
  const reportSummary = props.reportSummary || [
    "The novelty of the invention will be questioned by the examiner using a Single patent/non-patent reference and/or using an obviousness rejection (by providing a combination of references) as stated under India Patent Act sections 2(1)(I) and 2(1)(ja).",
    "However, the identified references indicate it would be difficult for the examiner to make a strong case for rejection under section sections 2(1) (I) and 2(1)(ja) of the Indian Patents Act.",
    "More than 5000, patent applications have been filed under machines using liquid flow and more than 65000 patent applications under the use of hydro energy, indicating a high patent density that points towards the disclosed invention having a very good industrial application. Also, there are around 335 patent applications under engines and pumps having elliptical or ellipsoidal shape, indicating a very low patent density, thereby decreasing the probability of rejection of the patent application for the disclosed invention.",
  ];
  const reportSummaryText = props.reportSummaryText || [
    "The novelty and valuation of the patent invention under study can be increased by writing the claims describing in detail how the aforementioned turbine works to achieve an increase in the performance parameters such as coefficient of power (Cp), coefficient of torque (Ct), and tip speed ratio (TSR).",
  ];
  const technologyTitle = props.technology || "Rotors";
  const technologyPatents = props.technologyPatents || "170";
  const technologyCountries = props.technologyCountries || "28";
  const technologyOrganizations = props.technologyOrganizations || "138";
  const keyGeographies = props.keyGeographies || [
    ["United States", 100],
    ["Japan", 80],
    ["United Kingdom", 70],
    ["France", 60],
    ["Germany", 50],
  ];
  const claims = props.claims;
  const claimsText = props.claimsText
    ? props.claimsText.split("CLM-")
    : undefined;

  const getIdfAPI = "https://api.incubig.org/solutions/idf/";
  const extractIndustriesURL =
    "https://api.incubig.org/solutions/generate-facets";
  const cpcDefinitionAPI = "https://api.incubig.org/analytics/cpc-definition";
  const summarizationAPI = "https://api.incubig.org/solutions/summarize";
  const oneOtwoAPI = "https://api.incubig.org/solutions/one-o-two";
  const oneOThreeAPI = "https://api.incubig.org/solutions/one-o-three";
  const recommendationAPI =
    "https://api.incubig.org/solutions/recommended-claims";

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      axios
        .get(getIdfAPI + id, { headers: { "auth-token": token } })
        .then((response) => {
          // console.log(res.data.data)
          setTitle(toTitleCase(response.data.data.title));
          setNovelElement(toTitleCase(response.data.data.novel_element));
          setFilters(response.data.data.filters);
          setFeatures(response.data.data.features);
          setDescription(toTitleCase(response.data.data.description));

          // Generating report
          axios
  .get(extractIndustriesURL, {
    params: { query: response.data.data.title, field: "industry" },
  })
  .then((res) => {
    if (res.data.data) {
      setIndustries(res.data.data.map((item) => item[0]));
    } else {
      // Handle the case when res.data.data is null
      console.error("Data is null");
    }
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
    // Handle any errors that occurred during the request
  });

          axios
            .get(extractIndustriesURL, {
              params: { query: response.data.data.title, field: "main_cpc" },
            })
            .then((res) => {
              // console.log(res.data);
              if (res.data.data) {
                axios
                  .post(
                    cpcDefinitionAPI,
                    res.data.data
                      .map((item) => {
                        return item[0];
                      })
                      .slice(0, 5)
                  )
                  .then((resp) => {
                    let tempFields = [];
                    resp.data.forEach((item, index) => {
                      tempFields.push({
                        cpc: item.cpc,
                        definition: item.definition,
                        count: res.data.data[index][1],
                      });
                    });
                    setTechnologies(tempFields);
                    axios
                      .get(recommendationAPI, {
                        params: {
                          query: response.data.data.title,
                          filter: "main_cpc eq '" + tempFields[0].cpc + "'",
                        },
                      })
                      .then((resp) => {
                        if (resp.data.data) {
                          setClaimRecommendations(resp.data.data.slice(0, 5));
                        }
                      });
                  });
              }
            });
          axios.post(summarizationAPI, { features: features }).then((res) => {
            setFeatureSummary(res.data.data);
          });
          let filter = null;
          if (filters && filters.length > 0) {
            filter = "( industry eq '" + filters[0] + "' )";
          }

          axios
            .post(
              oneOtwoAPI,
              {
                email: "",
                title: response.data.data.title,
                novel_element: response.data.data.novel_element,
                description: response.data.data.description,
                features: response.data.data.features,
              },
              { params: { filter: filter } }
            )
            .then((resp) => {
              // console.log(res.data.data.slice(0,5))
              setOneOtwoCount(resp.data.data["@odata.count"]);
              setOneOtwoReferences(resp.data.data.value.slice(0, 5));
            });
          axios
            .post(
              oneOThreeAPI,
              {
                email: "",
                title: response.data.data.title,
                novel_element: response.data.data.novel_element,
                description: response.data.data.description,
                features: response.data.data.features,
              },
              { params: { filter: filter } }
            )
            .then((resp) => {
              // console.log(res.data)
              if (resp.data.data) {
                setOneOthreeCount(resp.data.data["@odata.count"]);
                setOneOthreeReferences(resp.data.data.value);
                setOverview(
                  resp.data.data.value[0]["@search.captions"][0]["text"]
                );
              }
            });
        });
    }
  }, []);

  const handleShare = () => {
    console.log("Share button is clicked");
  };

  const { toPDF, targetRef } = usePDF({
    method:"save",
    filename: "Novelty_report_by_incubig_ai.pdf",
  });

  const handleToPDF = async () => {
    try {
      setDownloadingFlag(true);
      await toPDF();
      
      setDownloadingFlag(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadingFlag(false);
    }
  };

  const navigate = useNavigate();

  const isLandingPage = location.pathname === "/";

  const redirectToHome = () => {
    navigate("/");
  };

  const handleSuggestionClick = (suggestion) => {
    // Toggle the selection
    setSelectedSuggestion((prevSelected) =>
      prevSelected === suggestion ? null : suggestion
    );
  };

  let scoreText = "";
  if (score <= 3) {
    scoreText = "Low";
  } else if (score <= 7) {
    scoreText = "Medium";
  } else {
    scoreText = "High";
  }

  return (
    <div>
      {isLoggedIn ? (
        <div className="reportContainer" ref={targetRef}>
          <Row className="g-0">
            <Col md="6"></Col>
            <Col md="6"></Col>
          </Row>
          {/* Intro Page */}
          <Row className="g-0">
            <Col xs={{ span: 12, order: 1 }} md="5" style={{ color: "black" }}>
              <div className="rightSection1">
                <a
                  className={`logoText ${
                    isLandingPage ||
                    window.location.pathname.split("/").at(-1) === "sign-up"
                      ? "whiteText"
                      : ""
                  }`}
                  onClick={() => {
                    redirectToHome();
                  }}
                  style={{
                    cursor: "pointer",
                    marginLeft: "25px",
                    color: "white",
                  }}
                >
                  Incubig AI
                </a>
                <div className="rightText">
                  <div className="inventionText">
                    Own
                    <br />
                    Your
                    <br />
                    Invention
                  </div>
                  <p className="inventionPara">
                    Making you file valid patents.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="7">
              <div className="leftSection">
                <div className="leftText">
                  <p className="titlePatent">
                    Novelty analysis for the Invention :{" "}
                    {title ? title : <Skeleton />}
                  </p>
                  <p className="name">For {userName}</p>
                  <p className="userInfo">{userInfo}</p>
                </div>
                <hr className="belowUserInfo" />
                <div className="bottomRight">
                  Report publication date :{" "}
                  <b style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                    {currentDate}
                  </b>
                </div>
              </div>
            </Col>
          </Row>
          <div className="pageStrip" style={{ marginTop: "0px" }}>
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>

          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Page 1 */}
          <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              <div className="forWhom">
                {/* Own your invention for {userName} - {userInfo} */}
              </div>
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>

            <Col xs={{ span: 12, order: 1 }} md="6">
              <div>
                <div className="aboutText">About Invention</div>
                <div>
                  {title ? (
                    <div className="title">{sanitizeText(title)}</div>
                  ) : (
                    <div>{props.flag ? <Skeleton /> : <p></p>}</div>
                  )}
                </div>
                <hr className="belowAbout" />
              </div>
              <div className="paraHeading">Overview of Invention</div>
              <div className="paraInvention">
                {overview ? toTitleCase(overview) : <Skeleton count={5} />}
              </div>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6">
              <div className="industryAreas">
                <div className="industryAreaText">
                  Invention's Industrial areas
                </div>
                {industries.length > 0 ? (
                  <ul
                    className="suggestions"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  >
                    {industries.slice(0, 5).map((suggestion, index) => (
                      <li
                        className={
                          suggestion === selectedSuggestion ? "selected" : ""
                        }
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {truncateText(suggestion, 50)}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul className="suggestions">
                    <Skeleton />
                  </ul>
                )}
              </div>
              {/* <div className="forImages"></div> */}
              <div className="inventionAreas">
                <CpcTable
                  data={technologies}
                  showHeading={true}
                  showCount={true}
                  heading="Invention's technological areas"
                />
              </div>
            </Col>
          </Row>
          <div className="pageStrip">
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>

          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Page 2 */}
          {/* <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              <div className="forWhom">
              </div>
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>

            <Col xs={{ span: 12, order: 1 }} md="6">
              <div className="aboutText">Invention Features</div>
              <div>
                {title ? (
                  <div className="title">{sanitizeText(title)}</div>
                ) : (
                  <div>{props.flag ? <Skeleton /> : <p></p>}</div>
                )}
              </div>
              <hr className="belowAbout" />
              <div className="paraHeading">Key features of Invention :</div>
              <div className="paraInvention">{featureSummary} :</div>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6">
              <div className="paraHeading" style={{ marginTop: "22%" }}>
                Feature List:
              </div>
              <ul className="featureList">
                {features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="pageStrip">
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div> */}

          {/* <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          /> */}

          {/* Page 3 */}
          <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              <div className="forWhom">
                {/* Own your invention for {userName} - {userInfo} */}
              </div>
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>
            <Col xs={{ span: 12, order: 1 }} md="6">
              <div className="aboutText">Novel & Essential Features</div>
              <div>
                {title ? (
                  <div className="title">{sanitizeText(title)}</div>
                ) : (
                  <div>{props.flag ? <Skeleton /> : <p></p>}</div>
                )}
              </div>
              <hr className="belowAbout" />
              <div className="novelWrap">
                <div className="novelPara">Novel Feature</div>
                <div className="novelElement">{novelElement}</div>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6">
              <ul className="featureList" style={{ marginTop: "22%" }}>
                <span style={{ marginLeft: "10%", fontWeight: "bold" }}>
                  Essential Features
                </span>
                {features
                  ? features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))
                  : features.map((feature, index) => (
                      <li key={index}>
                        <Skeleton />
                      </li>
                    ))}
              </ul>
            </Col>
          </Row>
          <div className="pageStrip">
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>

          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Novelty Intro */}
          <Row className="g-0">
            <Col xs={{ span: 12, order: 1 }} md="6">
              <div>
                <div className="noveltyTitle">Novelty Analysis</div>
              </div>
              <div className="noveltyText">Novelty Assesment</div>
              <hr className="belowNovelty" />
              <div className="paraInvention">
                The next section provides novelty assessment analysis of the
                invention.
              </div>
              <ul className="novelList">
                <li>Novelty analysis overview of 102 & 103 patents.</li>
                <li>Top 5 102 patent references.</li>
                <li>List of 20 103 patent references.</li>
                <li>Top Technologies.</li>
              </ul>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6">
              <div className="leftSection2"></div>
            </Col>
          </Row>

          <div className="pageStrip" style={{ marginTop: "0px" }}>
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>

          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* List of 102 reference page  */}
          <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              <div className="forWhom">
                {/* Own your invention for {userName} - {userInfo} */}
              </div>
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>
            <div className="referTitle" style={{ marginBottom: "0px" }}>
              List of 102 patent references
            </div>
            <div>
              <ReportReference data={oneOtwoReferences} />
            </div>
          </Row>
          <div className="pageStrip">
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>
          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Page 5 - 1 */}
          <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              <div className="forWhom">
                {/* Own your invention for {userName} - {userInfo} */}
              </div>
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>
            <div className="referTitle" style={{ marginBottom: "0px" }}>
              102 patent references
              <h6
                className="referHead"
                style={{
                  fontWeight: "normal",
                  marginLeft: "0px",
                  marginTop: "10px",
                }}
              >
                Relevant patent references
              </h6>
            </div>
            <div className="pageStrip2">
              <div
                className="additional"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              ></div>
              <div style={{ fontSize: "0.8rem" }}>102 References</div>
              <div className="endStrip" style={{ fontSize: "0.8rem" }}></div>
            </div>
            {oneOtwoReferences.map((reference, index) => (
              <div key={index}>
                <h2 className="referHead">Reference {index + 1}</h2>
                <div>
                  {title ? (
                    <div className="referTitle" style={{ marginTop: "20px" }}>
                      {sanitizeText(reference.title)}
                    </div>
                  ) : (
                    <div className="referTitle">
                      {props.flag ? <Skeleton /> : <p></p>}
                    </div>
                  )}
                </div>
                <hr className="belowAbout" style={{ marginLeft: "5%" }} />
                <div className="reportAbstractContainer">
                  <PatentAbstract
                    flag={true}
                    publicationNumber={base64Decode(
                      reference.publication_number.substring(
                        0,
                        reference.publication_number.length - 1
                      ) + "=".repeat(reference.publication_number.at(-1))
                    )}
                    title={reference.title}
                    status={"pending"}
                    applicationDate={reference.application_date}
                    publicationDate={reference.publication_date}
                    assignees={reference.assignee}
                    inventors={reference.inventor}
                    abstract={reference.abstract_text}
                    industry={reference.industry}
                  />
                </div>
                {/* Page 5 - 2 */}
                <Row className="g-0">
                  <hr className="aboveExcerpt" />
                  <Col xs={{ span: 12, order: 1 }} md="6">
                    <div
                      style={{
                        lineHeight: "1.5",
                        marginTop: "10px",
                        maxHeight: "520px",
                        overflowY: "auto",
                        marginBottom: "60px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          marginLeft: "10%",
                          position: "sticky",
                          zIndex: "1",
                          top: "0px",
                          backgroundColor: "white",
                        }}
                      >
                        Claims :
                      </div>

                      <div
                        id="claimsText"
                        style={{
                          marginLeft: "10%",
                          width: "90%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {reference["claims_text"] ? (
                          <div style={{ fontSize: "0.8rem" }}>
                            {reference["claims_text"]
                              .split("CLM-")
                              .map((item, index) => (
                                <div key={index}>
                                  <p style={{ fontSize: "0.8rem" }}>
                                    {item != "" && "CML-" + item}
                                  </p>
                                  {/* <br /> */}
                                </div>
                              ))}
                          </div>
                        ) : (
                          <Skeleton count={10} />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 12, order: 2 }} md="6">
                    <section
                      className="basic-info"
                      style={{
                        lineHeight: "1.5",
                        marginTop: "10px",
                        maxHeight: "520px",
                        overflowY: "auto",
                        marginBottom: "60px",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          marginLeft: "10%",
                          position: "sticky",
                          zIndex: "1",
                          top: "0px",
                          backgroundColor: "white",
                        }}
                      >
                        Relevant Excerpts:
                      </h2>
                      {reference["@search.captions"][0]["text"] ? (
                        <div
                          style={{
                            marginLeft: "10%",
                            width: "90%",
                            fontSize: "0.8rem",
                            marginTop: "5px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: toTitleCase(
                              reference["@search.captions"][0]["text"]
                            ),
                          }}
                        />
                      ) : (
                        <div style={{ marginLeft: "10%", width: "90%" }}>
                          <Skeleton count={10} />
                        </div>
                      )}
                    </section>
                  </Col>
                </Row>
                {/* <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginLeft: "5%",
                zIndex: "1",
                top: "0px",
                backgroundColor: "white",
              }}
            >
              Reference Analysis
            </div>
            <hr className="belowAbout" style={{ width: "15%", marginLeft: "5%" }} />
            <div className="referContainer">
              <div className="referAnalysis">
                <div style={{ fontWeight: "bold" }}>Strength Score</div>
                <p>{(reference['@search.score']>100)?"High":(reference['@search.score']>50)?"Medium":"Low"}</p>
              </div>
              <div className="referAnalysis">
                <div style={{ fontWeight: "bold" }}>Filing Timing</div>
                <p>High</p>
              </div>
              <div className="referAnalysis">
                <div style={{ fontWeight: "bold" }}>Assignee Status</div>
                <p>Large</p>
              </div>
            </div> */}
                <div className="pageStrip">
                  <div
                    className="additional"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    Developed by Incubig AI
                  </div>
                  <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
                  <div
                    className="endStrip"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    Own Your Invention
                  </div>
                </div>

                <hr
                  className="thin-horizontal-line"
                  style={{
                    backgroundColor: "rgb(199, 198, 198)",
                    color: "rgb(199, 198, 198)",
                    marginTop: "10px",
                    marginBottom: "0px",
                  }}
                />
              </div>
            ))}
          </Row>

          {/* 103 Reference Intro */}
          <Row className="g-0">
            <Col xs={{ span: 12, order: 1 }} md="6">
              <div>
                <div className="noveltyTitle">Prior art references</div>
              </div>
              <div className="noveltyText">103 patent references</div>
              <hr className="belowNovelty" />
              <div className="paraInvention">
                The next section of the report provides Prior art 103 patent
                references, possibly disclosing the essential feature of the
                invention.
              </div>
              <ul className="novelList">
                <li>List of top Prior art references.</li>
              </ul>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6">
              <div className="leftSection2"></div>
            </Col>
          </Row>
          <div className="pageStrip" style={{ marginTop: "0px" }}>
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>
          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Page 7 */}
          <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              <div className="forWhom">
                {/* Own your invention for {userName} - {userInfo} */}
              </div>
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>
            <div className="referTitle" style={{ marginBottom: "0px" }}>
              103 patent references
              <h6
                className="referHead"
                style={{
                  fontWeight: "normal",
                  marginLeft: "0px",
                  marginTop: "10px",
                }}
              >
                Relevant patent references
              </h6>
            </div>
            <div className="pageStrip2">
              <div
                className="additional"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              ></div>
              <div style={{ fontSize: "0.8rem" }}>103 Reference</div>
              <div className="endStrip" style={{ fontSize: "0.8rem" }}></div>
            </div>
            <Col xs="12" md="12">
              <div className="referPatentPara">
                Other patent references than can be included in the prior art
                references
              </div>

              <div className="referScrollableContainer">
                {oneOthreeReferences ? (
                  oneOthreeReferences.length > 0 && (
                    <div className="referColumnsContainer">
                      <div className="referPatentList">
                        <PatentList
                          keyApplications={oneOthreeReferences.slice(0, 20)}
                        />
                      </div>
                    </div>
                  )
                ) : (
                  <div className="referColumnsContainer">
                    <div className="referPatentList">
                      <Skeleton />
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <hr className="aboveExcerpt below103References" />

          <div className="pageStrip">
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>

          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Know Your Technologies */}
          <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              <div className="forWhom">
                {/* Own your invention for {userName} - {userInfo} */}
              </div>
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>
            <div className="referTitle" style={{ marginBottom: "0px" }}>
              Know Your Technology
              <h6
                className="referHead"
                style={{
                  fontWeight: "normal",
                  marginLeft: "0px",
                  marginTop: "10px",
                }}
              >
                Relevant technologies for Invention
              </h6>
            </div>
            <div>
              <ReportCPC technologies={technologies} />
            </div>
          </Row>
          <div className="pageStrip" style={{ marginTop: "0px" }}>
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>
          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Claim recommendation intro */}
          <Row className="g-0">
            <Col xs={{ span: 12, order: 1 }} md="6">
              <div>
                <div className="noveltyTitle">Invention enhnacement</div>
              </div>
              <div className="noveltyText">Claim Recommendations</div>
              <hr className="belowNovelty" />
              <div className="paraInvention">
                The next section of the report provides most recent claims,
                invention features similar to the invention{" "}
                <strong className="paraInvention" style={{ marginLeft: "0px" }}>
                  {title}
                </strong>
                .
              </div>
              <ul className="novelList">
                <li>
                  Recommended claims can be added in the invention, to increase
                  the validity of the invention.
                </li>
              </ul>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6">
              <div className="leftSection2"></div>
            </Col>
          </Row>
          <div className="pageStrip" style={{ marginTop: "0px" }}>
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>

          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Page 8 */}
          <Row className="g-0">
            <div className="pageNavbar">
              <div className="additional"></div>
              {/* <div className="forWhom">
              Own your invention for {userName} - {userInfo}
            </div> */}
              <a
                className="logoReport"
                onClick={() => {
                  redirectToHome();
                }}
              >
                Incubig AI
              </a>
            </div>
            {/* Earlier it was patent title */}
            <div className="referTitle">
              Recent Claims Related to Your Invention
            </div>

            {/* {applicationDate && (
            <h2 className="referHead" style={{ fontWeight: "normal" }}>
              {inventor} | {industry} | {applicationDate}
            </h2>
          )} */}
            <div className="pageStrip2">
              <div
                className="additional"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              ></div>
              <div style={{ fontSize: "0.8rem" }}>Claim Recommendations</div>
              <div className="endStrip" style={{ fontSize: "0.8rem" }}></div>
            </div>
            <Col xs={{ span: 12, order: 1 }} md="12">
              {/* <div className="paraHeading">Recommended Claim :</div> */}

              {/* <div className="paraInvention">{claimPara} :</div> */}
              <ul className="featureList">
                {claimRecommendations ? (
                  <div>
                    {claimRecommendations.map((claim, index) => (
                      <li key={index}>{claim}</li>
                    ))}
                  </div>
                ) : (
                  <Skeleton
                    className="recentClaimSkeleton"
                    width={300}
                    height={30}
                    count={5}
                  />
                )}
              </ul>
              {/* <div className="referClaimContainer">
              <div className="claimAnalysis">
                <div style={{ fontWeight: "bold" }}>Industry</div>
                <p>Machines & Engines</p>
              </div>
              <div className="claimAnalysis">
                <div style={{ fontWeight: "bold" }}>Technology</div>
                <p>Casings</p>
              </div>
            </div> */}
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6"></Col>
          </Row>
          <div className="pageStrip">
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>

          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />

          {/* Page 9 */}
          {/* <Row className="g-0">
          <div className="pageNavbar">
            <div className="additional"></div>
            <div className="forWhom">
              Own your invention for {userName} - {userInfo}
            </div>
            <a
              className="logoReport"
              onClick={() => {
                redirectToHome();
              }}
            >
              Incubig AI
            </a>
          </div>
          <div>
            {title ? (
              <div className="referTitle">{sanitizeText(referTitle)}</div>
            ) : (
              <div>{props.flag ? <Skeleton /> : <p></p>}</div>
            )}
          </div>
          {applicationDate && (
            <h2 className="referHead" style={{ fontWeight: "normal" }}>
              {inventor} | {industry} | {applicationDate}
            </h2>
          )}
          <div className="pageStrip2">
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            ></div>
            <div style={{ fontSize: "0.8rem" }}>
              Claims Recommendations | US20200408191A1
            </div>
            <div className="endStrip" style={{ fontSize: "0.8rem" }}></div>
          </div>
          <Col xs={{ span: 12, order: 1 }} md="6">
            <div className="paraHeading">Recommended Claim :</div>
            <div className="paraInvention">{claimPara} :</div>
            <ul className="featureList">
              <li>
                each of the plurality of elliptical blades has an elliptical shape
                that is approximated by two or more substantially straight blade
                portions extending between distal ends and one or more central
                locations of that elliptical blade.
              </li>
              <li>
                each of the two or more blade portions is substantially straight
                along its longitudinal axis, has a foil cross section along its
                longitudinal axis, is twisted about its longitudinal axis by a
                first angle.
              </li>
            </ul>
            <div className="referClaimContainer">
              <div className="claimAnalysis">
                <div style={{ fontWeight: "bold" }}>Industry</div>
                <p>Machines & Engines</p>
              </div>
              <div className="claimAnalysis">
                <div style={{ fontWeight: "bold" }}>Technology</div>
                <p>Casings</p>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 2 }} md="6"></Col>
        </Row>
        <div className="pageStrip">
          <div
            className="additional"
            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            Developed by Incubig AI
          </div>
          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>Page 9</div>
          <div
            className="endStrip"
            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            Own Your Invention
          </div>
        </div>

        <hr
          className="thin-horizontal-line"
          style={{
            backgroundColor: "rgb(199, 198, 198)",
            color: "rgb(199, 198, 198)",
            marginTop: "10px",
            marginBottom: "0px",
          }}
        /> */}

          {/* Page 10 */}
          {/* <Row className="g-0">
          <div className="pageNavbar">
            <div className="additional"></div>
            <div className="forWhom">
              Own your invention for {userName} - {userInfo}
            </div>
            <a
              className="logoReport"
              onClick={() => {
                redirectToHome();
              }}
            >
              Incubig AI
            </a>
          </div>
          <Col xs={{ span: 12, ordr: 1 }} md="6">
            <div>
              <div className="aboutText">Overall summary & next steps</div>
            </div>
            <div>
              {title ? (
                <div className="title" style={{ fontWeight: "normal" }}>
                  {sanitizeText(title)}
                </div>
              ) : (
                <div>{props.flag ? <Skeleton /> : <p></p>}</div>
              )}
            </div>
            <hr className="belowAbout" />
            <div className="paraHeading">Report Summary</div>
            {reportSummary.map((paragraph, index) => (
              <div
                key={index}
                className="paraInvention"
                style={{ fontSize: "1rem", width: "90%" }}
              >
                {paragraph}
              </div>
            ))}
          </Col>
          <Col xs={{ span: 12, order: 2 }} md="6">
            <div className="reportSummary">
              <div className="paraHead">Suggestions</div>
              <div className="scorePara" style={{ marginTop: "2%" }}>
                {reportSummaryText}
              </div>
            </div>
          </Col>
        </Row>
        <div className="pageStrip">
          <div
            className="additional"
            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            Developed by Incubig AI
          </div>
          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>Page 10</div>
          <div
            className="endStrip"
            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            Own Your Invention
          </div>
        </div>

        <hr
          className="thin-horizontal-line"
          style={{
            backgroundColor: "rgb(199, 198, 198)",
            color: "rgb(199, 198, 198)",
            marginTop: "10px",
            marginBottom: "0px",
          }}
        /> */}

          {/* Intelligence intro */}
          {/* <Row className="g-0">
          <Col xs={{ span: 12, order: 1 }} md="6">
            <div>
              <div className="noveltyTitle">Technology Intelligence</div>
            </div>
            <div className="noveltyText">Know Your Technology</div>
            <hr className="belowNovelty" />
            <div className="paraInvention" style={{ width: "80%" }}>
              The next section of the report provides most recent claims,
              invention features similar to the invention{" "}
              <strong
                className="paraInvention"
                style={{ width: "80%", marginLeft: "0px" }}
              >
                {title}
              </strong>
              .
            </div>
            <ul className="novelList">
              <li>
                Recommended claims can be added in the invention, to increase the
                validity of the invention.
              </li>
            </ul>
          </Col>
          <Col xs={{ span: 12, order: 2 }} md="6">
            <div className="leftSection2"></div>
          </Col>
        </Row>
        <hr
          className="thin-horizontal-line"
          style={{
            backgroundColor: "rgb(199, 198, 198)",
            color: "rgb(199, 198, 198)",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        /> */}

          {/* Page 11 */}
          {/* <Row className="g-0">
          <div className="pageNavbar">
            <div className="additional"></div>
            <div className="forWhom">
              Own your invention for {userName} - {userInfo}
            </div>
            <a
              className="logoReport"
              onClick={() => {
                redirectToHome();
              }}
            >
              Incubig AI
            </a>
          </div>
          <div className="lastTechnology">
            {technologyTitle ? (
              <div className="referTitle lastRefer">
                {sanitizeText(technologyTitle)}
              </div>
            ) : (
              <div>{props.flag ? <Skeleton /> : <p></p>}</div>
            )}

            {applicationDate && (
              <h2 className="referHead lastHead" style={{ fontWeight: "normal" }}>
                {technologyPatents} patents filed | {technologyCountries}{" "}
                countries working | {technologyOrganizations} active organizations
              </h2>
            )}
          </div>
        </Row>
        <Row className="g-0">
          <Col xs={{ span: 12, order: 1 }} md="6">
            <div className="lastSummary">
              <Grid
                gridHeading={"Summary"}
                upperGrid={[
                  {
                    value:
                      props.keyGeographies && props.keyGeographies.length > 0
                        ? props.keyGeographies.length
                        : undefined,
                    caption: "Countries working",
                  },
                  {
                    value:
                      props.keyPlayers && props.keyPlayers.length > 0
                        ? props.keyPlayers.length
                        : undefined,
                    caption: "Active organizations",
                  },
                  {
                    value:
                      props.keyInventors && props.keyInventors.length > 0
                        ? props.keyInventors.length
                        : undefined,
                    caption: "Active inventors",
                  },
                ]}
                lowerGrid={[
                  {
                    value:
                      props.keyGeographies && props.keyGeographies.length > 0
                        ? props.keyGeographies[0][0]
                        : undefined,
                    caption: "Leading country",
                  },
                  {
                    value: props.topYearContribution,
                    caption: "Patents in " + props.topYear,
                  },
                  { value: props.grantPercent, caption: "Patents Granted" },
                ]}
                topPlayer={
                  props.keyPlayers && props.keyPlayers.length > 0
                    ? props.keyPlayers[0][0]
                    : undefined
                }
              />
            </div>
            <div className="reportMap">
              <SimpleMap
                keyGeographies={keyGeographies}
                color="blue"
                backgroundColor="white"
                borderColor="grey"
              />
            </div>
          </Col>
          <Col xs={{ span: 12, order: 2 }} md="6">
            <div className="lastSummary">
              <LineChart />
            </div>
            <div className="reportBar">
              <HorizontalBarChart
                keyGeographies={keyGeographies}
                heading="Organization Count by Country"
              />
            </div>
          </Col>
        </Row>
        <div className="pageStrip">
          <div
            className="additional"
            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            Developed by Incubig AI
          </div>
          <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>Page 11</div>
          <div
            className="endStrip"
            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            Own Your Invention
          </div>
        </div>

        <hr
          className="thin-horizontal-line"
          style={{
            backgroundColor: "rgb(199, 198, 198)",
            color: "rgb(199, 198, 198)",
            marginTop: "10px",
            marginBottom: "0px",
          }}
        /> */}

          {/* Last Page  */}
          <Row className="g-0">
            <Col xs={{ span: 12, order: 1 }} md="6">
              <div className="pageNavbar">
                <a
                  className="logoReport"
                  onClick={() => {
                    redirectToHome();
                  }}
                >
                  Incubig AI
                </a>
                <div className="additional"></div>
                <div className="forWhom"></div>
              </div>
              <div className="lastReportText">
                <div className="lastNoveltyText">Thank You</div>
                <div className="lastNoveltyTitle">
                  For using Own Your Invention report
                </div>
              </div>
              <hr className="belowNovelty lastbelowNovelty" />

              <div className="clickToButtons">
                <div
                  className="shareButtonReport"
                  title="Share"
                  onClick={handleShare}
                >
                  <RWebShare
                    data={{
                      text: "Novelty Report by Incubig",
                      url: window.location.href,
                      title: "Novelty Report by Incubig",
                    }}
                  >
                    <MdShare size={30} />
                  </RWebShare>
                </div>

                <div
                  className="convertButton"
                  title="Convert to pdf"
                  onClick={handleToPDF}
                >
                  {downloadingFlag ? (
                    <div className="spinner"></div>
                  ) : (
                    <FaFilePdf size={30} />
                  )}
                </div>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md="6">
              <div className="leftSection2"></div>
            </Col>
          </Row>
          <div className="pageStrip" style={{ marginTop: "0px" }}>
            <div
              className="additional"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Developed by Incubig AI
            </div>
            <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}></div>
            <div
              className="endStrip"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Own Your Invention
            </div>
          </div>
          <hr
            className="thin-horizontal-line"
            style={{
              backgroundColor: "rgb(199, 198, 198)",
              color: "rgb(199, 198, 198)",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          />
        </div>
      ) : (
        <div>
          <Row className="g-0">
            <Col xs="12">
              <PersonalizedHeader />
            </Col>
          </Row>
          <ForYouHeader
            changeSection={"reports"}
            reportsSection={true}
            patentsSection={false}
          />

          {/* Page banner */}
          <Row className="g-0">
            <Col xs="12">
              <hr className="thin-horizontal-line" />
              <span
                className="headingSearch"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "0.75rem",
                  background: "linear-gradient(to right, #1834dd, #d079df)",
                  color: "white",
                  height: "40px",
                }}
              >
                For You &#124; Reports
              </span>
            </Col>
          </Row>

          <GoToSignin />
        </div>
      )}
    </div>
  );
};

export default Reports;
