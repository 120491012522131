import React from 'react'
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { moveToForYouPage } from '../../actions/helper';

const ForYouHeader = (props) => {

  const navigate = useNavigate();

  const changeSection = (section) => {
    props.changeSection(section)
    moveToForYouPage(navigate,section)
  }

  return (
    <div>
      <hr className="mt-0"/>
      <Row className="g-0">
          <Col xs={{ span: 12, order: 1 }} md="6">
              <div className="technologyMonitor">
              <div>
                  <div style={{ fontSize: "1.3rem"}}>
                    For Your Research
                  </div>
              </div>
              <h2 className="details-title">
                  Research intelligence personalized for you 
              </h2>
              </div>
          </Col>
          <Col xs={{ span: 12, order: 2 }} md="6" style={{display: "flex"}}>
          <div className="sectionContainer">
          <div
                className={`sectionButton ${
                  window.location.pathname.split("/").at(-1) == "user-profile"
                    ? "bold Outline"
                    : ""
                }`}
                onClick={() => changeSection("user-profile")}
              >
                Profile
              </div>
              <div
                className={`sectionButton ${
                  window.location.pathname.split("/").at(-1) == "state-of-the-art"
                    ? "bold Outline"
                    : ""
                }`}
                onClick={() => changeSection("state-of-the-art")}
              >
                State of the art
              </div>
              <div
                className={`sectionButton ${
                  window.location.pathname.split("/").at(-1) == "technologies"
                    ? "bold Outline"
                    : ""
                }`}
                onClick={() => changeSection("technologies")}
              >
                Technologies
              </div>
              {props.patentsSection&&
              <div
                className={`sectionButton ${
                  window.location.pathname.split("/").at(-1) == "your-organization"
                    ? "bold Outline"
                    : ""
                }`}
                onClick={() => changeSection("your-organization")}
              >
                Your Organization
              </div>
              }
              {/* {props.patentsSection&&
              <div 
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "patent-status"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => changeSection("patent-status")}>
              Status
              </div>
              } */}
              {props.reportsSection&&
              <div
                className={`sectionButton ${
                  window.location.pathname.split("/").at(-1) == "report" | window.location.pathname.split("/").at(-1) == "own-your-invention"
                    ? "bold Outline"
                    : ""
                }`}
                onClick={() => changeSection("report")}
              >
                Get Report
              </div>
              }
              
            </div>
          </Col>
      </Row>

    </div>
  )
}

export default ForYouHeader;