import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Grid from "../../components/Grid/grid";
import { toTitleCase } from "../../actions/helper";
import { Tracking } from "../../components/tracking/tracking";
import { moveToCompanyPage } from "../../actions/helper";
import { Carousel } from "../../components/carousel/carousel";

const TechnologyOverview = (props) => {
  const navigate = useNavigate();
  const technologyOverview = props.technologyOverview;
  const topYear = props.topYear;
  const topYearCount = props.topYearCount;
  const grantRate = props.grantRate;
  const keyTechnologies = (props.technologyOverview && props.technologyOverview.technology_suggestions)
  ? props.technologyOverview.technology_suggestions.filter(tech => tech.cpc !== props.selectedTechnology.cpc)
  : [];
  const [searchStatus, setSearchStatus] = useState(false);
  
  useEffect(()=>{
    setSearchStatus(props.searchStatus);
  })

  return (
    <div>
      <Tracking
        title={
          props.selectedTechnology
            ? props.selectedTechnology.definition
            : "Technology analysis by Incubig"
        }
        description={
          "Patents in " +
          (props.selectedTechnology
            ? props.selectedTechnology.definition
            : "Technology analysis by Incubig")
        }
        keywords={
          (props.selectedTechnology&&props.selectedTechnology.definition!=null)
            ? props.selectedTechnology.definition.split(" ").join(",")
            : "Technology analysis by Incubig"
        }
      />
      <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
        <p className="keyPoints">Key Points</p>
{technologyOverview?.country_analysis ? (
  <p className="para" style={{ marginTop: "20px" }}>
    In <b className="globalStrong">{topYear}</b>, research surged
    globally in this technology with{" "}
    <b className="globalStrong">
      {technologyOverview?.country_analysis?.total_countries || "N/A"}
    </b>{" "}
    countries actively innovating, led by{" "}
    <b className="globalStrong">
      {technologyOverview?.country_analysis?.top_countries?.length > 0
        ? technologyOverview?.country_analysis?.top_countries[0]?.country
        : "Unknown Country"}
    </b>
    .
  </p>
) : (
  <Skeleton />
)}
          <ul
            className="keyPointsUL"
            style={{
              height: "auto",
              marginBottom: "20px",
            }}
          >
            <li style={{ marginBottom: "10px" }}>
              {typeof topYear !== "undefined" ? (
                <div className="keyPointsLI">
                  <strong className="keyPointsLI" style={{ color: "black" }}>
                    {topYear}
                  </strong>{" "}
                  year observed a high research activity.
                </div>
              ) : (
                <Skeleton />
              )}
            </li>
            <li style={{ marginBottom: "10px" }}>
              {typeof technologyOverview !== "undefined" ? (
                <div className="keyPointsLI">
                  <strong className="keyPointsLI" style={{ color: "black" }}>
                    {technologyOverview.country_analysis.total_countries}
                  </strong>{" "}
                  countries are actively doing research.
                </div>
              ) : (
                <Skeleton />
              )}
            </li>
            <li style={{ marginBottom: "10px" }}>
              {typeof technologyOverview !== "undefined" ? (
                <div className="keyPointsLI">
                  <strong className="keyPointsLI" style={{ color: "black" }}>
                    {
                      (technologyOverview.country_analysis.top_countries.length>0)?technologyOverview.country_analysis.top_countries[0]
                        .country:""
                    }
                  </strong>{" "}
                  is leading the research
                  {technologyOverview.country_analysis.top_countries.length > 1
                    ? ", followed by " +
                      technologyOverview.country_analysis.top_countries[1]
                        .country
                    : ""}
                  .
                </div>
              ) : (
                <Skeleton />
              )}
            </li>
            <li style={{ marginBottom: "10px" }}>
              {typeof technologyOverview !== "undefined" ? (
                <div className="keyPointsLI">
                  <strong
                    className="keyPointsLI"
                    style={{ color: "black", cursor: "pointer" }}
                    onClick={() => {
                      moveToCompanyPage(
                        navigate,
                        technologyOverview.assignee_analysis.top_assignees[0]
                          .assignee
                      );
                    }}
                  >
                    {toTitleCase(
                      (technologyOverview.assignee_analysis.top_assignees.length>0)?technologyOverview.assignee_analysis.top_assignees[0]
                        .assignee:""
                    )}
                  </strong>{" "}
                  filed{" "}
                  {(technologyOverview.assignee_analysis.top_assignees.length)?technologyOverview.assignee_analysis.top_assignees[0].count:0}{" "}
                  patents recently.
                </div>
              ) : (
                <Skeleton />
              )}
            </li>
          </ul>
        </Col>
        <Col xs="12" md="6">
          <div className="forStats">
            <p style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              Technology data stats
            </p>
            <div className="box-container">
              <div className="box" style={{ borderRight: "1px solid black" }}>
                <div className="widget-block">
                  {typeof technologyOverview !== "undefined" ? (
                    <div className="value">
                      {technologyOverview.total_applications}
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                  <p className="body-3" style={{ fontSize: "0.8rem" }}>
                    Patents published
                  </p>
                </div>
              </div>
              <div className="box" style={{ borderRight: "1px solid black" }}>
                <div className="widget-block">
                  {typeof grantRate !== "undefined" ? (
                    <div className="value">
                      {parseInt(
                        (parseFloat(grantRate) *
                          technologyOverview.total_applications) /
                          100
                      )}
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                  <p className="body-3" style={{ fontSize: "0.8rem" }}>
                    Patents granted
                  </p>
                </div>
              </div>
              <div className="box">
                <div className="widget-block">
                  {technologyOverview ? (
                    <div className="value">
                      {parseInt(technologyOverview.total_applications) -
                        parseInt(
                          (parseFloat(grantRate) *
                            technologyOverview.total_applications) /
                            100
                        )}
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                  <p className="body-3" style={{ fontSize: "0.8rem" }}>
                    Patents pending
                  </p>
                </div>
              </div>
            </div>
            <Grid
              gridHeading="Summary"
              upperGrid={[
                {
                  value: technologyOverview
                    ? technologyOverview.country_analysis.total_countries
                    : undefined,
                  caption: "Countries",
                },
                {
                  value: technologyOverview
                    ? technologyOverview.assignee_analysis.total_assignees
                    : undefined,
                  caption: "Organizations",
                },
                {
                  value: technologyOverview
                    ? technologyOverview.inventor_analysis.total_inventors
                    : undefined,
                  caption: "Inventors",
                },
              ]}
              lowerGrid={[
                {
                  value: (technologyOverview && technologyOverview.country_analysis.top_countries.length>0)
                    ? technologyOverview.country_analysis.top_countries[0]
                        .country
                    : undefined,
                  caption: "Leading country",
                },
                { value: topYearCount, caption: "Patents in " + topYear },
                { value: grantRate, caption: "Patents Granted" },
              ]}
              topYear={topYear}
              topPlayer={
                technologyOverview&&technologyOverview.assignee_analysis.top_assignees.length>0
                  ? technologyOverview.assignee_analysis.top_assignees[0]
                      .assignee
                  : undefined
              }
            />
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />
      {(keyTechnologies.length > 0) && 
      <Row className="g-0" style={{marginBottom: "30px"}}>
        <Col md="12">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "10px",
            }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              Other active technologies
            </span>
          </div>
          <div className="cpcTable" style={{marginLeft: "0px", marginTop: "0px"}}>
              <Carousel type={"technology"} data={keyTechnologies} isMoving={true} />
          </div>
        </Col>
      </Row>
      }
    </div>
  );
};

export default TechnologyOverview;
