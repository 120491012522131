import React, { useEffect, useRef } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { moveToPatentPage, toTitleCase, truncateText } from '../../actions/helper';
import "../../pages/forYouPage/forYou.css"; 
import "../../components/patentsList/patentList.css";
import "../../pages/landingPage/landingPage.css";

const PatentCardList = ({ patents = [], showBackgroundColor, showAbstract = true, isMoving }) => {
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const startScroll = () => {
            animationRef.current = requestAnimationFrame(scroll);
        };

        const stopScroll = () => {
            cancelAnimationFrame(animationRef.current);
        };

        const scroll = () => {
            if (container.scrollLeft <= 0) {
                container.scrollTo({ left: container.scrollWidth, behavior: 'auto' });
            } else {
                container.scrollLeft -= 1.5;
            }
            animationRef.current = requestAnimationFrame(scroll);
        };

        if (isMoving) {
            startScroll();
            container.addEventListener('mouseenter', stopScroll);
            container.addEventListener('mouseleave', startScroll);
        } else {
            stopScroll();
            container.removeEventListener('mouseenter', stopScroll);
            container.removeEventListener('mouseleave', startScroll);
        }

        return () => {
            cancelAnimationFrame(animationRef.current);
            container.removeEventListener('mouseenter', stopScroll);
            container.removeEventListener('mouseleave', startScroll);
        };
    }, [isMoving]);

    return (
        <div className="scrolling-containerNew" ref={containerRef}>
            {patents.map((patent, index) => (
                <div 
                    key={index}
                    className="card-wrapperInsightNew" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => moveToPatentPage(navigate, patent.publication_number, "about")}
                >
                    <Card  className='cardNew'
                      
                    >
                        <CardBody className='cardBodyNew' style={{ display: 'flex', flexDirection: 'column' }}>
                            <CardTitle style={{ fontSize: "1rem", fontWeight: "bold", marginTop: "5px" }}>
                                {truncateText(toTitleCase(patent.title), 80)}
                            </CardTitle>
                            <h2 className="details-patent" style={{ fontSize: "0.7rem", color: "#838c95", marginTop: "10px" }}>
                                {patent.assignee
                                    ? patent.assignee[0]
                                        ? toTitleCase(patent.assignee[0])
                                        : toTitleCase(patent.inventor[0])
                                    : patent.name
                                        ? toTitleCase(patent.name)
                                        : toTitleCase(patent.inventor)} 
                                | {toTitleCase(patent.industry)} 
                                | {patent.publication_year || String(patent.publication_date).slice(0, 4)} 
                                | {patent.assignee_country || patent.country}
                            </h2>
                            {showAbstract && (
                                <p className="patentDescription" style={{ fontSize: "0.8rem", color: "black" }}>
                                    {truncateText(patent.abstract || patent.abstract_text || "", 180)}
                                </p>
                            )}
                        </CardBody>
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default PatentCardList;
