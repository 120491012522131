import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Popover } from "reactstrap";
import {
  base64Decode,
  moveToPatentPage,
  moveToTechnologyPage,
  removeDuplicateTechnologies,
  toTitleCase,
} from "../../actions/helper";
import Skeleton from "react-loading-skeleton";
import "../../components/Tables/cpcTable.css";

export const ReportReference = (props) => {
  const [popoverContent, setPopoverContent] = useState({ title: "", body: "" });
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const sectionId = "TableRow";

  const sanitizeText = (str) => {
    if (typeof str === "string") {
      str = str.replace("_", " ");
      return str[0].toUpperCase() + str.slice(1, str.length).toLowerCase();
    } else {
      return str;
    }
  };

  const navigate = useNavigate();

  const handlePopoverClick = (e, item) => {
    setPopoverContent({ title: item[0], body: `${item[1]} related patents` });
    setPopoverTarget(e.target);
    setPopoverOpen(true);
  };

  const closePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <div className="reportCPC">
      {props.showHeading && (
        <div className="newTechnologyTableheading">
          {props.heading ? props.heading : "Patent Technologies"}
        </div>
      )}
      <Table className="mainTable">
        <thead>
          <tr>
            <th
              className="text-center"
              style={{
                fontWeight: "normal",
                marginLeft: "-2%",
                backgroundColor: "#020b26",
                color: "white",
              }}
            >
              Publication Number
            </th>

            <th
              className="text-center"
              style={{
                fontWeight: "normal",
                backgroundColor: "#020b26",
                color: "white",
              }}
            >
              Title
            </th>

            <th
              className="text-center"
              style={{
                fontWeight: "normal",
                backgroundColor: "#020b26",
                color: "white",
                textAlign: "end",
                marginLeft: "10%",
              }}
            >
              Filing Date
            </th>

            <th
              className="text-center"
              style={{
                fontWeight: "normal",
                backgroundColor: "#020b26",
                color: "white",
              }}
            >
              Assignee
            </th>

            <th
              className="text-center"
              style={{
                fontWeight: "normal",
                backgroundColor: "#020b26",
                color: "white",
                marginRight: "-8%",
              }}
            >
              Country
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data ? (
            props.data.map((item, index) => (
              <tr
                key={index}
                onClick={() =>
                  moveToPatentPage(
                    navigate,
                    base64Decode(item.publication_number),
                    "about"
                  )
                }
                className="rowTable"
              >
                <td className="reportTableFirst">
                  {base64Decode(item.publication_number)}
                </td>
                <td className="reportTableSecond">{toTitleCase(item.title)}</td>
                <td className="reportTableThird">{item.application_date}</td>
                <td className="reportTableFourth">
                  {item.assignee && item.assignee.length > 0
                    ? toTitleCase(item.assignee[0])
                    : toTitleCase(item.inventor[0])}
                </td>
                <td className="reportTableFifth">{item.assignee_country}</td>
              </tr>
            ))
          ) : (
            <tr className="rowTable">
              <td className="reportTableFirst">
                <Skeleton
                  className="customSkeleton"
                  width={50}
                  height={20}
                  count={5}
                />
              </td>
              <td className="reportTableSecond">
                <Skeleton
                  className="customSkeleton"
                  width={300}
                  height={20}
                  count={5}
                />
              </td>
              <td className="reportTableThird">
                <Skeleton
                  className="customSkeleton"
                  width={50}
                  height={20}
                  count={5}
                />
              </td>
              <td className="reportTableFourth">
                <Skeleton
                  className="customSkeleton"
                  width={200}
                  height={20}
                  count={5}
                />
              </td>
              <td className="reportTableFifth">
                <Skeleton
                  className="customSkeleton"
                  width={50}
                  height={20}
                  count={5}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
