import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
} from "reactstrap";
import { Logo } from "../../components/logo/logo";
import { Tracking } from "../../components/tracking/tracking";
import "./signUp.css";
import { Footer } from "../../components/footer/footer";
import AcademicsTable from "../../components/Tables/academicsTable";
import BusinessTable from "../../components/Tables/businessTable";
import Customers from "../../components/ourCustomers/customer";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import SignupForm from "../../components/signupform/signupForm";

export const SignUp = () => {

  // for tables
  const academics = ["Text", "Text"];
  const business = ["Text", "Text"];
  const location = useLocation();

  // for customers
  const customers = [
    "The process is fast, efficient, cost effective and the results have been outstanding. Strategically we can do much more at a fraction of the cost and at incredible speed.",
    "I have been working with Incubig since the last two years, and they have never disappointed me. Reports are detailed and meet all the expectations. Their report on Covid19 in early 2020 was shared with the government and was well appreciated.",
    "Incubig's solution & platform is needed in South Korea. One of the top companies in KSGC 2019 competition, signed MoUs and joint venture agreements with leading corporations.",
  ];
  const customerName = [
    "Michael Wright, Founding Partner Intercepting Horizons",
    "Satish Mehta, Director Digital India Fund",
    "Chelsea Han, Manager NIPA",
  ];

  const modifiedCustomerName = customerName.map((name) => (
    <span>
      {name.split(", ").map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index < name.split(", ").length - 1 && <br />}
        </React.Fragment>
      ))}
    </span>
  ));

 

  // const registerURL =
  //   "http://localhost:8008/authorization/register"

 

  

  const navigate = useNavigate();
  const moveToLandingPage = () => {
    navigate("/");
  };

  const isSignupPage = location.pathname === "/sign-up";

  return (
    <div className="signupPage">
      <Tracking />

      <Row className="g-0">
        <PersonalizedHeader />
      </Row>      
      <Row className="g-0">
        {/* <Col
          xs="12"
          className="formLeft d-flex justify-content-center flex-column"
        >
         <div
                className="para&country"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <p
                  className="para"
                  style={{
                    marginTop: "50px",
                    fontSize: "1.3rem",
                    marginBottom: "50px",
                    textAlign: "center",
                    width: "100%",
                    color: "white",
                    marginLeft: "20px",
                    marginRight: "20px"
                  }}
                >
                  DYR gives you the foresight to innovate and dominate the
                  future of technology.
                </p>
              </div>
          <div
            className=" belowDontReact"
            style={{
              width: "auto",
              backgroundColor: "transparent",
              color: "white",
              marginTop: "20px",
            }}
          >
            <span className="summaryTagText belowDontReactText">
              Fuel Your R&D and Business with personalised Research
              Intelligence.
            </span>
          </div>
          
            <SignupForm/>

        </Col> */}
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0">
        <Col xs="12">
          <div
            style={{
              textAlign: "center",
              position: "relative",
              marginTop: "50px",
            }}
          >
            <div className="textAbovePortfolio">
              Powering our Global Research & Innovation community.
            </div>
            <hr className="lineBelowPortfolio" />
          </div>

          <div className="clientContainer">
            <div className="client1">
              <img
                src={process.env.PUBLIC_URL + "/incubig_michael.jfif"}
                className="rounded-circle"
                style={{ width: "50%", height: "50%" }}
                alt="My Image"
              />
              <div className="clientName">Michael Wright</div>
              <div className="clientTitle">
                Founding Partner, Intercepting Horizons
              </div>
              <div className="clientReview">
                The process is fast, efficient, cost effective and the results
                have been outstanding. Strategically we can do much more at a
                fraction of the cost and at incredible speed.
              </div>
            </div>
            <div className="client2">
              <img
                src={process.env.PUBLIC_URL + "/incubig_satish.jfif"}
                className="rounded-circle"
                style={{ width: "35%", height: "50%" }}
                alt="My Image"
              />
              <div className="clientName">Satish Mehta</div>
              <div className="clientTitle">Director, Digital India Fund</div>
              <div className="clientReview">
                RI have been working with Incubig since the last two years, and
                they have never disappointed me. Reports are detailed and meet
                all the expectations. Their report on Covid19 in early 2020 was
                shared with the government and was well appreciated.
              </div>
            </div>
            <div className="client3">
              <img
                src={process.env.PUBLIC_URL + "/incubig_chelsea.jfif"}
                className="rounded-circle"
                style={{ width: "50%", height: "50%" }}
                alt="My Image"
              />
              <div className="clientName">Chelsea Han</div>
              <div className="clientTitle">Manager, NIPA</div>
              <div className="clientReview">
                Incubig's solution & platform is needed in South Korea. One of
                the top companies in KSGC 2019 competition, signed MoUs and
                joint venture agreements with leading corporations.
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr className="lineBelowPortfolio " />

      <Row className="g-0">
        <Col xs="12">
          <div style={{ height: "20px", marginTop: "30px" }}></div>
        </Col>
      </Row>

      <Row className="g-0">
        <Col>
          <Footer />
        </Col>
      </Row>
    </div>
  );
};
