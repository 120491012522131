import React from 'react';
import { Row, Col } from "reactstrap";
import { Tracking } from '../../components/tracking/tracking';
import Grid from '../../components/Grid/grid';
import LineChart from '../../components/Charts/lineChart';
import SimpleMap from '../../components/WorldMap/WorldMap';

const InventorPageActivity = (props) => {
  const sampleData = {
    gridHeading: "Overview",
    upperGrid: [
      { caption: "Industry Impact", value: "10" },
      { caption: "Technologies Developed", value: "30" },
      { caption: "Patents Published", value: "245" },
    ],
    lowerGrid: [
      { caption: "Patents Granted", value: "90" },
      { caption: "Research Papers", value: "50" },
      { caption: "Collaborations", value: "15" },
    ]
  };
  const sampleData2 = {
    labels: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
    series: [10, 15, 8, 12, 20, 25, 30, 28, 35],
  };

  const sampleMapData3 = [
    ["US", 100],
    ["JP", 80],
    ["CN", 120],
    ["KR", 60],
  ];
  const handleMapClick = (countryCode, countryValue) => {
    console.log(`Clicked on country: ${countryCode}, with value: ${countryValue}`);
  };

  return (
    <div>
      <Tracking  title={props.selectedInventor + " 's patent and research trends"} description={"Patent and research trends of "+props.selectedInventor+" 's patent portfolio"} keywords={props.selectedInventor}/>

    <Row className="g-0">
      <Col xs="12" md="6" className="borderRight">
      <div className="companyTag">Research Activity</div>

      <h2
              className="globalText"
              style={{ fontSize: "0.8rem", marginTop: "20px" }}
            >
           Mitsuru mochizuki demonstrated significant research activity in 2019, filing a
substantial 230 patent applications. Of these, a 64.7% of the patents
were granted.
            </h2>

            <div className="forStats" style={{marginTop: "30px", marginLeft: "25px"}}>
<Grid   gridHeading={sampleData.gridHeading}
        upperGrid={sampleData.upperGrid}
        lowerGrid={sampleData.lowerGrid}
        topPlayer={sampleData.topPlayer}
      />
      </div>

      </Col>
      <Col xs="12" md="6">
      <div className="companyTag">Research activity over time</div>
      <div className="researchAreaChart">
        <LineChart labels={sampleData2.labels}
        series={sampleData2.series}
        title="Patents Filed Over Years"
        axisLabel="Number of Patents"
        name="Patents Filed"/>
      </div>
      </Col>
    </Row>

    {/* <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      /> */}

<hr style={{width: "97%", marginLeft: "25px", background: "linear-gradient(to right, rgb(12 177 232), rgb(13 39 238))", height: "10px", border: "white", marginTop: "10px"}}/>


    <Row className='g-0'>
      <Col xs="12" md="6">
      <h1 style={{ fontSize: "1.3rem", marginLeft: "25px", marginTop: "0px" }}>
      Where Mitsuru Mochizuki is doing research
              </h1>

              <div className="citationMap">
              <SimpleMap
        data={sampleMapData3}
        color="blue"
        backgroundColor="white"
        borderColor="#000"
        clickHandler={handleMapClick}
      />
      </div>

      </Col>

      <Col xs="12" md="6">
      <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginLeft: "20px",
              marginTop: "0px",
            }}
          >
            <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
              Top Locations
            </span>
          </div>
          <ol>
            <li className="country-list-item">
            <p className="country-name">Fort worth</p>
            <span className="patents-count">267 patents</span>
            </li>
            <li className="country-list-item">
            <p className="country-name">South burlington</p>
            <span className="patents-count">64 patents</span>
            </li>
            <li className="country-list-item">
            <p className="country-name">Providence</p>
            <span className="patents-count">48 patents</span>
            </li>
            <li className="country-list-item">
            <p className="country-name">Santa cruz</p>
            <span className="patents-count">37 patents</span>
            </li>
            <li className="country-list-item">
            <p className="country-name">Mountain view</p>
            <span className="patents-count">35 patents</span>
            </li>
            </ol>
      </Col>
    </Row>



    </div>
  )
}

export default InventorPageActivity;