import React from 'react';
import {Row, Col} from 'reactstrap';
import { Tracking } from '../../components/tracking/tracking';
import PieChart from '../../components/Charts/pieChart';
import { CpcTable } from '../../components/Tables/cpcTable';
import { Timeline } from '../../components/Timeline/timeline';


const InventorPageImpact = (props) => {
  const isMobile = window.innerWidth <= 768;
  const isWidth = window.innerWidth <= 768;
  const sampleValues = [44, 55, 41, 17, 15];
const sampleLabels = ["Communication System", "Computer Peripherals", "Electrical Devices", "Electrography", "Entertainment"];

const sampleData2 = [
  { definition: "Electric digital data processing", count: 120 },
  { definition: "Wireless communication networks", count: 85 },
  { definition: "Transmission of digital information", count: 65 },
  { definition: "Control or regulating systems in general", count: 30 },
  { definition: "Multiplex communication", count: 50 },
];


  const samplePointers = {
    maxima: [
      { year: 2010, technology: 'Wireless communication networks', sharedPatents: 2 },
      { year: 2012, technology: 'Blockchain', sharedPatents: 3 },
      { year: 2015, technology: 'Cybersecurity', sharedPatents: 1 },
      { year: 2018, technology: 'Quantum Computing', sharedPatents: 4 },
      { year: 2020, technology: 'IoT', sharedPatents: 5 }
    ],
    minima: [
      { year: 2011, technology: 'Multiplex communication', sharedPatents: 1 },
      { year: 2013, technology: 'Control or regulating systems in general', sharedPatents: 2 },
      { year: 2016, technology: 'Transmission of digital information', sharedPatents: 3 },
      { year: 2019, technology: 'Wireless communication networks', sharedPatents: 2 },
      { year: 2021, technology: 'Electric digital data processing', sharedPatents: 4 }
    ]
  };


  return (
    <div>

      <Tracking title={props.selectedInventor + " 's patent impact on industries and technologies"} description={"Patent impact and technologies of "+ props.selectedInventor} keywords={props.selectedInventor} />

      <Row className='g-0'>
        <Col xs="12" md="6" className="borderRight">
        <div className="companyTag">Research Activity</div>

        <h2
              className="globalText"
              style={{ fontSize: "0.8rem", marginTop: "20px" }}
            >
           Mitsuru mochizuki primarily focuses on pictorial communication and communication peripherals.
            </h2>

            <hr style={{width: "80%", marginLeft: "25px", background: "linear-gradient(to right, rgb(12 177 232), rgb(13 39 238))", height: "10px", border: "white", marginTop: "25px"}}/>

            <h1 style={{ fontSize: "1.3rem", marginLeft: "25px", marginTop: "0px" }}>
            What Industries Mitsuru Mochizuki is  is Impacting?
              </h1>

            <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ fontSize: "0.8rem", marginLeft: "25px", marginBottom: "20px" }}>
              Industries impacted
            </div>
            <PieChart  values={sampleValues}
        labels={sampleLabels}
        hideLegend={false}
        legendPosition={isMobile ? 'bottom' : 'right'}
                responsiveLegendPosition="top"
                width={isWidth ? '400px' : '500px'}
                responsiveWidth="400px" />
        </div>
        </Col>

<Col xs="12" md="6">
{/* <div className="companyTag">Technology</div> */}
<CpcTable
        data={sampleData2}
        showHeading={true}
        heading={"Top technologies (click to view)"}
        showCount={true}
        count="Patent Count"
      />
</Col>
      </Row>

      <hr style={{width: "97%", marginLeft: "25px", background: "linear-gradient(to right, rgb(12 177 232), rgb(13 39 238))", height: "10px", border: "white", marginTop: "10px"}}/>

      <Row className='g-0'>
        <Col xs="12">
        <h1 style={{ fontSize: "1.3rem", marginLeft: "25px", marginTop: "0px" }}>
        Mitsuru Mochizuki technology timeline
              </h1>

              <div style={{ marginTop: "50px", marginBottom: "80px", padding: "20px", border: "1px solid #ddd", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginLeft: "50px", marginRight: "50px", height: "70%" }}>
      <h2 style={{ textAlign: "center",marginBottom: "20px" }}>Technological Advancements Timeline</h2>
      <p style={{ textAlign: "center", color: "#555", marginBottom: "20px" }}>Hover on the circles to see various technologies over the years.</p>
      {/* <Timeline pointers={samplePointers} query="patent" /> */}
    </div>

        </Col>
      </Row>

    </div>
  )
}

export default InventorPageImpact;