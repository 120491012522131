import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Table  } from "reactstrap";
import { Tracking } from "../../components/tracking/tracking";
import LineChart from "../../components/Charts/lineChart";
import "../../pages/forYouPage/forYou.css";
import TechnologyInsightTable from "../forYouStateArt/insightTable1";
import PieChart from "../../components/Charts/pieChart";
import SimpleMap from "../../components/WorldMap/WorldMap";
import { moveToCompanyPage, sanitizeText, toTitleCase, truncateText } from "../../actions/helper";
import InlineDisplay from "./floating";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import ISOCode from "../../actions/countryISOcodes.json";


const ForYouTechnologies = (props) => {
  const navigate = useNavigate();
  const technologySummary = props.technologySummary;

  const [selectedCountry, setSelectedCountry] = useState((props.technologySummary && props.technologySummary[0]) ? props.technologySummary[0].data.country_analysis.top_countries[0][0] : 'US');
  const [selectedCountryInfo, setSelectedCountryInfo] = useState();
  const analyticsAPI = "https://api.incubig.org/analytics/technology-analysis";

  const [selectedResearchArea, setSelectedResearchArea] = useState(0);

  useEffect(() => {
    if (props.researchAreas&&props.researchAreas[0]){
    filterByGeography("US",props.researchAreas[0].cpc);
    }
  }, [props.researchAreas]);


  const filterByGeography = (country,cpc=null) => {
    setSelectedCountryInfo(null);
    setSelectedCountry(country);
    axios
      .get(analyticsAPI, {
        headers: {
          "x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
        },
        params: { "cpc": cpc?cpc:technologySummary[selectedResearchArea].cpc, "country": country },
      })
      .then((response) => {
        setSelectedCountryInfo(response.data);
      });
  };

  return (
    <div>
      <Tracking
        title={"State of the art patents"}
        description={"State of the art patents"}
        keywords={"State of the art patents"}
      />

      <div className="userOverview">
        <div
          className="summaryTag"
          style={{
            width: "auto",
            backgroundColor: "white",
            color: "black",
            marginLeft: "0px",
            marginTop: "-15px",
          }}
        >
          <span
            className="summaryTagText"
            style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
          >
            Technology Insights
          </span>
        </div>

        <Row className="g-0">
          <Col xs="12" md="12">
            <TechnologyInsightTable data={technologySummary} />
          </Col>
        </Row>

        <hr className="thin-horizontal-line" />

        <div>
          <InlineDisplay
            technologySummary={technologySummary}
            selectedResearchArea={selectedResearchArea}
            setSelectedResearchArea={setSelectedResearchArea}
          />
        </div>
        <div
          className="summaryTag"
          style={{
            backgroundColor: "white",
            color: "black",
            marginTop: "10px",
            position: "relative",
          }}
        >
          <span
            className="summaryTagText"
            style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
          >
          Technology Trends
          </span>
        </div>

        <Row className="g-0">
          <Col xs="12" md="6" className="borderRight">
            {/* <p className="keyPoints">Key Trends</p>
            {technologySummary&&technologySummary[selectedResearchArea]
            ?
            <p
              className="para"
              style={{ fontWeight: "bold", marginTop: "20px" }}
            >
              Recently in the year {technologySummary[selectedResearchArea].bestYear.year}, {technologySummary[selectedResearchArea].bestYear.count}&nbsp;
              patents were filed.
            </p>
            :
            <Skeleton/>
            }
            {(technologySummary && technologySummary[selectedResearchArea])
              ?
              <div className="trendText" style={{ marginTop: "10px" }}>
                <ul className="keyPointsUL">
                  <li style={{ marginBottom: "10px" }}>
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {technologySummary[selectedResearchArea].grantRate}%
                      </strong>{" "}
                      of the patent applications filed are granted.
                    </div>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                      <div className="keyPointsLI">
                        <strong className="keyPointsLI"
                        style={{ color: "black" }}>
                          {technologySummary[selectedResearchArea].bestYear.year}
                        </strong>{" "}
                        observed a high research activity.
                      </div>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                      <div className="keyPointsLI">
                        <strong className="keyPointsLI"
                        style={{ color: "black" }}>
                          {technologySummary[selectedResearchArea].bestYear.count}
                        </strong>{" "}
                        patents were filed in{" "}
                        <strong  className="keyPointsLI"
                        style={{ color: "black" }}>
                          {technologySummary[selectedResearchArea].researchYear}
                        </strong>{" "}
                        year.
                      </div>
                  </li>
                </ul>
              </div>
              :
              <div className="customSkeletonContainer">
              <Skeleton count={3} className="customSkeleton"/>
              </div>
            } */}
            <div className="trendGraph">
              {technologySummary && technologySummary[selectedResearchArea] && (
                <PieChart
                  labels={["Granted", "Pending"]}
                  values={[parseFloat(technologySummary[selectedResearchArea].grantRate), 100 - parseFloat(technologySummary[selectedResearchArea].grantRate)]}
                  label="Patents status"
                  width="400px"
                  height="auto"
                />
              )}
            </div>
          </Col>
          <Col xs="12" md="6">
            {technologySummary && technologySummary[selectedResearchArea] && (
              <div className="trendsGraph1">
                <LineChart
                  labels={technologySummary[selectedResearchArea].data.application_trends.map((item) => item.year)}
                  series={technologySummary[selectedResearchArea].data.application_trends.map((item) => item.count)}
                  name={"Patents filed"}
                  title={"YoY patent filing trends"}
                />
              </div>
            )}
            {technologySummary&&technologySummary[selectedResearchArea] && (
              <div className="trendsGraph2">
                <LineChart
                  labels={technologySummary[selectedResearchArea].data.publication_trends.map((item) => item.year)}
                  series={technologySummary[selectedResearchArea].data.publication_trends.map((item) => item.count)}
                  name={"Patents published"}
                  title={"YoY patent publication trends"}
                  axisLabel={"Patents Published"}
                />
              </div>
            )}
          </Col>
        </Row>

        <hr className="thin-horizontal-line" />

        <Row className="g-0">
          <Col xs="12" md="6" className="borderRight">
            <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginTop: "0px"
              }}
            >
              <span className="summaryTagText" style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              Select a country on the map to view its corresponding data in the graph
              </span>
              
            </div>
            <div className="citationMap">
              <SimpleMap
                color="blue"
                backgroundColor="white"
                borderColor="grey"
                clickHandler={(e) => {
                  filterByGeography(e);
                }}
                data={
                  technologySummary&&technologySummary[selectedResearchArea]
                    ? technologySummary[selectedResearchArea].data.country_analysis.top_countries.map(
                        (item) => {
                          return [item.country, item.count];
                        }
                      )
                    : []
                }
              />
            </div>
          </Col>
          <Col xs="12" md="6">
            {/* <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginTop: "30px",
              }}
            >
              <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
                Top active organisations (
                {selectedCountryInfo
                  ? ISOCode[selectedCountry]
                  : "...loading..."}
                )
              </span>
            </div>

            <Table className="mainTable globalTable">
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{
                      fontWeight: "normal",
                      marginLeft: "-2%",
                      backgroundColor: "#020b26",
                      color: "white",
                    }}
                  >
                    Organization
                  </th>
                  <th
                    className="text-center"
                    style={{
                      fontWeight: "normal",
                      backgroundColor: "#020b26",
                      color: "white",
                    }}
                  >
                    Recent Patents
                  </th>
                </tr>
              </thead>
              {selectedCountryInfo
              ? (
                <tbody>
                  {selectedCountryInfo.assignee_analysis.top_assignees
                    .slice(0, 5)
                    .map((row, index) => (
                      <tr
                        className="rowTable"
                        key={index}
                        onClick={() => {
                          moveToCompanyPage(navigate, row.assignee);
                        }}
                      >
                        <td className="tableFirstColumn">
                          {sanitizeText(row.assignee)}
                        </td>
                        <td className="tableSecondColumn">{row.count}</td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody>
                  <tr className="rowTable">
                    <td className="tableFirstColumn">
                      <Skeleton width={200} />
                    </td>
                    <td className="tableSecondColumn">
                      <Skeleton width={150} />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table> */}
             <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginLeft: "20px",
                marginTop: "0px",
              }}
            >
              <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
                Selected Country:{" "}
                <b style={{ fontSize: "0.8rem" }}>
                  {selectedCountryInfo
                    ? ISOCode[selectedCountry]
                    : "...loading..."}
                </b>
              </span>
              
            </div>
            {selectedCountryInfo ? (
              <div
                className="trendText"
                style={{
                  marginTop: "30px",
                  height: "auto",
                  marginLeft: "25px",
                }}
              >
                {selectedCountryInfo.total_applications} patents are filed from{" "}
                {selectedCountry},{" "}
                {selectedCountryInfo.city_analysis &&
                selectedCountryInfo.city_analysis.top_cities &&
                selectedCountryInfo.city_analysis.top_cities.length > 0
                  ? toTitleCase(
                      selectedCountryInfo.city_analysis.top_cities[0].city
                    )
                  : "N/A"}{" "}
                is the top city, which filed maximum patent
                {selectedCountryInfo.city_analysis &&
                selectedCountryInfo.city_analysis.top_cities &&
                selectedCountryInfo.city_analysis.top_cities.length > 1
                  ? ", followed by " +
                    toTitleCase(
                      selectedCountryInfo.city_analysis.top_cities[1].city
                    )
                  : "."}
                .
              </div>
            ) : (
              <div className="trendText" style={{ marginTop: "30px" }}>
                <Skeleton />
              </div>
            )}
            {selectedCountryInfo ? (
              <div className="researchAreaChart">
                <LineChart
                  labels={
                    selectedCountryInfo.application_trends
                      ? selectedCountryInfo.application_trends.map(
                          (item) => item.year
                        )
                      : []
                  }
                  series={
                    selectedCountryInfo.application_trends
                      ? selectedCountryInfo.application_trends.map(
                          (item) => item.count
                        )
                      : []
                  }
                  name={"Patents filed"}
                  title={"YoY patent filing trends"}
                />
              </div>
            ) : (
              <Skeleton count={2} />
            )}
          </Col>
        </Row>

        {/* <hr className="thin-horizontal-line" /> */}
        <Row className="g-0" style={{ marginBottom: "50px" }}>
          <Col xs="12" md="6" className="borderRight">
            {/* <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginLeft: "20px",
                marginTop: "0px",
              }}
            >
              <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
                Selected Country:{" "}
                <b style={{ fontSize: "0.8rem" }}>
                  {selectedCountryInfo
                    ? ISOCode[selectedCountry]
                    : "...loading..."}
                </b>
              </span>
              
            </div>
            {selectedCountryInfo ? (
              <div
                className="trendText"
                style={{
                  marginTop: "30px",
                  height: "auto",
                  marginLeft: "25px",
                }}
              >
                {selectedCountryInfo.total_applications} patents are filed from{" "}
                {selectedCountry},{" "}
                {selectedCountryInfo.city_analysis &&
                selectedCountryInfo.city_analysis.top_cities &&
                selectedCountryInfo.city_analysis.top_cities.length > 0
                  ? toTitleCase(
                      selectedCountryInfo.city_analysis.top_cities[0].city
                    )
                  : "N/A"}{" "}
                is the top city, which filed maximum patent
                {selectedCountryInfo.city_analysis &&
                selectedCountryInfo.city_analysis.top_cities &&
                selectedCountryInfo.city_analysis.top_cities.length > 1
                  ? ", followed by " +
                    toTitleCase(
                      selectedCountryInfo.city_analysis.top_cities[1].city
                    )
                  : "."}
                .
              </div>
            ) : (
              <div className="trendText" style={{ marginTop: "30px" }}>
                <Skeleton />
              </div>
            )}
            {selectedCountryInfo ? (
              <div className="researchAreaChart">
                <LineChart
                  labels={
                    selectedCountryInfo.application_trends
                      ? selectedCountryInfo.application_trends.map(
                          (item) => item.year
                        )
                      : []
                  }
                  series={
                    selectedCountryInfo.application_trends
                      ? selectedCountryInfo.application_trends.map(
                          (item) => item.count
                        )
                      : []
                  }
                  name={"Patents filed"}
                  title={"YoY patent filing trends"}
                />
              </div>
            ) : (
              <Skeleton count={2} />
            )} */}
          </Col>
          <Col xs="12" md="6">
            {/* <div
              className="summaryTag"
              style={{
                width: "auto",
                backgroundColor: "white",
                color: "black",
                marginLeft: "20px",
                marginTop: "50px",
              }}
            >
              <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
                Top Locations
              </span>
            </div>
            {selectedCountryInfo ? (
              <ol>
                {selectedCountryInfo.city_analysis &&
                selectedCountryInfo.city_analysis.top_cities
                  ? selectedCountryInfo.city_analysis.top_cities.map(
                      (item, index) => (
                        <li key={index} className="country-list-item">
                          <p className="country-name">
                            {toTitleCase(item.city)}
                          </p>
                          <span className="patents-count">
                            {item.count} patents
                          </span>
                        </li>
                      )
                    )
                  : "N/A"}
              </ol>
            ) : (
              <Skeleton />
            )} */}
          </Col>
        </Row>

        <div style={{ textAlign: "center", position: "relative" }}>
          <hr className="belowForYou" />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "0.75rem",
              height: "20px",
              fontWeight: "bold",
            }}
          >
            Click & view
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "0.75rem",
              height: "40px",
            }}
          >
            System-generated, personalized technology insights.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForYouTechnologies;
