import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import {  useParams } from "react-router-dom";
import "./technologyPage.css";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader.js";
import { Footer } from "../../components/footer/footer.js";
// import { Tracking } from "../../components/tracking/tracking.js";
import TechnologyHeader from "../../sections/technologyPageHeader/technologyPageHeader.js";
import TechnologyOverview from "../../sections/technologyOverview/technologyOverview.js";
import TechnologyTrends from "../../sections/technologyTrends/technologyTrends.js";
import TechnologyGlobal from "../../sections/technologyGlobal/technologyGlobal.js";
import { saveUserPreference, toTitleCase, trackUsage } from "../../actions/helper";
import TechnologyOrganization from "../../sections/technologyOrganization/technologyOrganization";
import TechnologyDataset from "../../sections/technologyDataset/technologyDataset";


const TechnologyPage = () => {
  let params = useParams()
  const [selectedTechnology, setSelectedTechnology] = useState();
  const [technologyOverview, setTechnologyOverview] = useState();
  const [patentsInTechnology, setPatentsInTechnology] = useState();
  const [topYear, setTopYear] = useState();
  const [topYearCount, setTopYearCount] = useState();
  const [grantRate, setGrantRate] = useState();
  const [filterTag,setFilterTag] =useState();
  const [organizations, setOrganizations] = useState(4641)
  const [keyPlayers, setKeyPlayers] = useState([]);
  const [researchArea, setResearchArea] = useState();
  const [keyGeographies, setKeyGeographies] = useState([]);
  const [topCountryInfo, setTopCountryInfo] = useState();
  
  const searchURL =
    "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/xsearch";
  const cpcAPI =
    "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/cpc";
  const cpcDefinitionAPI = 
    "https://api.incubig.org/analytics/cpc-definition";
  const analyticsAPI = 
    "https://api.incubig.org/analytics/technology-analysis";


  useEffect(() => {
    if (params.cpc) {
      let cpc = decodeURIComponent(window.atob(params.cpc))
      getTechnologyOverview(cpc)
      axios.post(cpcDefinitionAPI,[cpc]).then((response)=>{
        setSelectedTechnology(response.data[0])
      })  
      trackUsage("technology",{technology:cpc},cpc)
      saveUserPreference([cpc])
    }
    else {
      axios
        .get(cpcAPI, { params: { query: "query optimization" } })
        .then((response) => {
          axios.post(cpcDefinitionAPI, response.data).then((resp) => {
            setSelectedTechnology(resp.data[0]);
          });
          getTechnologyOverview(response.data[0]);
        });
    }
  }, [params.cpc]);

  const getTechnologyOverview = (cpc) => {
    clearState()
    axios.get(analyticsAPI, {headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"}, params: { cpc: cpc } }).then((response) => {
      updateState(response.data)
    });
  }

  const updateState = (data) => {
    setTechnologyOverview(data);
    setPatentsInTechnology(data["total_applications"]);
    let tempTopYear = new Date().getFullYear();
    let tempTopYearCount = 0;
    data["application_trends"].forEach((item) => {
      if (item.count > tempTopYearCount) {
        tempTopYear = item.year;
        tempTopYearCount = item.count;
      }
    });
    setTopYear(tempTopYear);
    setTopYearCount(tempTopYearCount);
    data.status.forEach((item) => {
      if (Object.keys(item)[0] === "Active") {
        setGrantRate(
          (
            (item["Active"] * 100) /
            data["total_applications"]
          ).toFixed(1)
        );
      }
    });
  }

  const filterByGeography = (country) => {
    clearState()
    if (country){
      axios.get(analyticsAPI, {headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"}, params: { cpc: selectedTechnology.cpc, country: country } }).then((response) => {
        updateState(response.data)
      })
      setFilterTag(country)
    }
    else {
      axios.get(analyticsAPI, { params: { cpc: selectedTechnology.cpc} }).then((response) => {
        updateState(response.data)
      })
    }
    
  }

  const clearState = () => {
    setTechnologyOverview()
    setPatentsInTechnology()
    setTopYear()
    setTopYearCount()
    setGrantRate()
    setTopCountryInfo()
  }

  

  return (
    <div className="technologyPage">
      <Row className="g-0">
        <Col xs="12">
          <PersonalizedHeader />
        </Col>
      </Row>

      <TechnologyHeader selectedTechnology = {selectedTechnology} technologyOverview={technologyOverview}/>

      <Row className="g-0">
        <Col xs="12">
          <hr className="thin-horizontal-line" />
          <span
            className="headingSearch"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.75rem",
              background: "linear-gradient(to right, #1834dd, #d079df)",
              color: "white",
              height: "40px",
            }}
          >
            Technology Monitoring {(filterTag)?" ("+filterTag+")":""} &#124; {toTitleCase(window.location.pathname.split("/").at(-1))}
          </span>
        </Col>
      </Row>

      <hr
         className="thin-horizontal-line" 
      />
      {(window.location.pathname.split("/").at(-1)=="overview"|window.location.pathname.split("/").at(-1)=="technology-page")?
      <TechnologyOverview selectedTechnology={selectedTechnology} technologyOverview={technologyOverview} topYear={topYear} topYearCount={topYearCount} grantRate={grantRate}/>
      :
      <div></div>
      }
      
      {(window.location.pathname.split("/").at(-1)=="dataset"|window.location.pathname.split("/").at(-1)=="technology-page")?
      <TechnologyDataset 
        selectedTechnology={selectedTechnology}
        technologyOverview={technologyOverview} 
        filterFunction={filterByGeography}
        topYear={topYear}
        topYearCount={topYearCount}
        grantRate={grantRate}
      />
      :
      <div></div>
      }

      {(window.location.pathname.split("/").at(-1)=="trends"|window.location.pathname.split("/").at(-1)=="technology-page")?
      <TechnologyTrends 
            technologyOverview={technologyOverview} 
            grantRate={grantRate}
            keyPlayers={keyPlayers}
            researchArea={researchArea}
            topYear={topYear}
            topYearCount={topYearCount}
            grantPercent={grantRate}
      />
      :
      <div></div>
      }
    
      
      {(window.location.pathname.split("/").at(-1)=="global"|window.location.pathname.split("/").at(-1)=="technology-page")?
      <TechnologyGlobal selectedTechnology={selectedTechnology} technologyOverview={technologyOverview} topYear={topYear} topYearCount={topYearCount} grantRate={grantRate} keyPlayers={keyPlayers} keyGeographies={keyGeographies} topCountryInfo={topCountryInfo} />
      :
      <div></div>
      }

      {/* {(window.location.pathname.split("/").at(-1)=="organization"|window.location.pathname.split("/").at(-1)=="technology-page")?
      <TechnologyOrganization organizations = {organizations} />
      :
      <div></div>
      } */}

      <Row className="g-0">
        <Col xs="12">
          <div style={{height: "20px"}}>

          </div>
        </Col>
      </Row>

      <Row className="g-0">
        <Footer />
      </Row>
    </div>
  );
};

export default TechnologyPage;
