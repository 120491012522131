import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Popover } from "reactstrap";
import {
  base64Decode,
  moveToTechnologyPage,
  removeDuplicateTechnologies,
  toTitleCase,
} from "../../actions/helper";
import Skeleton from "react-loading-skeleton";
import "../../components/Tables/cpcTable.css"

export const StatusTable = (props) => {
  const [popoverContent, setPopoverContent] = useState({ title: "", body: "" });
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const sectionId = "TableRow";

  const sanitizeText = (str) => {
    if (typeof str === "string") {
      str = str.replace("_", " ");
      return str[0].toUpperCase() + str.slice(1, str.length).toLowerCase();
    } else {
      return str;
    }
  };

  const navigate = useNavigate();

  const handlePopoverClick = (e, item) => {
    setPopoverContent({ title: item[0], body: `${item[1]} related patents` });
    setPopoverTarget(e.target);
    setPopoverOpen(true);
  };

  const closePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <div className="reportCPC" style={{marginLeft: "1%"}}>
      {props.showHeading && (
        <div className="newTechnologyTableheading">
          {props.heading ? props.heading : "Patent Technologies"}
        </div>
      )}
      <Table className="mainTable">
        <thead>
          <tr>
            <th
              className="text-center"
              style={{
                fontWeight: "normal",
                marginLeft: "-1%",
                backgroundColor: "#020b26",
                color: "white",
              }}
            >
              Publication Number
            </th>

                <th className="text-center" style={{
                      fontWeight: "normal",
                      backgroundColor: "#020b26",
                      color: "white",
                      width: "25%"
                    }}
                 >
                    Title
                </th>

         
              <th
                className="text-center"
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#020b26",
                  color: "white",
                  textAlign: "end",
                  marginLeft: "10%"
                }}
              >
                Status
              </th>

              <th
                className="text-center"
                style={{
                  fontWeight: "normal",
                  backgroundColor: "#020b26",
                  color: "white",
                }}
              >
                Date
              </th>

          </tr>
        </thead>
        <tbody>
          
              <tr
                
                className="rowTable"
              >
                <td className="reportTableFirst" style={{width: "20%"}}>
                US20220083060A1
                </td>
                <td className="reportTableSecond" style={{width: "40%"}}>
                Autonomous driving vehicle
                </td>
                <td className="reportTableThird" style={{width: "10%"}}>
                Filed
                </td>
                <td className="reportTableFifth" stle={{width: "20%"}}>
                Nov 30th 2021
                </td>
              </tr>
           
        </tbody>
      </Table>
    </div>
  );
};
