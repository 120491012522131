import React, { useState, useRef } from "react";
import {
  Col,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaUpload, FaWpforms } from "react-icons/fa6";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import "../../../pages/forYouPage/forYou.css";
import Loader from "../../../components/loader/loader";
import { moveToOYI, moveToReportsPage, moveToResultsPage } from "../../../actions/helper";

const uploadIDFurl = "https://api.incubig.org/solutions/upload-idf";
const postIDFurl = "https://api.incubig.org/solutions/post-idf";


const OnlineForm = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [novelElement, setNovelElement] = useState("");
  const [description, setDescription] = useState("");
  const [features, setFeatures] = useState([""]);
  const [featureCount, setFeatureCount] = useState([1]);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  const extractIndustriesURL = "https://api.incubig.org/solutions/generate-facets"

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      
    if (!title) {
      setErrorMessage('*Please fill in Title');
      setLoading(false); 
      return;
    }

    if(!novelElement) {
      setErrorMessage('*Please fill in Novel element')
      setLoading(false); 
      return;
    }

    
    if (features.some(feature => !feature)) {
      setErrorMessage('*Please fill in at least one Invention feature');
      setLoading(false); 
      return;
    }


      const config = {
        headers: {
          "content-type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      let payload = {};
      if (selectedTags.length > 0) {
        payload = {
          email: localStorage.getItem("email"),
          title: title,
          filter_field: "industry",
          filter_value: selectedTags,
          novel_element: novelElement,
          description: description,
          features: features,
        };
      }
      else {
        payload = {
          email: localStorage.getItem("email"), 
          title: title,
          novel_element: novelElement,
          description: description,
          features: features,
        }
      }

      // Make the API call to submit the form data
      await axios.post(postIDFurl, payload, config).then((response) => {
        if (response.data.status_code === 200) {
          
          let idfID = response.data.data.id;
          localStorage.setItem(idfID, JSON.stringify(response.data.data));
          moveToReportsPage(navigate,idfID)
        }
        else {
          console.log(response.data);
          console.log("Error submitting form");
          setErrorMessage("Error submitting form");
        }
      });
    } catch (error) {
      console.error("Error submitting form", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTagClick = (tag) => {
    let tempTags = [...selectedTags];
    if (tempTags.includes(tag)) {
      tempTags = tempTags.filter((item) => item !== tag);
    } else {
      tempTags.push(tag);
    }
    
    setSelectedTags(tempTags);
  };

  const removeFeature = (index) => {
    if (featureCount.length > 1) {
      setFeatureCount((prevCount) => prevCount.filter((_, i) => i !== index));
      setFeatures((prevFeatures) => prevFeatures.filter((_, i) => i !== index));
    }
  };

  const getInput = (index, value) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = value;
    setFeatures(updatedFeatures);
  };

  const addFeature = () => {
    setFeatureCount((prevCount) => [...prevCount, prevCount.length]);
    setFeatures((prevFeatures) => [...prevFeatures, ""]);
  };


  const renderTags = () => {
    return tags.map((tag, index) => (
      <span
        key={index}
        className="tag"
        style={(selectedTags.includes(tag)) ? {backgroundColor: "#000", color: "#fff"}:{}}
        onClick={() => handleTagClick(tag)}
        title={tag}
      >
        {tag.length > 20 ? tag.substring(0, 20) + "..." : tag}
      </span>
    ));
  };

  const handleInputChange = (e) => {
    setTitle(e.target.value);
    if (e.target.value.length > 10){
      axios.get(extractIndustriesURL, {params: {query: e.target.value,field:"industry"}}).then((response) => {
        // console.log(response.data)
        setTags(response.data.data.slice(0,3).map((item) => item[0]))
        setShowTags(true)
      })
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Form
          style={{ width: "100%", marginTop: "50px", marginBottom: "20px" }}
          onSubmit={(e) => handleFormSubmit(e)}
        >
          <a
            href={require("../samples/Incubig_Sample IDF_BOMM_2023.pdf")}
            download="Incubig_Sample IDF_BOMM_2023.pdf"
          >
            Sample IDF
          </a>

          <FormGroup
            className="mt-4 d-flex align-items-center"
            style={{ marginBottom: "20px" }}
          >
            <Label
              for="title"
              style={{
                marginRight: "0px",
                fontWeight: "bold",
                marginBottom: "5px",
                width: "50%",
              }}
            >
              Title:{" "}
            </Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Enter your invention title"
              style={{
                backgroundColor: "#f5f5f5",
                color: "#000",
                fontSize: "0.875rem",
                marginBottom: "5px",
                width: "70%",
              }}
              value={title}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          {showTags && tags.length>0 && (
            <div>
              <h6 style={{ fontSize: "0.8rem", marginBottom: "8px" }}>
                Related Technologies
              </h6>
              <div className="tags-container">{renderTags()}</div>
            </div>
          )}

          <FormGroup
            className="mt-4 d-flex align-items-center"
            style={{ marginBottom: "20px" }}
          >
            <Label
              for="novelElement"
              style={{
                marginRight: "0px",
                fontWeight: "bold",
                marginBottom: "5px",
                width: "50%",
              }}
            >
              Novel Element:{" "}
            </Label>
            <Input
              type="text"
              name="novelElement"
              id="novelElement"
              placeholder="Enter the novel element of your invention"
              style={{
                backgroundColor: "#f5f5f5",
                color: "#000",
                fontSize: "0.875rem",
                marginBottom: "5px",
                width: "70%",
              }}
              value={novelElement}
              onChange={(e) => setNovelElement(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup
            className="mt-4 d-flex align-items-center"
            style={{ marginBottom: "20px" }}
          >
            <Label
              for="description"
              style={{
                marginRight: "0px",
                fontWeight: "bold",
                marginBottom: "5px",
                width: "50%",
              }}
            >
              Description:{" "}
            </Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              placeholder="Enter your invention description"
              style={{
                backgroundColor: "#f5f5f5",
                color: "#000",
                fontSize: "0.875rem",
                marginBottom: "5px",
                width: "70%",
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </FormGroup>

          {featureCount.map((count, index) => (
            <FormGroup
              key={index}
              className="mt-4 d-flex align-items-center"
              style={{ marginBottom: "20px" }}
            >
              <Label
                for={`feature-${count}`}
                style={{
                  marginRight: "0px",
                  fontWeight: "bold",
                  marginBottom: "5px",
                  width: "50%",
                }}
              >
                {index === 0 ? "Invention feature:" : ""}
              </Label>
              <Input
                type="text"
                name={`feature-${count}`}
                id={`feature-${count}`}
                placeholder={
                  index === 0
                    ? "Enter your invention feature"
                    : `Invention feature ${index + 1}`
                }
                value={features[index]}
                onChange={(e) => getInput(index, e.target.value)}
                required
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#000",
                  fontSize: "0.875rem",
                  marginBottom: "5px",
                  width: index === 0 ? "70%" : "65%",
                }}
              />
              {index !== 0 && (
                <RxCrossCircled
                  size={20}
                  onClick={() => removeFeature(index)}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
              )}
            </FormGroup>
          ))}
          <span
            onClick={addFeature}
            style={{ color: "blue", float: "right", cursor: "pointer" }}
          >
            + add more features
          </span>

          {errorMessage && <div className="errorMessage">{errorMessage}</div>}

          <Button
            type="submit"
            className="signupButton"
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </Form>
      )}
    </div>
  );
};

const OwnYourInvention = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [flag, setFlag] = useState(true);
  const fileInputRef = useRef();
  const [showOnlineForm, setShowOnlineForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  

  const handleOnlineFormClick = () => {
    setShowOnlineForm(true);
  };

  const handleUploadDocumentClick = (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("fileName", selectedFile.name);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      };
      axios.post(uploadIDFurl, formData, config).then((response) => {
        if (response.data.status_code === 200) {
          setFlag(false);
          console.log(response.data);
          // navigate("/for-you/report");
        } else {
          console.log(response.data);
        }
      });
    } else {
      setErrorMessage("Please select a file tfo upload");
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);

    if (selectedFile) {
      console.log("Selected file:", selectedFile);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container
          className="d-flex justify-content-center signupContainer"
          style={{ marginBottom: "30px" }}
        >
          <IoArrowBackCircleOutline
            size={25}
            style={{ marginTop: "10px", cursor: "pointer" }}
            onClick={goBack}
          />
          {flag ? (
            <div style={{ width: "95%" }}>
              <p
                className="para"
                style={{ marginLeft: "20px", marginTop: "15px" }}
              >
                {showOnlineForm
                  ? "Fill out our online form conveniently to provide the necessary details for your IDF."
                  : "Select your preferred method for novelty analysis."}
              </p>

              {!showOnlineForm && (
                <Form style={{ width: "100%", marginTop: "50px" }}>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  >
                    <Label
                      for=""
                      className="formLabel"
                    >
                      Fill online form:
                    </Label>
                    <Button
                      onClick={() => {
                        handleOnlineFormClick();
                      }}
                      className="signupDYR onlineFormButton"
                    >
                      <FaWpforms />
                      &nbsp;&nbsp;Online Form
                    </Button>
                  </FormGroup>

                  <h1
                    className="orText"
                    style={{ textAlign: "center", fontWeight: "bolder" }}
                  >
                    OR
                  </h1>
                  <FormGroup className="mt-4 d-flex flex-column align-items-center" style={{ marginBottom: "100px" }}>
                  <div className="uploadForm">
                  <Label for="email" className="formLabel">
                    Upload Document:
                  </Label>
                  
                  <Input
                      type="file"
                      ref={fileInputRef}
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#000",
                        fontSize: "0.875rem",
                        marginBottom: "5px",
                        width: "100%",
                      }}
                      onChange={handleFileChange}
                    />
                      <Button
                        onClick={handleUploadDocumentClick}
                        className="signupDYR uploadButton"
                      >
                        <FaUpload />
                        &nbsp;&nbsp;Upload Document
                      </Button>
                    </div>
                  </FormGroup>
<div className="signInWrap">
                  <a
                    href={require("../samples/Incubig_Sample IDF_BOMM_2023.pdf")}
                    download="Incubig_Sample IDF_BOMM_2023.pdf"
                  >
                    Sample IDF
                  </a>
                  <p style={{cursor:"pointer",textDecoration:"underline"}} className="idfLinks" onClick={()=>{moveToOYI(navigate,"idf")}}>View already submitted IDFs/Reports</p>
                  </div>
                  <FormGroup
                    className="mt-4 d-flex align-items-center"
                    style={{ marginBottom: "100px" }}
                  ></FormGroup>
                </Form>
              )}

              {showOnlineForm && <OnlineForm />}
            </div>
          ) : (
            <div
              className="afterSignUp"
              style={{ marginTop: "-10px", marginLeft: "10px" }}
            >
              <p className="mt-4">
                We have received your request and will get to you within two
                working days.{" "}
              </p>
              <Button
                className="signupDYR"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  moveToResultsPage(navigate, "Cold nuclear fusion");
                }}
              >
                Do Your Research
              </Button>
            </div>
          )}
        </Container>
      )}
      
    </div>
  );
};

export default OwnYourInvention;
