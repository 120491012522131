import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Table } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import SimpleMap from "../../components/WorldMap/WorldMap";
import {
  createFilters,
  moveToCompanyPage,
  removeDuplicates,
  sanitizeText,
  toTitleCase,
} from "../../actions/helper";
import { Carousel } from "../../components/carousel/carousel";
import axios from "axios";
import ISOCode from "../../actions/countryISOcodes.json";
import { Tracking } from "../../components/tracking/tracking";
import { useNavigate } from "react-router-dom";
import HorizontalBarChart from "../../components/horizontalBarChart/horizontalBarChart";
import LineChart from "../../components/Charts/lineChart";
import PieChart from "../../components/Charts/pieChart";
import LineChartTwo from "../../components/Charts/twoLineChart";

const Global = (props) => {
  const navigate = useNavigate();
  const [insights, setInsights] = useState();
  const [searchStatus, setSearchStatus] = useState(false);
  const [keyPlayers, setKeyPlayers] = useState([]);
  const [keyInventors, setKeyInventors] = useState("1000");
  const [keyGeographies, setKeyGeographies] = useState([]);
  const [topCountryInfo, setTopCountryInfo] = useState();
  const [selectedTechnology, setSelectedTechnology] = useState("All");

  const labels = [
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
  ];
  const series = [10, 15, 8, 12, 20, 25, 30, 28, 35];
  const grantPercent = 65;

  const technologies = [
    ["AI", "Artificial Intelligence"],
    ["ML", "Machine Learning"],
    ["Blockchain", "Blockchain Technology"],
    ["IoT", "Internet of Things"],
    ["Cloud", "Cloud Computing"],
  ];

  const sampleData3 = [
    ["US", 10],
    ["JP", 20],
    ["CN", 30],
    ["KR", 40],
  ];

   const sampleTopCities = [
    { city: "New York", count: 350 },
    { city: "San Francisco", count: 270 },
    { city: "Los Angeles", count: 220 },
    { city: "Chicago", count: 190 },
    { city: "Boston", count: 160 },
  ];

  const [datasets, setDatasets] = useState([
    {
      label: "Computational",
      data: [65, 59, 80, 81, 56, 55, 40],
      color: "rgba(75, 192, 192, 1)",
    },
    {
      label: "Communication",
      data: [28, 48, 40, 19, 86, 27, 90],
      color: "rgba(255, 99, 132, 1)",
    },
    {
      label: "Electronics",
      data: [45, 81, 30, 29, 66, 17, 33],
      color: "blue",
    },
  ]);

  const handleTechnologyChange = (event) => {
    const selected = event.target.value;
    setSelectedTechnology(selected);
    // Implement filtering logic based on selectedTechnology
  };

  const [selectedCountryInfo, setSelectedCountryInfo] = useState({
    city_analysis: {
      top_cities: sampleTopCities,
    },
  });

  useEffect(() => {
    setInsights(props.insights);
    setSearchStatus(props.searchStatus);
    setKeyPlayers(props.keyPlayers);
    setKeyInventors(props.keyInventors);
    setTopCountryInfo(props.topCountryInfo);
    setKeyGeographies(props.keyGeographies);
  }, [props]);

  const searchURL =
    "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/xsearch";

  const getTopCountryInfo = (country, maxCount = 5) => {
    setTopCountryInfo();
    axios
      .get(searchURL, {
        params: {
          query: props.query,
          filter: createFilters("assignee_country", country),
        },
      })
      .then((response) => {
        // response.data.facets.assignee.forEach((item)=>{

        // })
        setTopCountryInfo({
          country: country,
          assignees: removeDuplicates(
            response.data.facets.assignee.slice(0, maxCount)
          ),
          inventors: response.data.facets.inventors,
        });
      });
  };

  return (
    <div>
      <Tracking
        title={"Research overview for :" + props.query}
        description={"Patents in " + props.query}
        keywords={props.query}
      />
      {/* <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <div
              className="summaryTag"
              style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "15px", height: "auto"}}
            >
              <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
                Global activity summary
              </span>
          </div>
          {typeof insights != "undefined" && (
            <div style={{marginTop: "10px"}}>{insights}</div>
          )}
          
        </Col>

        <Col xs="12" md="6">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px"
            }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem"}}>Country Stats</span>
          </div>
          <div className="box-container">
            <div className="box" style={{ borderRight: "1px solid black" }}>
              <div className="widget-block">
                {typeof keyGeographies !== "undefined" ? (
                  <div className="value">
                    {keyGeographies.length}
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Countries
                </p>
              </div>
            </div>
            <div className="box" style={{ borderRight: "1px solid black" }}>
              <div className="widget-block">
                {typeof keyPlayers !== "undefined" ? (
                  <div
                    className="value"
                  >
                    {keyPlayers.length}
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Organizations
                </p>
              </div>
            </div>
            <div className="box">
              <div className="widget-block">
                {typeof keyInventors != "undefined" ? (
                  // <div className="value">{keyInventors}</div>
                  <div className="value">{keyInventors.length}</div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Inventors
                </p>
              </div>
            </div>
          </div>

          <div className="horizontalBarChart">
            <HorizontalBarChart keyGeographies={(keyGeographies&&keyGeographies.length>4)?keyGeographies.filter(item=>item[0]).slice(0,5):undefined} heading="Top five countries,based on number of organizations"/>
          </div>

        </Col>

        <Col xs="12" md="6" className="borderRight">
          <div
              className="summaryTag"
              style={{ width: "auto", backgroundColor: "white", color: "black", justifyContent: "center" }}
            >
              <span className="summaryTagText" style={{fontSize: "0.8rem"}}>
                Click country to see activity
              </span>
          </div>

          <div className="citationMap">
            <SimpleMap
              data={keyGeographies}
              color="blue"
              backgroundColor="white"
              borderColor="grey"
              clickHandler={getTopCountryInfo}
            />
          </div>
        </Col>
        
        <Col xs="12" md="6">
          <h1 className="textActivity">Activity in  {topCountryInfo
                  ? ISOCode[topCountryInfo.country]
                  : "...loading..."}</h1>
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "30px",
            }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem"}}>
              Top five active organisations
            </span>
          </div>

<Table className="mainTable globalTable">
        <thead>
          <tr>
            <th className="text-center" style={{fontWeight: "normal", marginLeft: "-2%", backgroundColor: "#020b26", color: "white"}}>Organization</th>


            <th className="text-center" style={{fontWeight: "normal", backgroundColor: "#020b26", color: "white"}}>
              Recent Patents</th>
          </tr>
        </thead>
        {typeof topCountryInfo != "undefined" ? (
        <tbody>
            {topCountryInfo.assignees.slice(0, 5).map((row, index) => (
              <tr className="rowTable"  key={index}
              onClick={() => {moveToCompanyPage(navigate,row[0])}}>
                <td className="tableFirstColumn">
                {sanitizeText(row[0])}
                </td>
                <td className="tableSecondColumn">
                {row[1]}
                </td>
              </tr>
            ))}
        </tbody>
          ) : (
            <tbody>
            <tr className="rowTable">
              <td className="tableFirstColumn">
                <Skeleton width={200} />
              </td>
              <td className="tableSecondColumn">
                <Skeleton width={150} />
              </td>
            </tr>
            </tbody>
          )}
      </Table>


        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "rgb(199, 198, 198)",
          color: "rgb(199, 198, 198)",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      />

      <Row className="g-0" style={{ height: "250px" }}>
        <Col md="12">
        <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black" }}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              See other active organizations
            </span>
          </div>
          <div className="cpcTable" style={{marginLeft: "0px", marginTop: "0px"}}>
            {searchStatus ? (
              <Carousel data={keyPlayers.slice(0, 10)} isMoving={true} />
            ) : (
              <Skeleton />
            )}
          </div>
        </Col>
      </Row> */}

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6" className="borderRight">
          <div
            className="sectionHeading2"
            style={{
              backgroundColor: "#FAF1EC",
              color: "black",
            }}
          >
            Trends Overview
          </div>
          <div
            className="para&country"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p
              className="para"
              style={{
                marginTop: "10px",
                fontSize: "1.3rem",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              In 2018 around 11000 patents were filed, 36.5% of the patents
              filed were granted.
            </p>
          </div>
          <ul className="keyPointsUL">
            <li>
              <div className="keyPointsLI2">
                Year <b>2023</b> observed highest patent filings.
              </div>
            </li>
            <li>
              <div className="keyPointsLI2">
                <b>2159</b> patents were filed in <b>2020</b> year
              </div>
            </li>
            <li>
              <div className="keyPointsLI2">
                <b>2159</b> patents were filed in <b>2020</b> year
              </div>
            </li>
          </ul>
        </Col>
        <Col xs="12" md="6">
          <div
            className="sectionHeading2"
            style={{
              backgroundColor: "#FAF1EC",
              color: "black",
            }}
          >
            Stats
          </div>

          <div className="box-container">
            <div
              className="box"
              style={{
                borderRight: "1px solid black",
                background: "transparent",
              }}
            >
              <div className="widget-block statistics-cards2">
                <div
                  className="value"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Germany
                </div>

                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Top Country
                </p>
              </div>
            </div>
            <div
              className="box"
              style={{
                borderRight: "1px solid black",
                background: "transparent",
              }}
            >
              <div className="widget-block statistics-cards2">
                <div
                  className="value"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  GlobalTech Inc.
                </div>

                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Leading Organisation
                </p>
              </div>
            </div>
            <div className="box" style={{ background: "transparent" }}>
              <div className="widget-block statistics-cards2">
                <div
                  className="value"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Jane Doe
                </div>
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Top Inventor
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "#5b5b5b",
          color: "black",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="12">
          <div className="sectionHeading2">Patent Trends</div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              How has research activity grown over the years?
            </h1>
          </div>

          <h2
            className="globalText"
            style={{
              fontSize: "0.8rem",
              marginTop: "10px",
              width: "70%",
              marginBottom: "20px",
            }}
          >
            In 2023, google filed 200 patent applications, which is 10% higher
            than the previous year.
          </h2>

          <div className="newDataGraphs">
            <div className="researchAreaChart">
              <div className="dropdownTechnology2" style={{}}>
                <label htmlFor="technologyDropdown" className="labelTechnology">
                  Select Technology:
                </label>
                {technologies.length > 0 && (
                  <select
                    id="technologyDropdown"
                    className="selectTechnology"
                    value={selectedTechnology}
                    onChange={handleTechnologyChange}
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      cursor: "pointer",
                      width: "100%",
                      maxWidth: "300px",
                      boxSizing: "border-box",
                    }}
                  >
                    <option value="All">All</option>
                    {technologies.map((tech, index) => (
                      <option key={index} value={tech}>
                        {tech}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <LineChart
                labels={labels}
                series={series}
                title="Patents Published"
                axisLabel="Number of Patents"
                name="Patents Filed"
              />
            </div>
            <div className="researchAreaChart" style={{ marginBottom: "30px" }}>
            <div className="dropdownTechnology2" style={{}}>
                <label htmlFor="technologyDropdown" className="labelTechnology">
                  Select Technology:
                </label>
                {technologies.length > 0 && (
                  <select
                    id="technologyDropdown"
                    className="selectTechnology"
                    value={selectedTechnology}
                    onChange={handleTechnologyChange}
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      cursor: "pointer",
                      width: "100%",
                      maxWidth: "300px",
                      boxSizing: "border-box",
                    }}
                  >
                    <option value="All">All</option>
                    {technologies.map((tech, index) => (
                      <option key={index} value={tech}>
                        {tech}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <LineChart
                labels={labels}
                series={series}
                title="Patents Filed"
                axisLabel="Number of Patents"
                name="Patents Filed"
              />
            </div>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "#5b5b5b",
          color: "black",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6" className="borderRight">
          <div className="sectionHeading2">Grant Data</div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              How has research activity grown over the years?
            </h1>
          </div>

          <h2
            className="globalText"
            style={{
              fontSize: "0.8rem",
              marginTop: "10px",
              width: "70%",
              marginBottom: "20px",
            }}
          >
            In 2023, google filed 200 patent applications, which is 10% higher
            than the previous year.
          </h2>

          <div className="trendGraph">
            <PieChart
              labels={["Granted", "Pending"]}
              values={[grantPercent, 100 - grantPercent]}
              label="Patents status"
              width="400px"
              height="auto"
            />
          </div>
        </Col>
        <Col xs="12" md="6">

        <div className="sectionHeading2">Technology Comparison</div>

<div
  style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  }}
>
  <h1
    style={{
      fontSize: "1.3rem",
      marginLeft: "25px",
      marginTop: "10px",
      width: "80%",
      fontWeight: "bolder",
      background:
        "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }}
  >
    How has research activity grown over the years?
  </h1>
</div>

<h2
  className="globalText"
  style={{
    fontSize: "0.8rem",
    marginTop: "10px",
    width: "70%",
    marginBottom: "20px",
  }}
>
  In 2023, google filed 200 patent applications, which is 10% higher
  than the previous year.
</h2>

        <div className="researchAreaChart" style={{ marginBottom: "30px" }}>
        <LineChartTwo labels={labels}
            datasets={datasets}
            title=""
            axisLabel="Number of patents"
            height={400}
            width={700}/>
            </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          backgroundColor: "#5b5b5b",
          color: "black",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

<Row className="g-0" style={{ background: "#FAF1EC" }}>
  <Col xs="12" md="6" className="borderRight">
  <div className="sectionHeading2">Geographical Activity</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What are the important geographies?
            </h1>
          </div>

          <h2 className="paraData">
            Mountainview is the leading location, accounting for 10% of all
            patent filings.
          </h2>

          <div className="citationMap">
            <SimpleMap
              data={sampleData3}
              color="blue"
              backgroundColor="#FAF1EC"
              borderColor="black"
              label="Click & explore"
            />
          </div>
  </Col>
  <Col xs="12" md="6">
  <div className="sectionHeading2">Top Locations</div>
          {selectedCountryInfo ? (
            <ol>
              {selectedCountryInfo.city_analysis.top_cities.map(
                (item, index) => (
                  <li key={index} className="country-list-item">
                    <p className="country-name">{toTitleCase(item.city)}</p>
                    <span className="patents-count">{item.count} patents</span>
                  </li>
                )
              )}
            </ol>
          ) : (
            <Skeleton count={5} />
          )}
  </Col>
</Row>

    </div>
  );
};

export default Global;
