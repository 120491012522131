import React from 'react';
import ApexCharts from 'react-apexcharts';

const LineChartTwo = ({ labels = [], datasets = [], title = 'Chart Title', axisLabel = 'Y-Axis', height = 300, width = 600 }) => {
  const series = datasets.map((dataset, index) => ({
    name: dataset.label || `Series ${index + 1}`,
    data: dataset.data || []
  }));

  // Define chart options
  const options = {
    chart: {
      id: 'line-chart',
      type: 'line',
      height,
      width,
    },
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '12px',
        fontWeight: 'lighter',
      },
    },
    xaxis: {
      categories: labels,
      title: {
        text: 'Year',
        style: {
          fontWeight: 'lighter',
        },
      },
      labels: {
        style: {
          colors: '#8e8da4',
        },
      },
    },
    yaxis: {
      title: {
        text: axisLabel,
        style: {
          fontWeight: 'lighter',
        },
      },
      labels: {
        formatter: (value) => Math.floor(value),
        style: {
          colors: '#8e8da4',
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      show: true,
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 3,
    },
  };

  return (
    <div style={{ height, width }}>
      <ApexCharts options={options} series={series} type="line" height={height} width={width} />
    </div>
  );
};

export default LineChartTwo;

