import React from 'react'
import { StatusTable } from '../../components/statusTable/statusTable';

const ForYouStatus = () => {
  return (
    <div>
        <div
        className="summaryTag"
        style={{
          width: "auto",
          backgroundColor: "white",
          color: "black",
          marginTop: "20px",
        }}
      >
        <span
          className="summaryTagText"
          style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
        >
          Status of your patents
        </span>
      </div>
      <StatusTable/>
    </div>
  )
}

export default ForYouStatus;