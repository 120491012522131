import React from "react";
import {Container, Row, Col} from 'reactstrap'
import  "./footer.css";

export const Footer = () => {
  return(

    <Container>
        <Row className="g-0">
          <Col xs="12">
          <div className="footer">
          <span className="footerLabel">Research intelligence by Incubig AI | <a href="https://incubig.org/" className="incubigHyper">incubig.org</a> &copy; {(new Date().getFullYear())}</span>
          </div>
          </Col>
        </Row>
    </Container>

  )
}

