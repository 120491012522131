import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Row,Col, Button, Container, Table} from 'reactstrap'
import {jwtDecode} from 'jwt-decode'
import Skeleton from 'react-loading-skeleton'
import { moveToForYouPage, moveToReportsPage, toTitleCase, trackUsage } from '../../actions/helper';
import { useNavigate } from 'react-router-dom'
import "./oyiIDF.css";
import { EditIDF } from './modules/editIDF'
import OwnYourInvention from '../forYouReport/forms/ownyourinvention'
import { Footer } from '../../components/footer/footer'

export default function OYIIDF(props) {
    const navigate = useNavigate()
    const getIDFurl = "https://api.incubig.org/solutions/idf"
    const [idfs,setIDFs] = useState()
    const [noIDFflag,setNoIDFflag] = useState(false)
    const [editPage,setEditPage] = useState(false)
    const [selectedIDF,setSelectedIDF] = useState()

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            let userEmail = jwtDecode(token).user.email;
            axios.get(getIDFurl, {
                headers: {
                    "auth-token": token
                },
                params:{
                    "email":userEmail
                }
            })
            .then((response) => {
                if(response.data.data.length == 0){
                    setNoIDFflag(true)
                }
                setIDFs(response.data.data)
            })
        }
    }, []);

    const editIDF = (idf) => {
        setSelectedIDF(idf)
        setEditPage(true)
    }

    const redirectToIDF = () => {
        navigate("/for-you/request-report/own-your-invention ");
    }

    return (
        <div>
        <div>
          <OwnYourInvention />
        </div>
  
        <hr className="thin-horizontal-line" />
  
        <div>
          <p className="fileMoreIDF">IDFs owned by you</p>
        </div>
  
        <Row className="g-0" style={{marginBottom: "20px"}}>
          {!editPage ? (
            <Col xs="12">
              {idfs ? (
                <div className="tableSection">
                  <Table striped className="tableOYIIDF">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Novel Element</th>
                        <th>Features</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {idfs.map((idf, IDFindex) => (
                        <tr key={IDFindex}>
                          <td>{toTitleCase(idf.title)}</td>
                          <td>{toTitleCase(idf.novel_element)}</td>
                          <td>
                            <ul>
                              {idf.features.map((feature, index) => (
                                <li key={index} className="tableFeatureli">
                                  {toTitleCase(feature)}
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>
                            <Button
                              className="tableButton"
                              onClick={() => moveToReportsPage(navigate, idf.id)}
                            >
                              View Report
                            </Button>
                            <Button
                              className="tableButton"
                              style={{ marginTop: "20px" }}
                              onClick={() => editIDF(idf)}
                            >
                              Edit IDF
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="idfSection">
                  <Skeleton />
                </div>
              )}
              {noIDFflag && (
                <div>
                  <h3>No IDFs found</h3>
                  <Button onClick={() => moveToForYouPage(navigate, "report")}>
                    Generate Novelty
                  </Button>
                </div>
              )}
            </Col>
          ) : (
            <Col xs="12">
              <div>
                <Container
                  className="d-flex justify-content-center signupContainer"
                  style={{ marginBottom: "30px" }}
                >
                  <EditIDF idf={selectedIDF} />
                </Container>
              </div>
            </Col>
          )}
        </Row>
  
  <Footer/>
  
      </div>
    );
  }