import React from "react";
import { Row, Col } from "reactstrap";
import { useNavigate} from "react-router-dom";
import { moveToAdminPage, moveToCompanyPage, toTitleCase } from "../../actions/helper";
import "../technologyPageHeader/technologyPageHeader.css";

const AdminPageHeader = (props) => {
  const navigate = useNavigate()

  return (
    <div>

    <hr className="mt-0"/>

      <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="6">
          <div className="companyHeader">
            <div>
                <div style={{ fontSize: "1.3rem", marginLeft: "25px" }}>
                Incubig Admin Console
                </div>
            </div>
            <h2 className="details-title">
            Central hub for efficient control and oversight.
            </h2>
            
          </div>
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="6" style={{display: "flex"}}>
        <div className="sectionContainer">
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "notifications"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToAdminPage(navigate,"notifications")}
            >
              Notifications
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "upload-indian-applications"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToAdminPage(navigate,"upload-indian-applications")}
            >
              Indian Applications
            </div>
            <div
              className={`sectionButton ${
                window.location.pathname.split("/").at(-1) == "tweet"
                  ? "bold Outline"
                  : ""
              }`}
              onClick={() => moveToAdminPage(navigate,"tweet")}
            >
              Tweet
            </div>
            
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPageHeader;
