import React, {useState} from "react";
import { Row, Col } from "reactstrap";
import { Tracking } from "../../components/tracking/tracking";
import { PatentTableFilters } from "../../components/patentTableFilters/patentTableFilters";
import PatentList from "../../components/patentsList/patentList";
import LineChart from "../../components/Charts/lineChart";
import PieChart from "../../components/Charts/pieChart";
import Skeleton from "react-loading-skeleton";
import { removeDuplicatePatents, toTitleCase } from "../../actions/helper";

const CompanyPageTrends = (props) => {
  const selectedCompany = props.selectedCompany;
  const assigneeOverview = props.assigneeOverview;
  const grantRate = props.grantRate;
  const topYear = props.topYear;
  const topYearCount = props.topYearCount;

  return (
    <div>
      <Tracking  title={props.selectedCompany + " 's patent and research trends"} description={"Patent and research trends of "+props.selectedCompany+" 's patent portfolio"} keywords={props.selectedCompany}/>

      <Row className="g-0">
        <Col
          xs="12"
          md="6"
          className="borderRight"
        >
          <p className="keyPoints">Key Trends</p>
          {topYear?
          <p className="para" style={{ fontWeight: "bold", marginTop: "20px" }}>
            Recently in the year {topYear}, {topYearCount}{" "}
            patents were filed.
          </p>
          :
          <p className="para" style={{ fontWeight: "bold", marginTop: "20px" }}>
            <Skeleton/>
          </p>
          }

          {grantRate && (
            <div className="trendText" style={{ marginTop: "10px" }}>
              <ul className="keyPointsUL">
                <li style={{ marginBottom: "10px" }}>
                  {typeof grantRate !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {grantRate}%
                      </strong>{" "}
                      of the patent applications filed are granted.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof topYear !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {topYear}
                      </strong>{" "}
                      observed a high research activity.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof topYearCount !== "undefined" ? (
                    <div className="keyPointsLI">
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {topYearCount}
                      </strong>{" "}
                      patents were filed in{" "}
                      <strong
                        className="keyPointsLI"
                        style={{ color: "black" }}
                      >
                        {topYear}
                      </strong>{" "}
                      year.
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
              </ul>
            </div>
          )}
           <div className="trendGraph" style={{justifyContent: "center", marginBottom: "30px", marginTop: "15%"}}>
          {grantRate?
            <PieChart labels={["Granted", "Pending"]} values={[parseInt(grantRate), 100 - parseInt(grantRate)]} label="Patents status"  width="400px"
            height="400px"/>
            :
            <Skeleton circle="true" />
          }
          </div>
          
        </Col>
        <Col xs="12" md="6">
        <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px"}}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              Trends
            </span>
          </div>
        <div className="researchAreaChart">
          {assigneeOverview?
              <LineChart
                labels={assigneeOverview.application_trends.map((item) => {
                  return item.year;
                })}
                series={assigneeOverview.application_trends.map((item) => {
                  return item.count;
                })}
                name={"Patents filed"}
                title={"YoY patent filing trends"}
              />
            :
            <Skeleton count={3}/>
          }
        </div>
        <div className="researchAreaChart" style={{ marginBottom: "30px"}}>
          {assigneeOverview?
              <LineChart
                labels={assigneeOverview.publication_trends.map((item) => {
                  return item.year;
                })}
                series={assigneeOverview.publication_trends.map((item) => {
                  return item.count;
                })}
                name={"Patents published"}
                title={"YoY patent publishing trends"}
                axisLabel={"Patents Published"}
              />
            :
            <Skeleton count={3}/>
          }
        </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyPageTrends;
