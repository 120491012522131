import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'reactstrap';
import "./goToSignin.css"

const GoToSignin = () => {

    const navigate = useNavigate();
    const moveToSignUp = () => {
        navigate("/sign-up");
      };

  return (
    <div>
        <p className="noLoginText">
            You need to be signed in to view this page
          </p>
          <Button className="personalize notLoggedSignin" onClick={moveToSignUp}>Sign In</Button>
    </div>
  )
}

export default GoToSignin