import React, {useEffect, useState} from 'react'
import {Row, Col} from 'reactstrap'
import { PersonalizedHeader } from '../../components/personalizedHeader/personalizedHeader'
import OYIPageHeader from '../../sections/oyiPageHeader/oyiPageHeader'
import { toTitleCase } from '../../actions/helper'
import OYIIDF from '../../sections/oyiIDF/oyiIDF'
import { useLocation } from 'react-router-dom'
import OYIReports from '../../sections/oyiReports/oyiReports'

export default function OYI(props) {
    const location = useLocation();

    const [selectedSection, setSelectedSection] = useState(window.location.pathname.split("/").at(-1));
    useEffect(() => {
        setSelectedSection(location.pathname.split("/").at(-1));
    }, [location.pathname]);

    return (
        <div>
            <Row className="g-0">
                <Col xs="12">
                    <PersonalizedHeader />
                </Col>
            </Row>

            {/* Page heading */}
            <OYIPageHeader/>
            
            {/* Page heading */}
            <Row className="g-0">
                <Col xs="12">
                <hr className="thin-horizontal-line" />
                <span
                    className="headingSearch"
                    style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "0.75rem",
                    background: "linear-gradient(to right, #1834dd, #d079df)",
                    color: "white",
                    height: "40px",
                    }}
                >
                    Own Your Invention | {toTitleCase(window.location.pathname.split("/").at(-1))} 
                </span>
                </Col>
            </Row>

            <hr className='thin-horizontal-line'/>

            {/* Page content */}
            {selectedSection === "idf"
            ?
            (
                <div>
                    <OYIIDF/>
                </div>
            )
            :
            (
                <div></div>
            )
            }

            {selectedSection === "reports"
            ?
            (
                <div>
                    <OYIReports/>
                </div>
            )
            :
            (
                <div></div>
            )
            }            
        </div>
    )
}