import axios from 'axios'
import React, { useState } from 'react'
import { RxCrossCircled } from 'react-icons/rx'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'

export const EditIDF = (props) => {
    const [title,setTitle] = useState(props.idf.title)
    const [tags,setTags] = useState(props.idf.tags)
    const [selectedTags,setSelectedTags] = useState(tags?tags:[])
    const [showTags,setShowTags] = useState((tags&&tags.length>0)?true:false)
    const [novelElement,setNovelElement] = useState(props.idf.novel_element)
    const [description,setDescription] = useState(props.idf.description)
    const [features,setFeatures] = useState(props.idf.features)
    const [featureCount,setFeatureCount] = useState(features.length>0?[...Array(features.length).keys()]:[0])
    const [errorMessage,setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const extractIndustriesURL = "https://api.incubig.org/solutions/generate-facets"
    const editIDFurl = "https://api.incubig.org/solutions/idf/"+props.idf.id

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {

        
        if (!title) {
        setErrorMessage('*Please fill in Title');
        setLoading(false); 
        return;
        }

        if(!novelElement) {
        setErrorMessage('*Please fill in Novel element')
        setLoading(false); 
        return;
        }

        
        if (features.some(feature => !feature)) {
        setErrorMessage('*Please fill in at least one Invention feature');
        setLoading(false); 
        return;
        }


        const config = {
            headers: {
            "content-type": "application/json",
            "auth-token": localStorage.getItem("token"),
            },
        };
        let payload = {};
        if (selectedTags.length > 0) {
            payload = {
            email: localStorage.getItem("email"),
            title: title,
            filter_field: "industry",
            filter_value: selectedTags,
            novel_element: novelElement,
            description: description,
            features: features,
            };
        }
        else {
            payload = {
            email: localStorage.getItem("email"), 
            title: title,
            novel_element: novelElement,
            description: description,
            features: features,
            }
        }

        // Make the API call to submit the form data
        await axios.put(editIDFurl, payload, config).then((response) => {
            if (response.data.status_code === 200) {
            
            let idfID = response.data.data.id;
            localStorage.setItem(idfID, JSON.stringify(response.data.data));
            window.location.reload();
            }
            else {
            console.log(response.data);
            console.log("Error submitting form");
            setErrorMessage("Error submitting form");
            }
        });
        } catch (error) {
        console.error("Error submitting form", error);
        } finally {
        setLoading(false);
        }
    };

    const handleTagClick = (tag) => {
        let tempTags = [...selectedTags];
        if (tempTags.includes(tag)) {
        tempTags = tempTags.filter((item) => item !== tag);
        } else {
        tempTags.push(tag);
        }
        
        setSelectedTags(tempTags);
    };

    const getFeatureInput = (index, value) => {
        const updatedFeatures = [...features];
        updatedFeatures[index] = value;
        setFeatures(updatedFeatures);
    };

    const removeFeature = (index) => {
        if (featureCount.length > 1) {
        setFeatureCount((prevCount) => prevCount.filter((_, i) => i !== index));
        setFeatures((prevFeatures) => prevFeatures.filter((_, i) => i !== index));
        }
    };
    
    const addFeature = () => {
        setFeatureCount((prevCount) => [...prevCount, prevCount.length]);
        setFeatures((prevFeatures) => [...prevFeatures, ""]);
    };

    const renderTags = () => {
        return tags.map((tag, index) => (
          <span
            key={index}
            className="tag"
            style={(selectedTags.includes(tag)) ? {backgroundColor: "#000", color: "#fff"}:{}}
            onClick={() => handleTagClick(tag)}
            title={tag}
          >
            {tag.length > 20 ? tag.substring(0, 20) + "..." : tag}
          </span>
        ));
      };

    return (
        <div style={{width: "95%"}}>
            <Form
                style={{ width: "100%", marginTop: "50px", marginBottom: "20px" }}
                onSubmit={(e) => handleFormSubmit(e)}
            >
            <a
                href={require("../../forYouReport/samples/Incubig_Sample IDF_BOMM_2023.pdf")}
                download="Incubig_Sample IDF_BOMM_2023.pdf"
            >
                Sample IDF
            </a>

            <FormGroup
                className="mt-4 d-flex align-items-center"
                style={{ marginBottom: "20px" }}
            >
                <Label
                for="title"
                style={{
                    marginRight: "0px",
                    fontWeight: "bold",
                    marginBottom: "5px",
                    width: "50%",
                }}
                >
                Title:{" "}
                </Label>
                <Input
                type="text"
                name="title"
                id="title"
                placeholder="Enter your invention title"
                style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    fontSize: "0.875rem",
                    marginBottom: "5px",
                    width: "70%",
                }}
                value={title}
                onChange={(e)=>{setTitle(e.target.value)}}
                required
                />
            </FormGroup>
            {showTags && tags.length>0 && (
                <div>
                <h6 style={{ fontSize: "0.8rem", marginBottom: "8px" }}>
                    Related Technologies
                </h6>
                <div className="tags-container">{renderTags()}</div>
                </div>
            )}

            <FormGroup
                className="mt-4 d-flex align-items-center"
                style={{ marginBottom: "20px" }}
            >
                <Label
                for="novelElement"
                style={{
                    marginRight: "0px",
                    fontWeight: "bold",
                    marginBottom: "5px",
                    width: "50%",
                }}
                >
                Novel Element:{" "}
                </Label>
                <Input
                type="text"
                name="novelElement"
                id="novelElement"
                placeholder="Enter the novel element of your invention"
                style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    fontSize: "0.875rem",
                    marginBottom: "5px",
                    width: "70%",
                }}
                value={novelElement}
                onChange={(e) => setNovelElement(e.target.value)}
                required
                />
            </FormGroup>
            <FormGroup
                className="mt-4 d-flex align-items-center"
                style={{ marginBottom: "20px" }}
            >
                <Label
                for="description"
                style={{
                    marginRight: "0px",
                    fontWeight: "bold",
                    marginBottom: "5px",
                    width: "50%",
                }}
                >
                Description:{" "}
                </Label>
                <Input
                type="textarea"
                name="description"
                id="description"
                placeholder="Enter your invention description"
                style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    fontSize: "0.875rem",
                    marginBottom: "5px",
                    width: "70%",
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                />
            </FormGroup>

            {featureCount.map((count, index) => (
                <FormGroup
                key={index}
                className="mt-4 d-flex align-items-center"
                style={{ marginBottom: "20px" }}
                >
                <Label
                    for={`feature-${count}`}
                    style={{
                    marginRight: "0px",
                    fontWeight: "bold",
                    marginBottom: "5px",
                    width: "50%",
                    }}
                >
                    {index === 0 ? "Invention feature:" : ""}
                </Label>
                <Input
                    type="text"
                    name={`feature-${count}`}
                    id={`feature-${count}`}
                    placeholder={
                    index === 0
                        ? "Enter your invention feature"
                        : `Invention feature ${index + 1}`
                    }
                    value={features[index]}
                    onChange={(e) => getFeatureInput(index, e.target.value)}
                    required
                    style={{
                    backgroundColor: "#f5f5f5",
                    color: "#000",
                    fontSize: "0.875rem",
                    marginBottom: "5px",
                    width: index === 0 ? "70%" : "65%",
                    }}
                />
                {index !== 0 && (
                    <RxCrossCircled
                    size={20}
                    onClick={() => removeFeature(index)}
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                )}
                </FormGroup>
            ))}
            <span
                onClick={addFeature}
                style={{ color: "blue", float: "right", cursor: "pointer" }}
            >
                + add more features
            </span>

            {errorMessage && <div className="errorMessage">{errorMessage}</div>}

            <Button
                type="submit"
                className="signupButton"
                onClick={handleFormSubmit}
            >
                Submit
            </Button>
            </Form>
        </div>
    )
}