import React from 'react';
import { Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Tracking } from '../../components/tracking/tracking';
import "../../pages/inventorPage/inventorPage.css"
import { toTitleCase } from '../../actions/helper';

const InventorPageAbout = (props) => {
    const inventorText = props.inventorDescription;
  return (
    <div>
        <Tracking  title={props.selectedInventor + " 's patent and research portfolio"}
        description={
          "Analyze the key strength and weakness of " +
          props.selectedInventor +
          " 's patent portfolio"
        }
        keywords={props.selectedInventor}
      />

<Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <div className="companyTag">About Inventor</div>
          {inventorText ? (
            <h2
              className="globalText"
              style={{ fontSize: "0.8rem", marginTop: "20px" }}
            >
              {inventorText}
              {/* <hr
        className="belowCompany"
      /> */}
            </h2>
            
            ) : (
              <div
              className="globalText"
              style={{ fontSize: "0.8rem", marginTop: "20px" }}
              >
              <Skeleton count={2} />
            </div>
          )}

<p className="keyPoints">Details</p>
<ul className="keyPointsUL">
  <li style={{ marginBottom: "10px" }}>
    <div className="keyPointsLI">
    <strong className="keyPointsLI" style={{ color: "black" }}>Country: </strong>Tokyo, Japan
    </div>
  </li>
  <li>
  <div className="keyPointsLI">
    <strong className="keyPointsLI" style={{ color: "black" }}>Organisation: </strong>Mitsubishi
    </div>
  </li>
</ul>

<hr style={{width: "80%", marginLeft: "25px", background: "linear-gradient(to right, rgb(12 177 232), rgb(13 39 238))", height: "10px", border: "white", marginTop: "25px"}}/>

<h1 style={{ fontSize: "1.3rem", marginLeft: "25px", marginBottom: "10px" }}>
Mitsuru Mochizuki Research Focus
              </h1>

              <div className="container">
  <div className="keyPointsSection">
    <p className="keyPoints" style={{marginLeft: "15px"}}>Industry</p>
    <ul className="keyPointsUL" style={{marginLeft: "30px"}}>
      <li style={{ marginBottom: "10px" }}>
        <div className="keyPointsLI">Communication System</div>
      </li>
      <li style={{ marginBottom: "10px" }}>
        <div className="keyPointsLI">Computer Peripherals</div>
      </li>
      <li>
        <div className="keyPointsLI">Electrical Devices</div>
      </li>
    </ul>
  </div>
  
  <div className="keyPointsSection">
    <p className="keyPoints" style={{marginLeft: "15px"}}>Technology</p>
    <ul className="keyPointsUL" style={{marginLeft: "30px"}}>
      <li style={{ marginBottom: "10px" }}>
        <div className="keyPointsLI">Pictorial communication, e.g. television</div>
      </li>
      <li style={{ marginBottom: "10px" }}>
        <div className="keyPointsLI">Electrography</div>
      </li>
      <li>
        <div className="keyPointsLI">Electric digital data processing</div>
      </li>
    </ul>
  </div>
</div>




</Col>
<Col xs="12" md="6">
<div className="companyTag">Publications</div>
  <div className="statistics-cards">
    <div className="stat-card">
      <p>245</p>
      <h2>Total Patents</h2>
    </div>
    <div className="stat-card">
      <p>10</p>
      <h2>Active Patents</h2>
    </div>
    <div className="stat-card">
      <p>30</p>
      <h2>Pending Patents</h2>
    </div>
  </div>
  <div className="statistics-cards">
  <div className="stat-card">
      <p>2019</p>
      <h2>Most active year</h2>
    </div>
    <div className="stat-card">
      <p>10</p>
      <h2>Industries Impacted</h2>
    </div>
    <div className="stat-card">
      <p>30</p>
      <h2>Technologies Developed</h2>
    </div>
  </div>
</Col>
          </Row>

    </div>
  )
}

export default InventorPageAbout;