import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";
import { Tracking } from "../../components/tracking/tracking";
import { CpcTable } from "../../components/Tables/cpcTable";
import { toTitleCase } from "../../actions/helper";
import PieChart from "../../components/Charts/pieChart";

const CompanyPageExplore = (props) => {
  const technologies = props.technologies;
  const assigneeOverview = props.assigneeOverview;
  const isMobile = window.innerWidth <= 768;
    const isWidth = window.innerWidth <= 768;
  return (
    <div>
      <Tracking
        title={
          props.selectedCompany +
          " 's patent and research portfolio and competitors"
        }
        description={
          "Competitors of " + props.selectedCompany + " 's patent portfolio"
        }
        keywords={props.selectedCompany}
      />
      <Row className="g-0">
        <Col xs="12" md="6" className="borderRight">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              View Technologies
            </span>
          </div>
          {technologies && technologies.length > 1 ? (
            <p
              className="globalText"
              style={{ marginTop: "20px", fontSize: "1rem" }}
            >
              <span style={{ fontWeight: "bold" }}>
                {toTitleCase(props.selectedCompany)}
              </span>{" "}
              is primarily researching on{" "}
              {toTitleCase(technologies[0].definition)}, followed by{" "}
              {toTitleCase(technologies[1].definition)}.
            </p>
          ) : (
            <Skeleton />
          )}
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ fontSize: "0.8rem", marginLeft: "25px", marginBottom: "20px" }}>
            Organization's technology distribution
            </div>
            {assigneeOverview ? (
              <PieChart className="specific-chart"
                labels={assigneeOverview.top_industries.map((item) => item.industry)}
                values={assigneeOverview.top_industries.map((item) => item.count)}
                hideLegend={false}
                legendPosition={isMobile ? 'bottom' : 'right'}
                responsiveLegendPosition="top"
                width={isWidth ? '400px' : '600px'}
                responsiveWidth="400px"
              />
            ) : (
              <Skeleton circle={true} width={"50%"} height={200} />
            )}
          </div>
        </Col>
        <Col xs="12" md="6" style={{ paddingRight: "5%" }}>
          <CpcTable data={technologies} showCount={true} showHeading={true} heading={"Top technologies (click to view)"} />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyPageExplore;
