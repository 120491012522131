import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ISOCode from '../../actions/countryISOcodes.json'

const HorizontalBarChart = (props) => {
  const heading = props.heading
  const keyGeographies = props.keyGeographies

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      horizontalAlign: 'left',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '30%',
        distributed: true, 
        roundedBars: true,
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    colors: ['#5C92AC', '#336F8C', '#0B4C6C', '#00334D', '#001A2E'],
    xaxis: {
      categories: (keyGeographies)?keyGeographies.map((item)=>{return ISOCode[item[0]]}):[],
    },
    yaxis: {
      title: {
        text: 'Country',
      },
    },
  };

  const chartSeries = [
    {
      name: 'Organizations',
      data: (keyGeographies)?keyGeographies.map((item)=>{return item[1]}):[],
    },
  ];

  return (
    <div className="horizontal-bar-chart">
      {(keyGeographies)&&
      <>
        <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={350} />
        <div className="chart-heading">
          <h2>{heading}</h2>
        </div>
        
      </>
      }
    </div>
  );
};

export default HorizontalBarChart;
