import React from 'react'
import { useState } from 'react'
import { toTitleCase } from '../../actions/helper'

export const InventorFilter = (props) => {
    const inventors = props.inventors?[{inventor:"All inventors"}].concat(props.inventors):[{inventor:"All inventors"}]
    const [selectedInventor,setSelectedInventor] = useState(props.inventors&&props.inventors.length===1?props.inventors[0].inventor:"All inventors")

    const changeSelectedInventor = (e) => {
        setSelectedInventor(e.target.value)
        if (e.target.value==="All inventors"){
            props.filterFunction(null)
        }
        else{
            props.filterFunction(e.target.value)
        }
    }


    return(
        <div className="d-flex justify-content-between patentsHeader">
            <div
                className="headline-3 d-flex "
                style={{
                fontSize: "0.7rem",
                marginLeft: "25px",
                marginTop: "20px",
                }}
            >
                Click to read patents
            </div>
            <div className="patentAction">
                <div>
                <select value={selectedInventor} onChange={(e)=>{changeSelectedInventor(e)}} style={{fontSize: "0.7rem", height: "26px", marginRight: "10px"}}>
                {inventors.map((inventor,index) => (
                    <option key={index} value={inventor.inventor} style={{fontSize: "0.7rem"}}>
                    {toTitleCase(inventor.inventor)}
                    </option>
                ))}
                </select>
                </div>
                
            </div>
        </div>
    )
}