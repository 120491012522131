import base64 from "react-native-base64";
import axios from "axios";

const trackingAPI = "https://api.incubig.org/track/usage";
const XapiKey = "b3e5b7e5-3b2d-4d3b-8b9a-5f8a9b3e2d1c";

export const toTitleCase = (txt) => {
  if (typeof txt == "string") {
    if (txt.length == 2) {
      return txt.toUpperCase();
    } else {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  } else {
    return txt;
  }
};

export const sanitizeText = (str) => {
  if (typeof str === "string") {
    str = str.replace("_", " ");
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  } else {
    return str;
  }
};

export const moveToResultsPage = (
  navigate,
  query,
  section = "technology",
  cache = false
) => {
  localStorage.setItem("query", query);
  navigate("/results/" + section + "?q=" + query, {
    state: {
      query: query,
      cache: cache,
    },
  });
};

export const moveToTechnologyPage = (
  navigate,
  section = "overview",
  cpc = []
) => {
  if (cpc.length > 0) {
    let encodedCPC = encodeURIComponent(window.btoa(cpc[0].cpc));
    localStorage.setItem("technology", encodedCPC);
    navigate("/technology-page/" + encodedCPC + "/" + section);
  } else {
    if (localStorage.getItem("technology")) {
      let encodedCPC = localStorage.getItem("technology");
      navigate("/technology-page/" + encodedCPC + "/" + section);
    } else {
      let cpc = "B64C 29/00";
      let encodedCPC = encodeURIComponent(window.btoa(cpc));
      navigate("/technology-page/" + encodedCPC + "/" + section);
    }
  }
};

export const base64Decode = (str) => {
  let base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (base64regex.test(str)) {
    str = base64.decode(str, "base64");
    str = str.substring(0, str.length - 1);
  }
  return str;
};

export const moveToReportsPage = (navigate, id) => {
  navigate("/reports/" + id);
};

export const moveToPatentPage = (
  navigate,
  publication_number,
  section = null
) => {
  let indianPublicationNumberPattern = /^\d{4}\/\d{2}\/.*/;
  let usPatentPattern = /^\d{11}$/;

  if (usPatentPattern.test(publication_number)) {
    console.log("US", publication_number);
  } else if (indianPublicationNumberPattern.test(publication_number)) {
    console.log("IN", publication_number);
  } else if (publication_number.startsWith("RU")) {
    console.log("RU", publication_number);
  } else {
    let padding = parseInt(publication_number.at(-1));
    publication_number =
      publication_number.substr(0, publication_number.length - 1) +
      "=".repeat(padding);
    try {
      publication_number = base64.decode(publication_number);
    } catch (error) {
      console.log(error);
      publication_number = publication_number;
    }
  }

  if (publication_number.startsWith("US")) {
    publication_number = publication_number.substr(
      2,
      publication_number.length - 4
    );
  }

  if (section) {
    let url = `/patent/${section}/${encodeURIComponent(publication_number)}`;
    navigate(url, {
      state: {
        publicationNumber: publication_number,
      },
    });
  } else {
    let url = `/patent/${encodeURIComponent(publication_number)}`;
    navigate(url, {
      state: {
        publicationNumber: publication_number,
      },
    });
  }
};

export const moveToOYIPage = (navigate, section) => {
  navigate("/own-your-invention/" + section);
};

export const truncateText = (text, maxLength) => {
  if (typeof text != "undefined" && text != null) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  } else {
    return "";
  }
};

export const createFilters = (filter, value) => {
  if (filter && value) {
    if (filter === "assignee") {
      return "(assignee/any(p:p eq '" + value + "'))";
    } else if (filter === "inventor") {
      return "(inventor/any(p:p eq '" + value + "'))";
    } else if (filter === "application_year") {
      return "(application_year eq " + value + ")";
    } else if (filter === "country") {
      return "(assignee_country eq '" + value + "')";
    } else {
      return "(" + filter + " eq '" + value + "')";
    }
  } else {
    return null;
  }
};

export const getParamsFromFilter = (filter) => {
  if (filter !== null) {
    return filter.split("'")[1];
  } else {
    return "";
  }
};

export const removeDuplicates = (inputArray) => {
  let outputArray = [];
  if (inputArray.constructor === Array) {
    inputArray.forEach((childArray) => {
      let stringVal = childArray[0].toLowerCase();
      let numberVal = childArray[1];

      let index = outputArray.findIndex(
        (item) => item[0].toLowerCase() === stringVal
      );

      if (index !== -1) {
        outputArray[index][1] += numberVal;
      } else {
        outputArray.push([stringVal, numberVal]);
      }
    });
  } else {
    return inputArray;
  }
  return outputArray;
};

export const removeDuplicatePatents = (inputArray) => {
  if (inputArray && inputArray.constructor === Array) {
    let uniqueTitles = [];
    let filteredArray = [];
    inputArray.forEach((item) => {
      if (!uniqueTitles.includes(item.title.toLowerCase())) {
        filteredArray.push(item);
        uniqueTitles.push(item.title.toLowerCase());
      }
    });
    // console.log(filteredArray.length,inputArray.length)
    return filteredArray;
  } else {
    return inputArray;
  }
};

export const removeDuplicateTechnologies = (inputArray) => {
  if (inputArray && inputArray.constructor === Array) {
    let uniqueDefinition = [];
    let filteredArray = [];
    inputArray.forEach((item) => {
      if (
        item.definition != null &&
        !uniqueDefinition.includes(item.definition.toLowerCase())
      ) {
        filteredArray.push(item);
        uniqueDefinition.push(item.definition.toLowerCase());
      }
    });
    // console.log(filteredArray.length,inputArray.length)
    return filteredArray;
  } else {
    return inputArray;
  }
};

export const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const moveToCompanyPage = (
  navigate,
  companyName,
  section = "overview"
) => {
  if (companyName) {
    companyName = encodeURIComponent(companyName);
    localStorage.setItem("assignee", companyName);
    navigate("/company-page/" + companyName + "/" + section);
  } else {
    companyName = localStorage.getItem("assignee");
    if (companyName === null) {
      companyName = "Mitsubishi";
    }
    companyName = encodeURIComponent(companyName);
    navigate("/company-page/" + companyName + "/" + section);
  }
};

export const moveToInventorPage = (
  navigate,
  inventorName,
  section = "overview"
) => {
  if (inventorName) {
    inventorName = encodeURIComponent(inventorName);
    localStorage.setItem("inventor", inventorName);
    navigate("/inventor-page/" + inventorName + "/" + section);
  } else {
    inventorName = localStorage.getItem("inventor");
    if (inventorName === null) {
      inventorName = "Mitsuru Mochizuki";
    }
    inventorName = encodeURIComponent(inventorName);
    navigate("/inventor-page/" + inventorName + "/" + section);
  }
};

export const moveToForYouPage = (navigate, section = "state-of-the-art") => {
  navigate("/for-you/" + section);
};

export const saveUserPreference = (cpcs) => {
  let tempUserPreference = localStorage.getItem("userPreference");
  if (tempUserPreference !== null) {
    tempUserPreference = JSON.parse(tempUserPreference);
    cpcs.map((cpc) => {
      tempUserPreference[cpc] = tempUserPreference[cpc] + 1 || 1;
    });
    localStorage.setItem("userPreference", JSON.stringify(tempUserPreference));
  } else {
    tempUserPreference = {};
    cpcs.map((cpc) => {
      tempUserPreference[cpc] = tempUserPreference[cpc] + 1 || 1;
    });
    localStorage.setItem("userPreference", JSON.stringify(tempUserPreference));
  }
};

export const moveToSignInPage = (navigate, page) => {
  // console.log(page)
  navigate("/" + page);
};

export const moveToAdminPage = (navigate, page) => {
  navigate("/admin/" + page);
};

export const trackUsage = (page, tags, cpc) => {
  let payload = {
    page: page,
    tags: tags,
    cpc: cpc,
  };
  let token = localStorage.getItem("token");
  if (token) {
    let headers = {
      "auth-token": token,
      "x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
    };
    // axios.post(trackingAPI, payload, { headers: headers });
  }
};

export const decodePatentNumber = (patentNumber) => {
  let base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (base64regex.test(patentNumber)) {
    // If last number is digit, replace it with padding character "=" as many times as digit
    let padding = parseInt(patentNumber.at(-1));
    patentNumber =
      patentNumber.substr(0, patentNumber.length - 1) + "=".repeat(padding);
    patentNumber = base64.decode(patentNumber, "base64");
  } else {
    console.log("Not base64");
  }
  return patentNumber;
};

export const moveToOYI = (navigate,section) => {
  navigate("/own-your-invention/"+section);
}

export const logOutUser = () =>{
  localStorage.removeItem("user");
  localStorage.removeItem("email");
  localStorage.removeItem("token");
  window.location.reload();
}