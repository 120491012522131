
import React, { useEffect, useRef, useState } from 'react';
import './carousel.css';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { moveToCompanyPage, moveToTechnologyPage } from '../../actions/helper';
import { useNavigate } from 'react-router-dom';

export const Carousel = (props) => {
  const navigate = useNavigate()
  const data = props.data;
  const isMoving = props.isMoving;
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  // const [popoverContent, setPopoverContent] = useState({ title: '', body: '' });
  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const [popoverTarget, setPopoverTarget] = useState(null);

  useEffect(() => {
    const container = containerRef.current;

    const startScroll = () => {
      animationRef.current = requestAnimationFrame(scroll);
    };

    const stopScroll = () => {
      cancelAnimationFrame(animationRef.current);
    };

    const scroll = () => {
      if (container.scrollLeft <= 0) {
        container.scrollTo({ left: container.scrollWidth, behavior: 'auto' });
      } else {
        container.scrollLeft -= 1.5;
      }

      animationRef.current = requestAnimationFrame(scroll);
    };

    if (isMoving) {
      startScroll();
      container.addEventListener('mouseenter', stopScroll);
      container.addEventListener('mouseleave', startScroll);
    } else {
      stopScroll();
      container.removeEventListener('mouseenter', stopScroll);
      container.removeEventListener('mouseleave', startScroll);
    }

    return () => {
      cancelAnimationFrame(animationRef.current);
      container.removeEventListener('mouseenter', stopScroll);
      container.removeEventListener('mouseleave', startScroll);
    };
  }, [isMoving]);

  const sanitizeText = (str) => {
    if (typeof str === 'string') {
      str = str.replace('_', ' ');
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return str;
    }
  };

  const clickHandler = (e) => {
    if (props.type === 'technology') {
      moveToTechnologyPage(navigate, "overview",[e]);
    } else {
      moveToCompanyPage(navigate, e[0]);
    }

  }

  return (
    <div>
      <div className="col-12 col-md-6 patentData" style={{ width: '100%' }}>
        <section className="Widget_widget__16nWC" style={{ height: '250px' }}>
          <div className="widget-p-md" style={{paddingTop: "0px"}}>
            {(props.heading)&&
            <div
              className="heading"
              style={{
                width: '35%',
                padding: '9px',
                height: '30px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'left',
              }}
            >
              <span style={{ marginLeft: '0px' }}>{props.heading}</span>
            </div>
            }
            <div className="scrollable-container" ref={containerRef}>
  <div className="columns-container">
    {data &&
      data.length > 0 &&
      data.map((item, index) => (
        <div
          key={index}
          className="mt-4 Dashboard_widgetBlock__Myojl carouselCard"
        >
          <div
            className="Dashboard_widgetBody__3yEc5 carouselData"
            style={{ width: '300px', height: '80px' }}
            onClick={() => {
              clickHandler(item);
            }}
          >
            <div className="d-flex">
              <div className="d-flex flex-column">
                <p className="body-2">
                  {props.type === "technology" ? item.definition : sanitizeText(item[0])}
                </p>
                <p className="body-3 muted">
                  {props.type === "technology" ? item.count : item[1]} recent patents
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
</div>

          </div>
        </section>
      </div> 
    </div>
  );
};

