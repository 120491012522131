import React, { useState } from 'react';
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { Footer } from '../../components/footer/footer';
import InventorPageHeader from '../../sections/inventorPageHeader/inventorPageHeader';
import InventorPageOverview from '../../sections/inventorPageAbout/inventorPageAbout';
import InventorPageData from '../../sections/inventorPageActivity/inventorPageActivity';
import InventorPageTrends from '../../sections/inventorPageImpact/inventorPageImpact';
import InventorPageExplore from '../../sections/inventorPageNetwork/inventorPageNetwork';
import { toTitleCase } from '../../actions/helper';
import { PersonalizedHeader } from '../../components/personalizedHeader/personalizedHeader';
import InventorPageAbout from '../../sections/inventorPageAbout/inventorPageAbout';
import InventorPageActivity from '../../sections/inventorPageActivity/inventorPageActivity';
import InventorPageImpact from '../../sections/inventorPageImpact/inventorPageImpact';
import InventorPageNetwork from '../../sections/inventorPageNetwork/inventorPageNetwork';

const InventorPage = () => {
    const params = useParams()
    const [selectedInventor, setSelectedInventor] = useState(decodeURIComponent(params.inventor))
    const [inventorInfo, SetInventorInfo] = useState()
    const [inventorDescription, setInventorDescription] = useState("Mitsuru mochizuki, a Japanese technology expert, was a key contributor at Mitsubishi specializing in wireless communication networks. His work was most impactful in 2019.")
  return (
    <div className="companyPage">
        {/* <Row className="g-0">
            <Col xs="12">
            <PersonalizedHeader/>
            </Col>
        </Row> */}

        <InventorPageHeader selectedInventor={selectedInventor} inventorInfo = {inventorInfo} />

        <Row className="g-0">
        <Col xs="12">
          <hr className="thin-horizontal-line" />
          <span
            className="headingSearch"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.75rem",
              background: "linear-gradient(to right, rgb(132 202 225), rgb(80 96 222))",
              color: "white",
              height: "40px",
            }}
          >
            Inventor Page | {toTitleCase(window.location.pathname.split("/").at(-1))} 
          </span>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
      />

{(window.location.pathname.split("/").at(-1) == "about") |
      (window.location.pathname.split("/").at(-1) == "inventor-page") ? (
        <InventorPageAbout selectedInventor ={selectedInventor}
        inventorDescription={inventorDescription} 
        />
      ) : (
        <div></div>
      )}

{(window.location.pathname.split("/").at(-1) == "activity") |
      (window.location.pathname.split("/").at(-1) == "inventor-page") ? (
        <InventorPageActivity
        />
      ) : (
        <div></div>
      )}

{(window.location.pathname.split("/").at(-1) == "impact") |
      (window.location.pathname.split("/").at(-1) == "inventor-page") ? (
        <InventorPageImpact
        />
      ) : (
        <div></div>
      )}

      {(window.location.pathname.split("/").at(-1)=="network"|window.location.pathname.split("/").at(-1)=="inventor-page")? (
        <InventorPageNetwork
        />
  ) : (
    <div></div>
  )}

<Row className="g-0">
        <Col xs="12">
          <div style={{height: "20px"}}>

          </div>
        </Col>
      </Row>

    <Row className="g-0">
      <Footer/>
    </Row>

    </div>
  )
}

export default InventorPage;