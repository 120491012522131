import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Table } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import PatentList from "../../components/patentsList/patentList";
import Grid from "../../components/Grid/grid";
import { createFilters, sanitizeText } from "../../actions/helper";
import ISOCode from "../../actions/countryISOcodes.json";
import { Tracking } from "../../components/tracking/tracking";
import { useNavigate } from "react-router-dom";
import { CpcTable } from "../../components/Tables/cpcTable";
import FullCpcTable from "../../components/Tables/fullCpcTable";
import { Carousel } from "../../components/carousel/carousel";
import { CompanyCarousel } from "../../components/carousel/companyCarousel";
import FullCompanyTable from "../../components/Tables/fullCompanyTable";
import SimpleMap from "../../components/WorldMap/WorldMap";
import LineChart from "../../components/Charts/lineChart";

const sampleData3 = [
  {
    name: "Electric digital data processing",
    industryImpacting: "Computational Technology",
    highestActivity: "2019",
    topInventor: "John Doe",
    patentsPublished: 120,
  },
  {
    name: "Wireless communication networks",
    industryImpacting: "Communication Technique",
    highestActivity: "2020",
    topInventor: "Jane Smith",
    patentsPublished: 85,
  },

  {
    name: "Transmission of digital information",
    industryImpacting: "Electronic Instruments",
    highestActivity: "2022",
    topInventor: "Jane Smith",
    patentsPublished: 65,
  },

  {
    name: "Control or regulating systems in general",
    industryImpacting: "Communication Technique",
    highestActivity: "2017",
    topInventor: "Jane Smith",
    patentsPublished: 30,
  },

  {
    name: "Multiplex communication",
    industryImpacting: "Communication Technique",
    highestActivity: "2021",
    topInventor: "Jane Smith",
    patentsPublished: 50,
  },
];

const DataSet = (props) => {
  // const searchStatus = props.searchStatus
  const keyApplications = props.keyApplications;
  const keyGeographies = props.keyGeographies
    ? [["All countries"]].concat(props.keyGeographies)
    : ["All countries"];
  const [selectedSortingLogic, setSelectedSortingLogic] = useState("Relevant");
  const [selectedCountry, setSelectedCountry] = useState(
    props.keyGeographies && props.keyGeographies.length === 1
      ? props.keyGeographies[0][0]
      : "All countries"
  );
  const [selectedCity, setSelectedCity] = useState(
    props.keyCities && props.keyCities.length === 1
      ? props.keyCities[0][0]
      : "All cities"
  );
  const keyCities = props.keyCities
    ? [["All cities"]].concat(props.keyCities)
    : ["All cities"];
  const [isMoving, setIsMoving] = useState(true);
  const handleClick = (countryCode, countryValue) => {
    console.log(`Country: ${countryCode}, Value: ${countryValue}`);
  };

  const gridData = {
    gridHeading: "",
    upperGrid: [
      { caption: "Countries", value: "150" },
      { caption: "Companies", value: "45" },
      { caption: "Inventors", value: "600" },
    ],
    lowerGrid: [
      { caption: "Technologies", value: "234" },
      { caption: "Industries", value: "10" },
      { caption: "Patents", value: "56553" },
    ],
    topPlayer: "",
  };

  const sampleData = [
    {
      companyName: "InnovateTech Inc.",
      country: "USA",
      industry1: "Artificial Intelligence",
      industry2: "Cloud Computing",
      technologiesCount: 25,
      topInventor: "Dr. Alice Johnson",
    },
    {
      companyName: "EcoSolutions Ltd.",
      country: "UK",
      industry1: "Renewable Energy",
      industry2: "Sustainable Materials",
      technologiesCount: 18,
      topInventor: "Mr. Liam Brown",
    },
    {
      companyName: "BioHealth Corp.",
      country: "Germany",
      industry1: "Biotechnology",
      industry2: "Pharmaceuticals",
      technologiesCount: 32,
      topInventor: "Dr. Emma Müller",
    },
    {
      companyName: "FinTech Dynamics",
      country: "Canada",
      industry1: "Financial Technology",
      industry2: "Blockchain",
      technologiesCount: 15,
      topInventor: "Mr. Noah Williams",
    },
    {
      companyName: "AutoDrive Solutions",
      country: "South Korea",
      industry1: "Automotive",
      industry2: "Autonomous Vehicles",
      technologiesCount: 22,
      topInventor: "Ms. Yuna Kim",
    },
    {
      companyName: "GreenEarth Innovations",
      country: "Australia",
      industry1: "Environmental Technology",
      industry2: "Waste Management",
      technologiesCount: 20,
      topInventor: "Dr. Olivia Smith",
    },
    {
      companyName: "Quantum Leap Labs",
      country: "Japan",
      industry1: "Quantum Computing",
      industry2: "Advanced Materials",
      technologiesCount: 28,
      topInventor: "Mr. Hiroshi Tanaka",
    },
  ];

  const sampleDataTable = [
    {
      name: "Tech Innovators Inc.",
      country: "USA",
      industryFocus: ["Technology", "Artificial Intelligence"],
      technologiesDeveloped: 120,
      topInventor: "Jane Doe",
    },
    {
      name: "Global Green Energy",
      country: "Germany",
      industryFocus: ["Energy", "Sustainability"],
      technologiesDeveloped: 85,
      topInventor: "Hans Schmidt",
    },
    {
      name: "FutureTech Solutions",
      country: "Japan",
      industryFocus: ["Robotics", "Automation"],
      technologiesDeveloped: 95,
      topInventor: "Akira Yamamoto",
    },
    {
      name: "BioHealth Innovations",
      country: "UK",
      industryFocus: ["Healthcare", "Biotechnology"],
      technologiesDeveloped: 110,
      topInventor: "Emma Wilson",
    },
    {
      name: "SpaceTech Ventures",
      country: "USA",
      industryFocus: ["Aerospace", "Satellite Technology"],
      technologiesDeveloped: 75,
      topInventor: "John Smith",
    },
  ];

  const samplePatentData = [
    {
      publication_number: "US123456A1",
      title: "Method for Producing Renewable Energy",
      assignee: ["GreenTech Innovations"],
      inventor: ["John Doe"],
      industry: "Energy",
      application_year: 2019,
      assignee_country: "USA",
      abstract:
        "A method for producing renewable energy using a new type of wind turbine designed to capture low-speed winds.",
      country: "USA",
      application_date: "2019-08-15",
    },
    {
      publication_number: "US789012B2",
      title: "Wearable Device for Health Monitoring",
      assignee: ["HealthTech Corp"],
      inventor: ["Jane Smith"],
      industry: "Healthcare",
      application_year: 2020,
      assignee_country: "UK",
      abstract:
        "A wearable device designed to monitor vital signs and detect anomalies in real-time, providing alerts to medical professionals.",
      country: "UK",
      application_date: "2020-06-10",
    },
    {
      publication_number: "US345678C3",
      title: "Artificial Intelligence System for Traffic Management",
      assignee: ["SmartCity AI"],
      inventor: ["Alan Turing"],
      industry: "Technology",
      application_year: 2021,
      assignee_country: "Germany",
      abstract:
        "An AI-based traffic management system that optimizes traffic flow in real-time to reduce congestion and improve travel times.",
      country: "Germany",
      application_date: "2021-03-12",
    },
    {
      publication_number: "US901234D4",
      title: "Advanced Materials for Battery Storage",
      assignee: ["Future Energy Materials"],
      inventor: ["Marie Curie"],
      industry: "Materials Science",
      application_year: 2022,
      assignee_country: "France",
      abstract:
        "A new class of materials for use in batteries that offer increased storage capacity and faster charging times.",
      country: "France",
      application_date: "2022-01-25",
    },
  ];

  const sampleData4 = [
    {
      country: "United States",
      activeOrganizations: "5",
      inventors: "12",
      patents: "150",
    },
    {
      country: "Germany",
      activeOrganizations: "3",
      inventors: "8",
      patents: "85",
    },
    {
      country: "Japan",
      activeOrganizations: "7",
      inventors: "20",
      patents: "210",
    },
    {
      country: "Russia",
      activeOrganizations: "4",
      inventors: "15",
      patents: "175",
    },
    {
      country: "China",
      activeOrganizations: "6",
      inventors: "10",
      patents: "130",
    },
  ];

  const companies = [
    ["Apple", "Apple Inc."],
    ["Google", "Google LLC"],
    ["Microsoft", "Microsoft Corporation"],
    ["Amazon", "Amazon.com, Inc."],
    ["Tesla", "Tesla, Inc."],
  ];

  const technologies = [
    ["AI", "Artificial Intelligence"],
    ["ML", "Machine Learning"],
    ["Blockchain", "Blockchain Technology"],
    ["IoT", "Internet of Things"],
    ["Cloud", "Cloud Computing"],
  ];

  const labels = ["2019", "2020", "2021", "2022", "2023"];
  const series = [15, 30, 25, 40, 50];

  const [selectedCompany, setSelectedCompany] = useState("Apple");
  const [selectedTechnology, setSelectedTechnology] = useState("AI");

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleTechnologyChange = (event) => {
    setSelectedTechnology(event.target.value);
  };

  const loadMoreData = () => {
    const nextPage = currentPage + 1;
    const newData = sampleData3.slice(0, nextPage * itemsPerPage);
    setDisplayedData(newData);
    setCurrentPage(nextPage);
  };

  const handleRowClick = (item) => {
    console.log("Row clicked:", item);
  };

  const handleButtonClick = (option) => {};

  const handleSortingLogicChange = (e) => {
    // console.log(e.target.value)
    setSelectedCountry("All countries");
    if (e.target.value === "Relevant") {
      props.searchFunction(props.query, null, false);
    } else {
      props.searchFunction(props.query, null, true);
    }
    setSelectedSortingLogic(e.target.value);
  };

  const handleCountryChange = (event) => {
    // console.log(window.location.search.split("?q=")[1])
    setSelectedSortingLogic("Relevant");

    let filter = null;
    if (event.target.value === "All countries") {
      props.searchFunction(
        decodeURIComponent(window.location.search).split("?q=")[1],
        filter
      );
    } else {
      filter = createFilters("country", event.target.value);
      props.searchFunction(
        decodeURIComponent(window.location.search).split("?q=")[1],
        filter
      );
    }
    setSelectedCountry(event.target.value);
  };

  return (
    <div>
      <Tracking
        title={"Research overview for :" + props.query}
        description={"Patents in " + props.query}
        keywords={props.query}
      />
      {/* <Row className="g-0">
        <Col xs={{ span: 12, order: 1 }} md="8" className="borderRight">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Patent data
            </span>
          </div>
          <div
            className="para&country"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {keyApplications ? (
              <p className="para" style={{ marginTop: "10px" }}>
                In{" "}
                {keyApplications[0]["application_date"].toString().slice(0, 4)},{" "}
                {keyApplications[0].assignee[0]
                  ? sanitizeText(keyApplications[0].assignee[0])
                  : sanitizeText(keyApplications[0].inventor[0])}{" "}
                filed a patent on {sanitizeText(keyApplications[0].title)}.
              </p>
            ) : (
              <p className="para" style={{ marginTop: "10px" }}>
                <Skeleton />
              </p>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <div
              className="headline-3 d-flex "
              style={{
                fontSize: "0.7rem",
                marginLeft: "25px",
                marginTop: "30px",
              }}
            >
              Click to read patents
            </div>

            <div className="patentAction">
              <div onClick={handleButtonClick}>
                <select
                  value={selectedSortingLogic}
                  onChange={handleSortingLogicChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {["Relevant", "Recent"].map((sortingLogic) => (
                    <option
                      key={sortingLogic}
                      value={sortingLogic}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {sortingLogic}
                    </option>
                  ))}
                </select>
              </div>
              <div onClick={() => handleButtonClick("Country")}>
                <select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {keyGeographies.map((country) => (
                    <option
                      key={country[0]}
                      value={country[0]}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {country[0] !== "All countries"
                        ? ISOCode[country[0]]
                        : country[0]}
                    </option>
                  ))}
                </select>
              </div>
              {props.keyCities && (
                <div onClick={() => handleButtonClick("Country")}>
                  <select
                    value={selectedCity}
                    onChange={handleCountryChange}
                    style={{
                      fontSize: "0.7rem",
                      height: "26px",
                      marginRight: "10px",
                    }}
                  >
                    {keyCities.map((city) => (
                      <option
                        key={city[0]}
                        value={city[0]}
                        style={{ fontSize: "0.7rem" }}
                      >
                        {city[0] !== "All cities" ? city[0] : city[0]}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          <PatentList
            keyApplications={keyApplications}
            downloadOption={{
              show: true,
              params: { query: localStorage.getItem("query") },
            }}
          />
        </Col>
        <Col xs={{ span: 12, order: 2 }} md="4">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Query Summary{" "}
              {selectedCountry != "All countries"
                ? " in " + ISOCode[selectedCountry] + " "
                : ""}{" "}
            </span>
          </div>
          <div className="forStats">
            <p>Summary of recent 1000 patents</p>
            <Grid
              gridHeading={
                "Summary" +
                (selectedCountry != "All countries"
                  ? " (" + ISOCode[selectedCountry] + ")"
                  : "")
              }
              upperGrid={[
                {
                  value:
                    props.keyGeographies && props.keyGeographies.length > 0
                      ? props.keyGeographies.length
                      : undefined,
                  caption: "Countries working",
                },
                {
                  value:
                    props.keyPlayers && props.keyPlayers.length > 0
                      ? props.keyPlayers.length
                      : undefined,
                  caption: "Active organizations",
                },
                {
                  value:
                    props.keyInventors && props.keyInventors.length > 0
                      ? props.keyInventors.length
                      : undefined,
                  caption: "Active inventors",
                },
              ]}
              lowerGrid={[
                {
                  value:
                    props.keyGeographies && props.keyGeographies.length > 0
                      ? props.keyGeographies[0][0]
                      : undefined,
                  caption: "Leading country",
                },
                {
                  value: props.topYearContribution,
                  caption: "Patents in " + props.topYear,
                },
                { value: props.grantPercent, caption: "Patents Granted" },
              ]}
              topPlayer={
                props.keyPlayers && props.keyPlayers.length > 0
                  ? props.keyPlayers[0][0]
                  : undefined
              }
            />
          </div>
        </Col>
      </Row> */}

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6" className="borderRight noBorderOnMobile">
          <div
            className="sectionHeading2"
            style={{
              backgroundColor: "#FAF1EC",
              color: "black",
            }}
          >
            Query Overview
          </div>
          <div
            className="para&country"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p
              className="para"
              style={{
                marginTop: "10px",
                fontSize: "1.3rem",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Personal monitoring apparatus is the most developing technology in
              your query.
            </p>
          </div>
          <ul className="keyPointsUL">
            <li>
              <div className="keyPointsLI2">
                Year <b>2023</b> observed highest patent filings.
              </div>
            </li>
            <li>
              <div className="keyPointsLI2">
                <b>2159</b> patents were filed in <b>2020</b> year
              </div>
            </li>
            <li>
              <div className="keyPointsLI2">
                <b>2159</b> patents were filed in <b>2020</b> year
              </div>
            </li>
          </ul>
        </Col>
        <Col xs="12" md="6">
          <div className="box-container">
            <div
              className="box"
              style={{
                borderRight: "1px solid black",
                background: "transparent",
              }}
            >
              <div className="widget-block statistics-cards2">
                <div
                  className="value"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Germany
                </div>

                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Top Country
                </p>
              </div>
            </div>
            <div
              className="box"
              style={{
                borderRight: "1px solid black",
                background: "transparent",
              }}
            >
              <div className="widget-block statistics-cards2">
                <div
                  className="value"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  GlobalTech Inc.
                </div>

                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Leading Organisation
                </p>
              </div>
            </div>
            <div className="box" style={{ background: "transparent" }}>
              <div className="widget-block statistics-cards2">
                <div
                  className="value"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Jane Doe
                </div>
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Top Inventor
                </p>
              </div>
            </div>
          </div>

          <div
            className="sectionHeading2"
            style={{
              backgroundColor: "#FAF1EC",
              color: "black",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            Statistics
          </div>

          <div className="forStats">
            <Grid
              gridHeading={gridData.gridHeading}
              upperGrid={gridData.upperGrid}
              lowerGrid={gridData.lowerGrid}
              topPlayer={gridData.topPlayer}
            />
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          background:
            "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
          height: "2px",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0">
        <Col xs="12" md="12" style={{ background: "#FAF1EC" }}>
          <div
            className="sectionHeading2"
            style={{
              backgroundColor: "#FAF1EC",
              color: "black",
            }}
          >
            Explore Technologies
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Found 100 technologies, Digital computing is the top technology.
            </h1>
          </div>

          <h2 className="paraData">
            Dive into a world of innovation and discovery as you explore the
            vast landscape of cutting-edge technologies. From the latest
            advancements in digital computing to groundbreaking breakthroughs in
            renewable energy, this comprehension overview invites you to uncover
            the boundless potential that lies within.
          </h2>

          <div
            className="sectionHeading2"
            style={{
              backgroundColor: "#FAF1EC",
              color: "black",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            Click and explore technologies relevant to you.
          </div>
          {/* <hr
          style={{
            width: "20%",
            marginLeft: "40%",
            background:
              "linear-gradient(to right, rgb(16 16 16), rgb(13 39 238))",
            height: "10px",
            border: "none",
            marginTop: "10px",
            marginBottom: "0px",
          }}
        /> */}

          <FullCpcTable
            data={sampleData3}
            showHeading={false}
            showDefaultHeading={false}
            heading=""
            showCount={true}
            count="Patent Count"
          />
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          background:
            "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
          height: "2px",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6" className="borderRight noBorderOnMobile">
          <div className="sectionHeading2">Trends & Data</div>

          <h2
            className="globalText"
            style={{
              fontSize: "1.3rem",
              marginTop: "10px",
              width: "80%",
              marginBottom: "0px",
              fontWeight: "bolder",
              background: "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Instruction prefetching is Google's leading technology focus.
          </h2>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 className="paraData">
              Companies leading technological development
            </h1>
          </div>

          <div className="newDataGraphs">
            <div className="researchAreaChart">
              <LineChart
                labels={labels}
                series={series}
                title="Patents Published"
                axisLabel="Number of Patents"
                name="Patents Filed"
              />
            </div>
          </div>
        </Col>
        <Col xs="12" md="6">
          {/* <div className="sectionHeading2">Patents</div> */}
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                fontSize: "1.3rem",
                marginLeft: "25px",
                marginTop: "10px",
                width: "80%",
                fontWeight: "bolder",
                background:
                  "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Google is developing 200+ technologies, with recent focus on
              instruction perfetching, packet discarding & relaying, and
              artificial neural networks.
            </h1>
          </div> */}

          <div className="d-flex justify-content-between patentsHeader">
            <div
              className="headline-3 d-flex"
              style={{
                fontSize: "0.7rem",
                marginLeft: "25px",
                marginTop: "30px",
              }}
            >
              Click to read patents
            </div>

            <div className="patentAction">
              {/* Dropdown for selecting company */}
              <div onClick={handleButtonClick}>
                <select
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {companies.map((company) => (
                    <option
                      key={company[0]}
                      value={company[0]}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {company[1]}
                    </option>
                  ))}
                </select>
              </div>

              {/* Dropdown for selecting technology */}
              <div onClick={handleButtonClick}>
                <select
                  value={selectedTechnology}
                  onChange={handleTechnologyChange}
                  style={{
                    fontSize: "0.7rem",
                    height: "26px",
                    marginRight: "10px",
                  }}
                >
                  {technologies.map((technology) => (
                    <option
                      key={technology[0]}
                      value={technology[0]}
                      style={{ fontSize: "0.7rem" }}
                    >
                      {technology[1]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <PatentList
            keyApplications={samplePatentData}
            downloadOption={{ show: true, params: { assignee: "All Patents" } }}
          />
        </Col>
      </Row>

      

      <hr
        className="thin-horizontal-line"
        style={{
          background:
            "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
          height: "2px",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0" style={{ background: "#FAF1EC" }}>
        <Col xs="12" md="6">
          <div className="sectionHeading2">Geographical Activity</div>

          <h2
            className="globalText"
            style={{
              fontSize: "1.3rem",
              marginTop: "10px",
              width: "80%",
              marginBottom: "0px",
              fontWeight: "bolder",
              background: "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Instruction prefetching is Google's leading technology focus.
          </h2>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 className="paraData">
              Companies leading technological development
            </h1>
          </div>
          <div className="citationMap">
            <SimpleMap
              color="blue"
              suffix="players"
              backgroundColor="#FAF1EC"
              borderColor="black"
              label="World Map"
              clickHandler={handleClick}
            />
          </div>
        </Col>
        <Col xs="12" md="6">
          <div className=" mapDataTable">
            <div className="table-container" style={{ height: "auto" }}>
              <table className="vibrant-table">
                <thead>
                  <tr>
                    <th className="text-center">Country</th>
                    <th className="text-center">Active Organizations</th>
                    <th className="text-center">Inventors</th>
                    <th className="text-center">Patents</th>
                  </tr>
                </thead>
                <tbody>
                  {sampleData4.map((row, index) => (
                    <tr
                      key={index}
                      onClick={() => moveToCompanyPage(row.country)}
                    >
                      <td>{row.country}</td>
                      <td>{row.activeOrganizations}</td>
                      <td>{row.inventors}</td>
                      <td>{row.patents}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="show-more-container">
                <button className="show-more-button">Show More</button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr
        className="thin-horizontal-line"
        style={{
          background:
            "linear-gradient(to right, rgb(24, 52, 221), rgb(208, 121, 223))",
          height: "2px",
          marginTop: 0,
          marginBottom: 0,
        }}
      />

      <Row className="g-0">
        <Col xs="12" md="12" style={{ background: "#FAF1EC" }}>
          <div className="sectionHeading2">Active organizations</div>

          <h2
            className="globalText"
            style={{
              fontSize: "1.3rem",
              marginTop: "10px",
              width: "80%",
              marginBottom: "0px",
              fontWeight: "bolder",
              background: "linear-gradient(to right, #020B26 10%, #AF41F0 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Instruction prefetching is Google's leading technology focus.
          </h2>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 className="paraData" style={{ marginTop: "10px" }}>
              Companies leading technological development
            </h1>
          </div>

          <div
            className="sectionHeading2"
            style={{
              backgroundColor: "#FAF1EC",
              color: "black",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            List of organizations
          </div>
          <FullCompanyTable data={sampleDataTable} heading="" />

          <div
            className="cpcTable"
            style={{ marginLeft: "0px", marginTop: "0px" }}
          >
            <div
              className="sectionHeading2"
              style={{
                backgroundColor: "#FAF1EC",
                color: "black",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              Checkout emerging organizations
            </div>
            <CompanyCarousel data={sampleData} isMoving={isMoving} heading="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataSet;
