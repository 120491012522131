import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import "../../pages/forYouPage/forYou.css";
import { IconContext } from "react-icons";
import { BiSolidFile } from "react-icons/bi";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { SlLike } from "react-icons/sl";
import { toTitleCase } from "../../actions/helper";

const ForYouProfile = (props) => {
  let userInfo = props.userInfo;
  const userActivity = props.userActivity;
  const [isEditing, setIsEditing] = useState({
    name: false,
    email: false,
    organization: false,
    researchAreas: false,
  });

  const handleEditClick = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };

  const handleInputChange = (field, e) => {
    userInfo[field] = e.target.value;
  };

  const handleSaveClick = (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    props.updateUserInfoFunction(field, userInfo[field]);
  };

  return (
    <div>
      <div className="userOverview" style={{ marginBottom: "0px" }}>
        <div
          className="summaryTag"
          style={{
            width: "auto",
            backgroundColor: "white",
            color: "black",
            marginLeft: "0px",
            marginTop: "-15px",
          }}
        >
          <span
            className="summaryTagText"
            style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
          >
            Your Profile
          </span>
        </div>
      </div>

      <Row className="g-0">
        <Col xs="12" md="5">
          <div
            className="overviewBlock"
            style={{ marginLeft: "30px", marginRight: "20px" }}
          >
            <div className="userImg">
              <img src="/profile.jpg" className="rounded-circle" width="150" />

              <div className="mt-3">
                <h2 className="userDesc uname">
                  {userInfo ? userInfo.name : "Incubig User"}
                </h2>
                <p className="userDesc udesc">
                  {userInfo && userInfo.employer
                    ? toTitleCase(userInfo.employer)
                    : "Incubig, India"}
                </p>
                {/* <p class="userDesc udesc">Bay Area, San Francisco, CA</p> */}
                {/* <div className="uButton">
                  <button className="userDesc btn btn-outline-primary ubutton">
                    Follow
                  </button>
                  <button className="userDesc btn btn-outline-primary ubutton">
                    Message
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </Col>
        <Col xs="12" md="7">
          <div
            className="overviewBlock"
            style={{ marginRight: "20px", paddingTop: "0px" }}
          >
            <div className="userInfo">
              {/* Name */}
              <div key="name">
                <div
                  className="uInfo"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Name :&nbsp;&nbsp;&nbsp;&nbsp;
                    {isEditing["name"] ? (
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => {
                          handleInputChange("name", e);
                        }}
                        style={{ fontWeight: "500", textAlign: "center" }}
                      />
                    ) : (
                      <span style={{ fontWeight: "500", textAlign: "center" }}>
                        {toTitleCase(userInfo["name"])}
                      </span>
                    )}
                  </div>
                  <button
                    className="editButton"
                    onClick={() =>
                      isEditing["name"]
                        ? handleSaveClick("name")
                        : handleEditClick("name")
                    }
                  >
                    {isEditing["name"] ? "Save" : "Edit"}
                  </button>
                </div>
                <hr className="thin-horizontal-line" />
              </div>
              {/* Email */}
              <div key="email">
                <div
                  className="uInfo"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Email :&nbsp;&nbsp;&nbsp;&nbsp;
                    {isEditing["email"] ? (
                      <input
                        type="text"
                        name="email"
                        value={userInfo["email"]}
                        onChange={(e) => {
                          handleInputChange("email", e);
                        }}
                        style={{ fontWeight: "500", textAlign: "center" }}
                      />
                    ) : (
                      <span style={{ fontWeight: "500", textAlign: "center" }}>
                        {userInfo["email"]}
                      </span>
                    )}
                  </div>
                </div>
                <hr className="thin-horizontal-line" />
              </div>
              {/* Organization */}
              <div key="employer">
                <div
                  className="uInfo"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Organization :&nbsp;&nbsp;&nbsp;&nbsp;
                    {isEditing["employer"] ? (
                      <input
                        type="text"
                        name="employer"
                        onChange={(e) => {
                          handleInputChange("employer", e);
                        }}
                        style={{ fontWeight: "500", textAlign: "center" }}
                      />
                    ) : (
                      <span style={{ fontWeight: "500", textAlign: "center" }}>
                        {toTitleCase(userInfo["employer"])}
                      </span>
                    )}
                  </div>
                  <button
                    className="editButton"
                    onClick={() =>
                      isEditing["employer"]
                        ? handleSaveClick("employer")
                        : handleEditClick("employer")
                    }
                  >
                    {isEditing["employer"] ? "Save" : "Edit"}
                  </button>
                </div>
                <hr className="thin-horizontal-line" />
              </div>
              {/* Research Areas */}
              <div key="researchAreas">
                <div
                  className="uInfo"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Research Areas :&nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ fontWeight: "500", textAlign: "center" }}>
                      {props.researchAreas
                        .map((area) => toTitleCase(area.definition))
                        .join(" | ")}
                    </span>
                  </div>
                </div>
                <hr className="thin-horizontal-line" />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr className="thin-horizontal-line"/>

      <div className="summaryTag" style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px" }}>
  <span className="summaryTagText" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
    Your account
  </span>
</div>
      <Row className="g-0" style={{ marginBottom: "40px" }}>
        <Col xs="12" md="3">
          <div
            className="overviewBlock"
            style={{
              marginRight: "20px",
              marginLeft: "30px",
              backgroundColor: "#020b26",
              color: "white",
            }}
          >
            <p>Welcome</p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              {localStorage.getItem("user")
                ? localStorage.getItem("user")
                : "Incubig User"}
            </p>
            <br />
            <br />
            <div className="credits">
              <div className="creditBlock1">
                <p>Credits avialable</p>
                <br />
                <span style={{ fontWeight: "bold" }}>5000</span>
              </div>
              <div className="creditBlock2">
                <p>Credits used</p>
                <br />
                <span style={{ fontWeight: "bold" }}>0</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <hr className="thin-horizontal-line"/>



        <div className="summaryTag" style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px" }}>
  <span className="summaryTagText" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>
    Your activity
  </span>
</div>
      <Row className="g-0" style={{ marginBottom: "40px" }}>
        <Col xs="12" md="3">
          <div className="overviewBlock" style={{
              marginRight: "20px",
              marginLeft: "30px",
              
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "-13px",
                marginRight: "20px"
              }}
            >
              <div style={{ marginLeft: "10px" }}>
                <p
                  className="text-blue-500"
                  style={{ fontSize: "2rem", fontWeight: "bold" }}
                >
                  {props.IDFs && props.IDFs.length}&nbsp;
                </p>
              </div>
              <IconContext.Provider
                value={{ color: "#93c5fd", className: "global-class-name" }}
              >
                <BiSolidFile size={65} />
              </IconContext.Provider>
            </div>
            <br />
            <br />
            <br />
            <div className="credits">
              <p style={{ fontWeight: "bold" }}>IDF created</p>
              <a
                className="creditLink"
                href="https://dyr.incubig.org/for-you/request-report/own-your-invention"
                style={{ color: "blue", textDecoration: "none" }}
              >
                Create new IDF
              </a>
            </div>
          </div>
        </Col>
        <Col xs="12" md="3">
          <div className="overviewBlock" style={{
              marginRight: "20px",
              marginLeft: "30px",
              
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "-5px",
              }}
            >
              <div style={{ marginLeft: "10px" }}>
                <p
                  className="text-blue-500"
                  style={{ fontSize: "2rem", fontWeight: "bold" }}
                >
                  {userActivity &&
                    userActivity.searches &&
                    userActivity.searches.length}
                  &nbsp;
                </p>
              </div>
              <IconContext.Provider
                value={{ color: "#93c5fd", className: "global-class-name" }}
              >
                <MdOutlineScreenSearchDesktop size={65} />
              </IconContext.Provider>
            </div>
            <br />
            <br />
            <br />
            <div className="credits">
              <p style={{ fontWeight: "bold" }}>Queries searched</p>
              <a
                className="creditLink"
                href="https://dyr.incubig.org/results?q=cold%20nuclear%20fission"
                style={{ color: "blue", textDecoration: "none" }}
              >
                Search again
              </a>
            </div>
          </div>
        </Col>
        <Col xs="12" md="3">
          <div
            className="overviewBlock"
            style={{
              marginRight: "20px",
              marginLeft: "30px",
              
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "-5px",
              }}
            >
              <div style={{ marginLeft: "10px" }}>
                <p
                  className="text-blue-500"
                  style={{ fontSize: "2rem", fontWeight: "bold" }}
                >
                  {userActivity &&
                    userActivity.likes &&
                    userActivity.likes.length}
                  &nbsp;
                </p>
              </div>
              <IconContext.Provider
                value={{ color: "#93c5fd", className: "global-class-name" }}
              >
                <SlLike size={65} />
              </IconContext.Provider>
            </div>
            <br />
            <br />
            <br />
            <div className="credits">
              <p style={{ fontWeight: "bold" }}>Liked patents</p>
              <a
                className="creditLink"
                href="https://dyr.incubig.org/patent/about/20100150292"
                style={{ color: "blue", textDecoration: "none" }}
              >
                View more
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForYouProfile;
