import React from "react";
import { Table } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "../../components/Tables/cpcTable.css";
import { useNavigate } from "react-router-dom";
import { moveToTechnologyPage } from "../../actions/helper";

const TechnologyInsightTable = ({ data, showHeading, heading }) => {
  const navigate = useNavigate();
  return (
    <div className="customTableInsight">
      {showHeading && (
        <div className="tableHeading" style={{ fontSize: "0.8rem", fontWeight: "bold", marginBottom: "10px" }}>
          {heading ? heading : "Company Patents"}
        </div>
      )}
      <Table className="mainTable" responsive>
        <thead>
          <tr>
            <th className="text-center customTh desktopTh" style={{ width: "40%", fontWeight: "normal", backgroundColor: "#020b26", color: "white" }}>Technology</th>
            <th className="text-center customTh desktopTh" style={{ width: "20%", fontWeight: "normal", backgroundColor: "#020b26", color: "white" }}>Patent Density</th>
            <th className="text-center customTh desktopTh" style={{ width: "30%", fontWeight: "normal", backgroundColor: "#020b26", color: "white" }}>Grant Rate</th>
            <th className="text-center customTh desktopTh theadBestYear" style={{ width: "10%", fontWeight: "normal", backgroundColor: "#020b26", color: "white" }}>Best Year</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.map((item, index) => (
              <tr key={index} className="rowTable" onClick={()=> moveToTechnologyPage(navigate,"overview", [item])} style={{ backgroundColor: "white" }}>
                <td className="tableColumnInsight" style={{width: "30%"}}>{item.definition}</td>
                <td className="tableColumnInsight">{item.density}</td>
                <td className="tableColumnInsight">{item.grantRate}</td>
                <td className="tableColumnInsight">{item.bestYear.year}</td>
              </tr>
            ))
          ) : (
            <tr className="rowTableInsight">
              <td className="tableColumn">
                <Skeleton className="customSkeleton" width={200} height={20} count={5} />
              </td>
              <td className="tableColumn">
                <Skeleton className="customSkeleton" width={150} height={20} count={5} />
              </td>
              <td className="tableColumn">
                <Skeleton className="customSkeleton" width={100} height={20} count={5} />
              </td>
              <td className="tableColumn">
                <Skeleton className="customSkeleton" width={100} height={20} count={5} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TechnologyInsightTable;
