import React from "react";
import Skeleton from "react-loading-skeleton";
import { truncateText } from "../../actions/helper";

const InlineDisplay = ({ technologySummary, selectedResearchArea, setSelectedResearchArea }) => {
  return (
    <div className="inline-display">
      {technologySummary && technologySummary.length > 0 ? (
        <div className="technology-name">
          {technologySummary[selectedResearchArea].definition}
        </div>
      ) : (
        <div className="technology-name">
          <Skeleton />
        </div>
      )}
      {technologySummary && technologySummary.length > 0 && (
        <div className="researchAreaDropdown">
          <label htmlFor="researchAreaDropdown" style={{ marginRight: "10px" }}>
            Select Technology:
          </label>
          <select
            id="researchAreaDropdown"
            value={selectedResearchArea}
            onChange={(e) => setSelectedResearchArea(parseInt(e.target.value))}
          >
            {technologySummary.map((technology, index) => (
              <option key={index} value={index}>
                {truncateText(technology.definition, 50)}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};


export default InlineDisplay;
