import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Popover } from "reactstrap";
import { moveToTechnologyPage, removeDuplicateTechnologies } from "../../actions/helper";
import Skeleton from "react-loading-skeleton";
import "./cpcTable.css";

export const CpcTable  = (props) => {
  const [popoverContent, setPopoverContent] = useState({ title: "", body: "" });
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const sectionId = "TableRow";

  const sanitizeText = (str) => {
    if (typeof str === "string") {
      str = str.replace("_", " ");
      return str[0].toUpperCase() + str.slice(1, str.length).toLowerCase();
    } else {
      return str;
    }
  };

  const navigate = useNavigate();

  const handlePopoverClick = (e, item) => {
    setPopoverContent({ title: item[0], body: `${item[1]} related patents` });
    setPopoverTarget(e.target);
    setPopoverOpen(true);
  };

  const closePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <div className="cpcTable">
      {props.showHeading && (
        <div className="newTechnologyTableheading">
          {(props.heading) ? props.heading : "Patent Technologies"}
        </div>
      )}
      <Table className="mainTable">
        <thead>
          <tr>
            <th className="text-center" style={{fontWeight: "normal", marginLeft: "-2%", backgroundColor: "#020b26", color: "white"}}>Technologies</th>

          {props.showCount && (
            <th className="text-center" style={{fontWeight: "normal", backgroundColor: "#020b26", color: "white"}}>
              {props.count ? props.count : "Patent Count"}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.data ? (
            props.data.map((item, index) => (
              <tr key={index} className="rowTable" onClick={() => moveToTechnologyPage(navigate, "overview", [item])}>
                <td className="tableFirstColumn">
                  {item.definition ? item.definition : item.cpc}
                </td>
                <td className="tableSecondColumn">
                  {item.count ? `${item.count} new patents` : ''}
                </td>
              </tr>
            ))
          ) : (
            <tr className="rowTable">
              <td className="tableFirstColumn">
                <Skeleton className="customSkeleton" width={300} height={20} count={5}/>
              </td>
              <td className="tableSecondColumn">
                <Skeleton className="customSkeleton" width={50} height={20} count={5}/>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};